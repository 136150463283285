"use client";

import dynamic from "next/dynamic";
import React, { useEffect, useState } from "react";
import ChartOne from "../Charts/ChartOne";
import ChartTwo from "../Charts/ChartTwo";
import ChatCard from "../Chat/ChatCard";
import TableOne from "../Tables/TableOne";
import CardDataStats from "../CardDataStats";
import * as geoappui from "ui";
import { PaginatedList } from "../Common/Pagination";
import LocationSelector from "../Location/Selector";
import { LocalSearchHeader } from "../LocalSearch/header";
import SearchResults from "../LocalSearch/results";
import ContentCard from "../Cards/Content";
import { GroupProfile } from "../Group/profile";
import { TabBar } from "../TabBar";
import { useSearchParams } from 'next/navigation';
import { useLocation } from "../Location/Context";
// import branch, { BranchParams } from 'branch-sdk';
// import { AppProps } from 'next/app';
// import { useEffect } from "react";

import "mapbox-gl/dist/mapbox-gl.css"; // Import Mapbox styles
import BackButton from "../BackButton";
import { GetAnonymousViewMapDataQueryVariables, useGetAnonGeoActivityByActivityIdLazyQuery, useGetAnonymousViewMapDataLazyQuery, useSearchGeoActivitiesLazyQuery } from "@/generated/public/graphql";
import useSharedState from "@/hooks/useSharedState";
import { ApiClient } from "@/utils/apiClient";
import { useIsMobile } from "@/hooks/useIsMobile";
import { ListMapToggle } from "../Buttons/ToggleListMap";
import { FiEdit } from "react-icons/fi";
const dateTimeToPriority = (dateTimeStr: string): number => {
  const date = new Date(dateTimeStr);
  // Return the Unix timestamp (in seconds)
  return Math.floor(date.getTime() / 1000);
};
interface LocationMarkerIcon {
  url: number;
  name: string;
}
interface LocationMarker {
  id: number;
  title: string;
  description: string;
  lng: number; // Add longitude
  lat: number; // Add latitude
  location?: string;
  eventDate?: string;
  eventTime?: string;
  eventTimestamp?: string;
  priority?: number;
  // metaJson?: any
  likes?: number;
  comments?: number;
  views?: number;
  media?: Array<{
    type: 'image' | 'video' | 'gallery';
    url: string[];
  }>;
  icon?: LocationMarkerIcon;
  refUrl?: string;
  searchable?: string[];
}
const MapOne = dynamic(() => import("@/components/Maps/MapOne"), {
  ssr: false
});
const ChartThree = dynamic(() => import("@/components/Charts/ChartThree"), {
  ssr: false
});
const LocalSearch: React.FC = () => {
  const isMobile = useIsMobile();
  console.log({
    isMobile
  });
  const mapRef = React.useRef<mapboxgl.Map | null>(null);
  const {
    location: searchLocation,
    setLocation: setSearchLocation
  } = useLocation();
  const searchParams = useSearchParams();
  const setSearchParams = (newParams: URLSearchParams) => {
    const url = new URL(window.location.href);
    url.search = newParams.toString();
    window.history.pushState({}, '', url.toString());
  };
  const clearParam = (param: string) => {
    // Create a new URLSearchParams object from the current search params
    const newParams = new URLSearchParams(searchParams.toString());
    // Delete the specific parameter
    newParams.delete(param);
    // Update the search params in the URL
    setSearchParams(newParams);
  };
  const setParam = (param: string, value: string) => {
    const newParams = new URLSearchParams(searchParams.toString());
    newParams.set(param, value);
    setSearchParams(newParams);
  };
  const searchParam = searchParams.get('search');
  const activityIdParam = searchParams.get('activityId');
  const locationIdParam = searchParams.get('location');
  const [locationIdParamLast, setLocationIdParamLast] = useState<string>();
  const [showProfileSection, setShowProfileSection] = useState<boolean>(false);
  const [activityId, setActivityId] = useState<any>(activityIdParam);
  const [activity, setActivity] = useState<any>();
  const [displayedResults, setDisplayedResults] = useState<any>();
  const lastSerializedDataRef = React.useRef<string | null>(null);
  const [selectedIcon, setSelectedIcon] = useSharedState('selectedIcon', null);
  const [isMapMode, setIsMapMode] = useState(false);
  const [getAnonymousViewMapData, {
    loading,
    error,
    data,
    refetch
  }] = useGetAnonymousViewMapDataLazyQuery({
    fetchPolicy: 'network-only' // Forces a fresh request each time
  });
  const [getSearchGeoActivitiesLazyQuery, {
    loading: loadingSearch,
    error: errorSearch,
    data: dataSearch,
    refetch: refetchSearch
  }] = useSearchGeoActivitiesLazyQuery({
    fetchPolicy: 'network-only' // Forces a fresh request each time
  });
  function getMapBoundaries() {
    if (mapRef.current) {
      const bounds = mapRef.current.getBounds();
      if (bounds) {
        const southWest = bounds.getSouthWest();
        const northEast = bounds.getNorthEast();
        // Map the result to match the required structure
        const result = {
          left: southWest.lat,
          // Latitude of southwest corner (matches left)
          bottom: southWest.lng,
          // Longitude of southwest corner (matches bottom)
          right: northEast.lat,
          // Latitude of northeast corner (matches right)
          top: northEast.lng // Longitude of northeast corner (matches top)
        };
        // console.log("getMapBoundaries:", result)
        return result;
      }
    }
  }
  function formatDateRange(startDate: string, endDate?: string): {
    eventDate: string;
    eventTime: string;
  } {
    const dateOptions: Intl.DateTimeFormatOptions = {
      weekday: 'short',
      // 'Thu'
      year: 'numeric',
      // '2024'
      month: 'short',
      // 'Oct'
      day: 'numeric' // '3'
    };
    const timeOptions: Intl.DateTimeFormatOptions = {
      hour: 'numeric',
      // '7 PM'
      minute: 'numeric' // '00'
    };
    const start = new Date(startDate);
    const end = endDate ? new Date(endDate) : null;
    const eventDate = start.toLocaleDateString(undefined, dateOptions);
    const startTime = start.toLocaleTimeString(undefined, timeOptions);
    let eventTime: string;
    if (!endDate || start.toDateString() === end?.toDateString()) {
      // Same day or no end date
      const endTime = end ? end.toLocaleTimeString(undefined, timeOptions) : null;
      eventTime = endTime && startTime !== endTime ? `${startTime} - ${endTime}` : `${startTime}`;
    } else {
      // Different day
      const endTime = end!.toLocaleTimeString(undefined, timeOptions);
      const endDateDisplay = end!.toLocaleDateString(undefined, dateOptions);
      eventTime = `${startTime} - ${endTime} (${endDateDisplay})`;
    }
    return {
      eventDate,
      eventTime
    };
  }
  const mapDropToLocationMarker = (drop: any) => {
    const {
      eventDate,
      eventTime
    } = formatDateRange(drop?.displayStartDate, drop?.displayEndDate);
    const mediaData: {
      type: 'image' | 'video' | 'gallery';
      url: string[];
    }[] = [];
    if (drop?.refImageUrl) {
      mediaData.push({
        type: 'image',
        url: [drop?.refImageUrl]
      });
    }
    if (drop?.refVideoUrl) {
      mediaData.push({
        type: 'video',
        url: [drop?.refVideoUrl]
      });
    }
    const proxyIconUrl = `/api/proxy-image?url=${encodeURIComponent(drop?.appDropCategory?.iconUrl)}`;
    return {
      id: drop?.id,
      title: drop?.messageSubject,
      description: drop?.messageBody,
      lat: drop?.geocode?.locationCoordinates?.latitude,
      lng: drop?.geocode?.locationCoordinates?.longitude,
      location: drop?.geocode?.name,
      eventDate: eventDate,
      eventTime: eventTime,
      eventTimestamp: drop?.displayStartDate,
      priority: dateTimeToPriority(drop?.displayStartDate),
      refUrl: drop?.refUrl,
      searchable: [drop?.messageSubject, drop?.messageBody, `icon:${drop?.appDropCategory?.name}`],
      media: mediaData,
      // metaJson: drop?.metaJson,
      views: drop?.metaJson?.track?.views || 0,
      likes: drop?.likeCount || 0,
      comments: drop?.commentCount || 0,
      icon: {
        url: proxyIconUrl as any,
        name: drop?.appDropCategory?.name
      }
    };
  };
  React.useEffect(() => {
    if (!searchParam) {
      // clearParam("search")
    }
  }, [searchParams]);
  useEffect(() => {
    console.log("data refresh called", {
      searchParam,
      data,
      loading,
      error,
      dataSearch,
      loadingSearch,
      errorSearch
    });
    // Use a ref to store the last serialized version of the data
    if (loading || error || loadingSearch || errorSearch) return;
    const selectData = searchParam ? dataSearch?.geoActivities?.nodes : data?.getAnonMapFeed?.geoDrops;
    const mappedData: LocationMarker[] = [];

    // Map over the geoDrops and transform them into the desired format
    for (const drop of selectData || []) {
      // console.log(drop)
      // console.log("metaJson:", drop?.metaJson, )
      mappedData.push(mapDropToLocationMarker(drop));
    }
    // Serialize the new mappedData to compare with the last version
    const newSerializedData = JSON.stringify(mappedData);

    // Compare with the last cached serialized data
    if (newSerializedData !== lastSerializedDataRef.current) {
      // Update displayed results if the data has changed
      setDisplayedResults(mappedData);

      // Update the ref with the new serialized data
      lastSerializedDataRef.current = newSerializedData;
    }
  }, [data, loading, error, dataSearch, loadingSearch, errorSearch]);
  const getMapData = (searchParamIn?: string | null) => {
    // console.log("getMapData called.", { searchParamIn }) 
    if (!!searchParamIn) {
      // console.log("getSearchGeoActivitiesLazyQuery called.")
      getSearchGeoActivitiesLazyQuery({
        variables: {
          sharingAndFilterCriteria: JSON.stringify({
            viewerId: "public",
            // includePublicData: true,
            searchText: searchParamIn,
            viewingLocation: {
              ...getMapBoundaries()
            }
          }),
          limit: 50,
          offset: 0
        }
      });
    } else {
      // console.log("getAnonymousViewMapData called.")
      getAnonymousViewMapData({
        variables: {
          boundary: {
            ...getMapBoundaries()
          } as GetAnonymousViewMapDataQueryVariables['boundary']
        }
      });
    }
  };

  // const debouncedGetMapData = React.useCallback(debounce(getMapData, 500), [searchParam, popularParam]);

  React.useEffect(() => {
    getMapData(searchParam);
  }, [searchParams]);
  React.useEffect(() => {
    if (locationIdParam && locationIdParam !== locationIdParamLast) {
      setSearchLocation({
        location: locationIdParam,
        updateMap: !locationIdParamLast ? true : false // only on page init/load 
      });
    }
  }, [locationIdParamLast]);
  React.useEffect(() => {
    if (locationIdParam && locationIdParam !== locationIdParamLast) {
      setLocationIdParamLast(locationIdParam);
    }
  }, [locationIdParam]);
  React.useEffect(() => {
    if (searchLocation) {
      setParam("location", searchLocation?.location);
    }
  }, [searchLocation]);
  const viewActivity = async (idIn: string) => {
    // console.log("viewActivity called")
    const apiClient = new ApiClient();
    const result = await apiClient.post(`/track/view-activity?id=${idIn}`);
    // console.log("viewActivity-response:", result)
  };
  const [getAnonGeoActivityByActivityId, {
    loading: loadingActivity,
    error: errorActivity,
    data: dataActivity
  }] = useGetAnonGeoActivityByActivityIdLazyQuery();
  const scrollToTop = () => {
    const overlay = document.querySelector('.overlayRef'); // Use your overlay class name here
    if (overlay) {
      overlay.scrollTop = 0; // Scroll to the top
    }
  };
  React.useEffect(() => {
    if (!activityId || activityId && !activity) {
      if (activityId && !activity) {
        const apiActivityData = dataActivity?.geoActivities?.nodes[0];
        if (!apiActivityData) {
          // console.log("call api to refresh by id")
          getAnonGeoActivityByActivityId({
            variables: {
              id: activityId
            }
          });
        } else if (!activity?.id) {
          // console.log("mapDropToLocationMarker(apiActivityData):::", mapDropToLocationMarker(apiActivityData))
          setActivity(mapDropToLocationMarker(apiActivityData));
        }
      } else {
        // console.log("11111")
        clearParam("activityId");
        setActivityId(null);
        setActivity(null);
      }
    } else {
      // console.log("2222")
      setParam("activityId", activityId);
      if (activity?.id) {
        viewActivity(activity?.id);
        scrollToTop();
      }
    }
  }, [activityId, activity, dataActivity]);
  const calculateDynamicOffsetY = (offsetYTarget = 300) => {
    if (mapRef.current) {
      const mapHeight = mapRef.current.getContainer().clientHeight;
      // Calculate the dynamic offset by subtracting half the map's height from the target offset
      const dynamicOffsetY = mapHeight / 2 - offsetYTarget;
      return dynamicOffsetY;
    }
    return 0; // Default offset in case mapRef is not available
  };
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };
  const isLoading = loading || loadingActivity || loadingSearch;
  return <geoappui.MapboxMap ref={mapRef} isMobile={isMobile} location={searchLocation} setLocation={setSearchLocation} setActivity={setActivity} setActivityId={setActivityId} refreshData={() => {
    getMapData(searchParam);
  }} focus={activity?.id ? {
    type: 'activity',
    content: activity
  } : undefined} displayedResults={searchParam == null || searchParam != null && !!searchParam ? displayedResults || [] : []} displayedResultsFilter={selectedIcon ? `icon:${selectedIcon}` : null} addCenterOffset={!isMobile && (displayedResults || activity?.id) ? {
    offsetX: -150,
    offsetY: calculateDynamicOffsetY()
  } : null} initCenter={[-84.3877, 33.7488] // Atlanta, GA
  } data-sentry-element="unknown" data-sentry-component="LocalSearch" data-sentry-source-file="LocalSearch.tsx">
      {<>
          <div className="flex">
            <div className="">

              {isLoading ? <div className="pl-4 pb-2"><p>loading...</p></div> : <></>}

              <div className={"px-4"}>

                {activityId ? <BackButton onBack={() => {
              setActivityId(null);
              setActivity(null);
            }} header={displayedResults ? "Results" : "Go Back"} /> : <></>}


              </div>

              {isMobile && !activityId && !showProfileSection ? <div className="flex items-start">
                  <>
                    <ListMapToggle onToggle={(isMapMode: boolean) => {
                setIsMapMode(isMapMode);
              }} />
                  </>
                  {isEditing ? <>
                      <LocationSelector apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''} hideHeader={true} initEditMode={true} isEditingCallback={setIsEditing} />
                    </> : <>
                      <p className="font-bold pl-2 pt-2">{searchLocation?.location}</p> 
                    </>}
                  {<FiEdit size={isEditing ? 30 : 20} className={"text-gray-500 cursor-pointer ml-4 mt-2" + (isEditing ? "mb-2" : "")} onClick={toggleEditMode} />}
                </div> : <></>}
              {!isMobile ? <LocationSelector apiKey={process.env.NEXT_PUBLIC_GOOGLE_API_KEY || ''} /> : <></>}

              <>
                {showProfileSection ? <GroupProfile /> : <></>}
              </>

              {activityId ? <ContentCard content={{
            ...activity
          }} onLocationClick={(resultId, result) => {
            // console.log({
            //   resultId,
            //   result
            // })
            setActivityId(resultId);
            setActivity(result);
          }} /> : displayedResults?.length > 0 && (!isMobile || !isMapMode) && searchLocation?.location && (searchParam == null || searchParam != null && !!searchParam) ? <SearchResults displayedResults={displayedResults} header={searchParam == null ? "Popular" : "Search Results"} onResultView={viewActivity} onResultClick={(resultId, result) => {
            // console.log({
            //   result
            // })
            setActivityId(resultId);
            setActivity(result);
            // if (mapRef.current) {
            //   mapRef.current.zoomTo(16)
            // }
          }} /> : <></>}
            </div>
          </div>
        </>}
    </geoappui.MapboxMap>;
};
export default LocalSearch;