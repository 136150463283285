import { useState, useEffect } from 'react';

// Custom hook for shared state
function useSharedState(key: string, defaultValue: string | null = null) {
  const [value, setValue] = useState<string | null>(() => {
    const savedValue = localStorage.getItem(key);
    return savedValue !== null ? savedValue : defaultValue;
  });

  // Function to trigger a custom event for value updates
  const triggerStorageChange = (newValue: string | null) => {
    const event = new CustomEvent('localStorageUpdate', {
      detail: {
        key,
        newValue
      }
    });
    window.dispatchEvent(event);
  };
  useEffect(() => {
    // Update localStorage and trigger the custom event when value changes
    if (value !== null) {
      localStorage.setItem(key, value);
    } else {
      localStorage.removeItem(key);
    }
    triggerStorageChange(value);
  }, [key, value]);
  useEffect(() => {
    // Listen for custom events and update the state when storage changes externally
    const handleStorageUpdate = (e: CustomEvent) => {
      if (e.detail.key === key) {
        setValue(e.detail.newValue);
      }
    };
    window.addEventListener('localStorageUpdate', handleStorageUpdate as EventListener);
    return () => {
      window.removeEventListener('localStorageUpdate', handleStorageUpdate as EventListener);
    };
  }, [key]);
  return [value, setValue] as const;
}
export default useSharedState;