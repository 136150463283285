import React, { useState } from 'react';
const ListMapToggle: React.FC<{
  onToggle?: (isMapMode: boolean) => void;
}> = ({
  onToggle
}) => {
  // State to track the current mode
  const [isMapMode, setIsMapMode] = useState(localStorage.getItem('isMapMode') == 'true');
  React.useEffect(() => {
    onToggle && onToggle(isMapMode);
    localStorage.setItem('isMapMode', JSON.stringify(isMapMode));
  }, [isMapMode]);

  // Function to toggle between List and Map mode
  const toggleMode = () => {
    setIsMapMode(!isMapMode);
  };
  return <>
        <button onClick={toggleMode} className="flex items-center justify-center p-2 bg-gray-200 hover:bg-gray-300 rounded-md">
          {isMapMode ?
      // If in Map mode, show List icon
      <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
      // {...props}
      >
              <path fillRule="evenodd" clipRule="evenodd" d="M0 0.75C0 0.335786 0.335786 0 0.75 0H21.25C21.6642 0 22 0.335786 22 0.75C22 1.16421 21.6642 1.5 21.25 1.5H0.75C0.335786 1.5 0 1.16421 0 0.75Z" fill="url(#paint0_linear_13251_101130)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M0 21.25C0 20.8358 0.335786 20.5 0.75 20.5H21.25C21.6642 20.5 22 20.8358 22 21.25C22 21.6642 21.6642 22 21.25 22H0.75C0.335786 22 0 21.6642 0 21.25Z" fill="url(#paint1_linear_13251_101130)" />
              <path fillRule="evenodd" clipRule="evenodd" d="M20 6H2C1.72386 6 1.5 6.22386 1.5 6.5V15.7C1.5 15.9761 1.72386 16.2 2 16.2H20C20.2761 16.2 20.5 15.9761 20.5 15.7V6.5C20.5 6.22386 20.2761 6 20 6ZM2 4.5C0.895431 4.5 0 5.39543 0 6.5V15.7C0 16.8046 0.89543 17.7 2 17.7H20C21.1046 17.7 22 16.8046 22 15.7V6.5C22 5.39543 21.1046 4.5 20 4.5H2Z" fill="url(#paint2_linear_13251_101130)" />
              <defs>
                <linearGradient id="paint0_linear_13251_101130" x1={0.703999} y1={47.3} x2={42.5969} y2={31.259} gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0D23E7" />
                  <stop offset={1} stopColor="#8C46FF" />
                </linearGradient>
                <linearGradient id="paint1_linear_13251_101130" x1={0.703999} y1={47.3} x2={42.5969} y2={31.259} gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0D23E7" />
                  <stop offset={1} stopColor="#8C46FF" />
                </linearGradient>
                <linearGradient id="paint2_linear_13251_101130" x1={0.703999} y1={47.3} x2={42.5969} y2={31.259} gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0D23E7" />
                  <stop offset={1} stopColor="#8C46FF" />
                </linearGradient>
              </defs>
            </svg> :
      // If in List mode, show Map icon
      <svg width={22} height={22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg"
      // {...props}
      >
              <path d="M7.5 22L14.5 20V0L7.5 2V22Z" fill="white" />
              <path d="M14.5 20L21.3712 21.8323C21.6886 21.917 22 21.6777 22 21.3492V2.38414C22 2.15761 21.8477 1.95939 21.6288 1.90102L14.5 0V20Z" fill="white" />
              <path d="M0.628832 0.167688C0.311409 0.0830423 0 0.32229 0 0.650806V19.1396C0 19.3548 0.137715 19.5459 0.341886 19.614L7.5 22V2L0.628832 0.167688Z" fill="white" />
              <path d="M8.25 2.56573L13.75 0.994297V19.4343L8.25 21.0057V2.56573ZM15.25 0.976209L21.25 2.57621V21.0238L15.25 19.4238V0.976209ZM6.75 2.57621V20.9594L0.75 18.9594V0.976209L6.75 2.57621Z" stroke="url(#paint0_linear_9681_207679)" strokeWidth={1.5} />
              <defs>
                <linearGradient id="paint0_linear_9681_207679" x1={0.703999} y1={47.3} x2={42.5969} y2={31.259} gradientUnits="userSpaceOnUse">
                  <stop stopColor="#0D23E7" />
                  <stop offset={1} stopColor="#8C46FF" />
                </linearGradient>
              </defs>
            </svg>}
        </button>
      </>;
};
export { ListMapToggle };