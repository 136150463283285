import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {
  [key: string]: unknown;
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends {
  [key: string]: unknown;
}, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {
    input: string;
    output: string;
  };
  String: {
    input: string;
    output: string;
  };
  Boolean: {
    input: boolean;
    output: boolean;
  };
  Int: {
    input: number;
    output: number;
  };
  Float: {
    input: number;
    output: number;
  };
  BigInt: {
    input: any;
    output: any;
  };
  Cursor: {
    input: any;
    output: any;
  };
  Datetime: {
    input: any;
    output: any;
  };
  GeoJSON: {
    input: any;
    output: any;
  };
  JSON: {
    input: any;
    output: any;
  };
  UUID: {
    input: any;
    output: any;
  };
};
export type AnonMapFeed = {
  __typename?: 'AnonMapFeed';
  geoContexts?: Maybe<Scalars['JSON']['output']>;
  geoDrops?: Maybe<Scalars['JSON']['output']>;
  interests?: Maybe<Scalars['JSON']['output']>;
  places?: Maybe<Scalars['JSON']['output']>;
};
export type AppBadge = {
  __typename?: 'AppBadge';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByAppBadgeId: GeoActivitiesConnection;
  iconName?: Maybe<Scalars['String']['output']>;
  iconPath?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};
export type AppBadgeGeoActivitiesByAppBadgeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/**
 * A condition to be used against `AppBadge` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppBadgeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `iconName` field. */
  iconName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `iconPath` field. */
  iconPath?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `AppBadge` object types. All fields are combined with a logical ‘and.’ */
export type AppBadgeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppBadgeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `iconName` field. */
  iconName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconPath` field. */
  iconPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppBadgeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppBadgeFilter>>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `AppBadge` */
export type AppBadgeInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconPath?: InputMaybe<Scalars['String']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `AppBadge` values. */
export type AppBadgesConnection = {
  __typename?: 'AppBadgesConnection';
  /** A list of edges which contains the `AppBadge` and cursor to aid in pagination. */
  edges: Array<AppBadgesEdge>;
  /** A list of `AppBadge` objects. */
  nodes: Array<AppBadge>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppBadge` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AppBadge` edge in the connection. */
export type AppBadgesEdge = {
  __typename?: 'AppBadgesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AppBadge` at the end of the edge. */
  node: AppBadge;
};

/** Methods to use when ordering `AppBadge`. */
export enum AppBadgesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  IconNameAsc = 'ICON_NAME_ASC',
  IconNameDesc = 'ICON_NAME_DESC',
  IconPathAsc = 'ICON_PATH_ASC',
  IconPathDesc = 'ICON_PATH_DESC',
  IconUrlAsc = 'ICON_URL_ASC',
  IconUrlDesc = 'ICON_URL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A connection to a list of `AppDropCategory` values. */
export type AppDropCategoriesConnection = {
  __typename?: 'AppDropCategoriesConnection';
  /** A list of edges which contains the `AppDropCategory` and cursor to aid in pagination. */
  edges: Array<AppDropCategoriesEdge>;
  /** A list of `AppDropCategory` objects. */
  nodes: Array<AppDropCategory>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppDropCategory` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AppDropCategory` edge in the connection. */
export type AppDropCategoriesEdge = {
  __typename?: 'AppDropCategoriesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AppDropCategory` at the end of the edge. */
  node: AppDropCategory;
};

/** Methods to use when ordering `AppDropCategory`. */
export enum AppDropCategoriesOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  EnableOffersAsc = 'ENABLE_OFFERS_ASC',
  EnableOffersDesc = 'ENABLE_OFFERS_DESC',
  IconNameAsc = 'ICON_NAME_ASC',
  IconNameDesc = 'ICON_NAME_DESC',
  IconPathAsc = 'ICON_PATH_ASC',
  IconPathDesc = 'ICON_PATH_DESC',
  IconUrlAsc = 'ICON_URL_ASC',
  IconUrlDesc = 'ICON_URL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsAlertAsc = 'IS_ALERT_ASC',
  IsAlertDesc = 'IS_ALERT_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsCustomAsc = 'IS_CUSTOM_ASC',
  IsCustomDesc = 'IS_CUSTOM_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsEventAsc = 'IS_EVENT_ASC',
  IsEventDesc = 'IS_EVENT_DESC',
  IsSpecialAccessRequiredAsc = 'IS_SPECIAL_ACCESS_REQUIRED_ASC',
  IsSpecialAccessRequiredDesc = 'IS_SPECIAL_ACCESS_REQUIRED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  IsVisibleAsc = 'IS_VISIBLE_ASC',
  IsVisibleDesc = 'IS_VISIBLE_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  LabelTsvAsc = 'LABEL_TSV_ASC',
  LabelTsvDesc = 'LABEL_TSV_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SecondaryLabelAsc = 'SECONDARY_LABEL_ASC',
  SecondaryLabelDesc = 'SECONDARY_LABEL_DESC',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}
export type AppDropCategory = {
  __typename?: 'AppDropCategory';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  enableOffers?: Maybe<Scalars['Boolean']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByAppDropCategoryId: GeoActivitiesConnection;
  iconName?: Maybe<Scalars['String']['output']>;
  iconPath?: Maybe<Scalars['String']['output']>;
  iconUrl?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isAlert?: Maybe<Scalars['Boolean']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isCustom?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isEvent?: Maybe<Scalars['Boolean']['output']>;
  isSpecialAccessRequired?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  labelTsv?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  secondaryLabel?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};
export type AppDropCategoryGeoActivitiesByAppDropCategoryIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/**
 * A condition to be used against `AppDropCategory` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type AppDropCategoryCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `enableOffers` field. */
  enableOffers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `iconName` field. */
  iconName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `iconPath` field. */
  iconPath?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isAlert` field. */
  isAlert?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCustom` field. */
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isEvent` field. */
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isSpecialAccessRequired` field. */
  isSpecialAccessRequired?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isVisible` field. */
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `labelTsv` field. */
  labelTsv?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `secondaryLabel` field. */
  secondaryLabel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `AppDropCategory` object types. All fields are combined with a logical ‘and.’ */
export type AppDropCategoryFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppDropCategoryFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enableOffers` field. */
  enableOffers?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `iconName` field. */
  iconName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconPath` field. */
  iconPath?: InputMaybe<StringFilter>;
  /** Filter by the object’s `iconUrl` field. */
  iconUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isAlert` field. */
  isAlert?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCustom` field. */
  isCustom?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isEvent` field. */
  isEvent?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSpecialAccessRequired` field. */
  isSpecialAccessRequired?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isVisible` field. */
  isVisible?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppDropCategoryFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppDropCategoryFilter>>;
  /** Filter by the object’s `secondaryLabel` field. */
  secondaryLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `AppDropCategory` */
export type AppDropCategoryInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  enableOffers?: InputMaybe<Scalars['Boolean']['input']>;
  iconName?: InputMaybe<Scalars['String']['input']>;
  iconPath?: InputMaybe<Scalars['String']['input']>;
  iconUrl?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isAlert?: InputMaybe<Scalars['Boolean']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isCustom?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isEvent?: InputMaybe<Scalars['Boolean']['input']>;
  isSpecialAccessRequired?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  labelTsv?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  secondaryLabel?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};
export type AppEmoji = {
  __typename?: 'AppEmoji';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  emojiCode?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByAppEmojiId: GeoActivitiesConnection;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  sortOrder?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};
export type AppEmojiGeoActivitiesByAppEmojiIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/**
 * A condition to be used against `AppEmoji` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type AppEmojiCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `emoji` field. */
  emoji?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emojiCode` field. */
  emojiCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `AppEmoji` object types. All fields are combined with a logical ‘and.’ */
export type AppEmojiFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AppEmojiFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `emoji` field. */
  emoji?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emojiCode` field. */
  emojiCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AppEmojiFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AppEmojiFilter>>;
  /** Filter by the object’s `sortOrder` field. */
  sortOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `AppEmoji` */
export type AppEmojiInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  emojiCode?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  sortOrder?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `AppEmoji` values. */
export type AppEmojisConnection = {
  __typename?: 'AppEmojisConnection';
  /** A list of edges which contains the `AppEmoji` and cursor to aid in pagination. */
  edges: Array<AppEmojisEdge>;
  /** A list of `AppEmoji` objects. */
  nodes: Array<AppEmoji>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AppEmoji` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `AppEmoji` edge in the connection. */
export type AppEmojisEdge = {
  __typename?: 'AppEmojisEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `AppEmoji` at the end of the edge. */
  node: AppEmoji;
};

/** Methods to use when ordering `AppEmoji`. */
export enum AppEmojisOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EmojiAsc = 'EMOJI_ASC',
  EmojiCodeAsc = 'EMOJI_CODE_ASC',
  EmojiCodeDesc = 'EMOJI_CODE_DESC',
  EmojiDesc = 'EMOJI_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  SortOrderAsc = 'SORT_ORDER_ASC',
  SortOrderDesc = 'SORT_ORDER_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['BigInt']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['BigInt']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['BigInt']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['BigInt']['input']>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};
export type Boundary = {
  bottom?: InputMaybe<Scalars['Float']['input']>;
  left?: InputMaybe<Scalars['Float']['input']>;
  maxLatitude?: InputMaybe<Scalars['Float']['input']>;
  maxLongitude?: InputMaybe<Scalars['Float']['input']>;
  minLatitude?: InputMaybe<Scalars['Float']['input']>;
  minLongitude?: InputMaybe<Scalars['Float']['input']>;
  right?: InputMaybe<Scalars['Float']['input']>;
  top?: InputMaybe<Scalars['Float']['input']>;
};

/** All input for the create `AppBadge` mutation. */
export type CreateAppBadgeInput = {
  /** The `AppBadge` to be created by this mutation. */
  appBadge: AppBadgeInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `AppBadge` mutation. */
export type CreateAppBadgePayload = {
  __typename?: 'CreateAppBadgePayload';
  /** The `AppBadge` that was created by this mutation. */
  appBadge?: Maybe<AppBadge>;
  /** An edge for our `AppBadge`. May be used by Relay 1. */
  appBadgeEdge?: Maybe<AppBadgesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AppBadge` mutation. */
export type CreateAppBadgePayloadAppBadgeEdgeArgs = {
  orderBy?: InputMaybe<Array<AppBadgesOrderBy>>;
};

/** All input for the create `AppDropCategory` mutation. */
export type CreateAppDropCategoryInput = {
  /** The `AppDropCategory` to be created by this mutation. */
  appDropCategory: AppDropCategoryInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `AppDropCategory` mutation. */
export type CreateAppDropCategoryPayload = {
  __typename?: 'CreateAppDropCategoryPayload';
  /** The `AppDropCategory` that was created by this mutation. */
  appDropCategory?: Maybe<AppDropCategory>;
  /** An edge for our `AppDropCategory`. May be used by Relay 1. */
  appDropCategoryEdge?: Maybe<AppDropCategoriesEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AppDropCategory` mutation. */
export type CreateAppDropCategoryPayloadAppDropCategoryEdgeArgs = {
  orderBy?: InputMaybe<Array<AppDropCategoriesOrderBy>>;
};

/** All input for the create `AppEmoji` mutation. */
export type CreateAppEmojiInput = {
  /** The `AppEmoji` to be created by this mutation. */
  appEmoji: AppEmojiInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
};

/** The output of our create `AppEmoji` mutation. */
export type CreateAppEmojiPayload = {
  __typename?: 'CreateAppEmojiPayload';
  /** The `AppEmoji` that was created by this mutation. */
  appEmoji?: Maybe<AppEmoji>;
  /** An edge for our `AppEmoji`. May be used by Relay 1. */
  appEmojiEdge?: Maybe<AppEmojisEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AppEmoji` mutation. */
export type CreateAppEmojiPayloadAppEmojiEdgeArgs = {
  orderBy?: InputMaybe<Array<AppEmojisOrderBy>>;
};

/** All input for the create `GeoActivity` mutation. */
export type CreateGeoActivityInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `GeoActivity` to be created by this mutation. */
  geoActivity: GeoActivityInput;
};

/** The output of our create `GeoActivity` mutation. */
export type CreateGeoActivityPayload = {
  __typename?: 'CreateGeoActivityPayload';
  /** Reads a single `AppBadge` that is related to this `GeoActivity`. */
  appBadge?: Maybe<AppBadge>;
  /** Reads a single `AppDropCategory` that is related to this `GeoActivity`. */
  appDropCategory?: Maybe<AppDropCategory>;
  /** Reads a single `AppEmoji` that is related to this `GeoActivity`. */
  appEmoji?: Maybe<AppEmoji>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `GeoActivity` that was created by this mutation. */
  geoActivity?: Maybe<GeoActivity>;
  /** An edge for our `GeoActivity`. May be used by Relay 1. */
  geoActivityEdge?: Maybe<GeoActivitiesEdge>;
  /** Reads a single `GeoCode` that is related to this `GeoActivity`. */
  geocode?: Maybe<GeoCode>;
  /** Reads a single `GeoCode` that is related to this `GeoActivity`. */
  linkToGeocode?: Maybe<GeoCode>;
  /** Reads a single `User` that is related to this `GeoActivity`. */
  owner?: Maybe<User>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserBusinessCard` that is related to this `GeoActivity`. */
  userBusinessCard?: Maybe<UserBusinessCard>;
};

/** The output of our create `GeoActivity` mutation. */
export type CreateGeoActivityPayloadGeoActivityEdgeArgs = {
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/** All input for the create `GeoActivityTag` mutation. */
export type CreateGeoActivityTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `GeoActivityTag` to be created by this mutation. */
  geoActivityTag: GeoActivityTagInput;
};

/** The output of our create `GeoActivityTag` mutation. */
export type CreateGeoActivityTagPayload = {
  __typename?: 'CreateGeoActivityTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `GeoActivityTag` that was created by this mutation. */
  geoActivityTag?: Maybe<GeoActivityTag>;
  /** An edge for our `GeoActivityTag`. May be used by Relay 1. */
  geoActivityTagEdge?: Maybe<GeoActivityTagsEdge>;
  /** Reads a single `GeoActivity` that is related to this `GeoActivityTag`. */
  geoactivity?: Maybe<GeoActivity>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `GeoActivityTag`. */
  tag?: Maybe<Tag>;
};

/** The output of our create `GeoActivityTag` mutation. */
export type CreateGeoActivityTagPayloadGeoActivityTagEdgeArgs = {
  orderBy?: InputMaybe<Array<GeoActivityTagsOrderBy>>;
};

/** All input for the create `GeoCode` mutation. */
export type CreateGeoCodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `GeoCode` to be created by this mutation. */
  geoCode: GeoCodeInput;
};

/** The output of our create `GeoCode` mutation. */
export type CreateGeoCodePayload = {
  __typename?: 'CreateGeoCodePayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `GeoCode` that was created by this mutation. */
  geoCode?: Maybe<GeoCode>;
  /** An edge for our `GeoCode`. May be used by Relay 1. */
  geoCodeEdge?: Maybe<GeoCodesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `GeoCode`. */
  verifiedOwner?: Maybe<User>;
};

/** The output of our create `GeoCode` mutation. */
export type CreateGeoCodePayloadGeoCodeEdgeArgs = {
  orderBy?: InputMaybe<Array<GeoCodesOrderBy>>;
};

/** All input for the create `GeoCodeTag` mutation. */
export type CreateGeoCodeTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `GeoCodeTag` to be created by this mutation. */
  geoCodeTag: GeoCodeTagInput;
};

/** The output of our create `GeoCodeTag` mutation. */
export type CreateGeoCodeTagPayload = {
  __typename?: 'CreateGeoCodeTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** The `GeoCodeTag` that was created by this mutation. */
  geoCodeTag?: Maybe<GeoCodeTag>;
  /** An edge for our `GeoCodeTag`. May be used by Relay 1. */
  geoCodeTagEdge?: Maybe<GeoCodeTagsEdge>;
  /** Reads a single `GeoCode` that is related to this `GeoCodeTag`. */
  geocode?: Maybe<GeoCode>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Tag` that is related to this `GeoCodeTag`. */
  tag?: Maybe<Tag>;
};

/** The output of our create `GeoCodeTag` mutation. */
export type CreateGeoCodeTagPayloadGeoCodeTagEdgeArgs = {
  orderBy?: InputMaybe<Array<GeoCodeTagsOrderBy>>;
};

/** All input for the create `Tag` mutation. */
export type CreateTagInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `Tag` to be created by this mutation. */
  tag: TagInput;
};

/** The output of our create `Tag` mutation. */
export type CreateTagPayload = {
  __typename?: 'CreateTagPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Tag` that was created by this mutation. */
  tag?: Maybe<Tag>;
  /** An edge for our `Tag`. May be used by Relay 1. */
  tagEdge?: Maybe<TagsEdge>;
};

/** The output of our create `Tag` mutation. */
export type CreateTagPayloadTagEdgeArgs = {
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** All input for the create `UserBusinessCard` mutation. */
export type CreateUserBusinessCardInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `UserBusinessCard` to be created by this mutation. */
  userBusinessCard: UserBusinessCardInput;
};

/** The output of our create `UserBusinessCard` mutation. */
export type CreateUserBusinessCardPayload = {
  __typename?: 'CreateUserBusinessCardPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserBusinessCard` that was created by this mutation. */
  userBusinessCard?: Maybe<UserBusinessCard>;
  /** An edge for our `UserBusinessCard`. May be used by Relay 1. */
  userBusinessCardEdge?: Maybe<UserBusinessCardsEdge>;
};

/** The output of our create `UserBusinessCard` mutation. */
export type CreateUserBusinessCardPayloadUserBusinessCardEdgeArgs = {
  orderBy?: InputMaybe<Array<UserBusinessCardsOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars['String']['input']>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars['String']['output']>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Datetime']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Datetime']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Datetime']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Datetime']['input']>>;
};
export enum EnumGeoActivitiesDropCategory {
  Activism = 'ACTIVISM',
  Business = 'BUSINESS',
  Event = 'EVENT',
  Info = 'INFO',
  Parking = 'PARKING',
  Restroom = 'RESTROOM',
  Tourism = 'TOURISM',
  WiFi = 'WI_FI',
}

/** A filter to be used against EnumGeoActivitiesDropCategory fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoActivitiesDropCategoryFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoActivitiesDropCategory>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoActivitiesDropCategory>>;
};
export enum EnumGeoActivitiesMessageType {
  Alert = 'ALERT',
  Info = 'INFO',
  News = 'NEWS',
}

/** A filter to be used against EnumGeoActivitiesMessageType fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoActivitiesMessageTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoActivitiesMessageType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoActivitiesMessageType>>;
};
export enum EnumGeoActivitiesType {
  Cast = 'CAST',
  Drop = 'DROP',
  Message = 'MESSAGE',
}

/** A filter to be used against EnumGeoActivitiesType fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoActivitiesTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoActivitiesType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoActivitiesType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoActivitiesType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoActivitiesType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoActivitiesType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoActivitiesType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoActivitiesType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoActivitiesType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoActivitiesType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoActivitiesType>>;
};
export enum EnumGeoActivitiesVisibilityDurationSetting {
  AlwaysOnMap = 'ALWAYS_ON_MAP',
  HideAfterDurationFromStart = 'HIDE_AFTER_DURATION_FROM_START',
  HideAtSpecificDate = 'HIDE_AT_SPECIFIC_DATE',
}

/** A filter to be used against EnumGeoActivitiesVisibilityDurationSetting fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoActivitiesVisibilityDurationSettingFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoActivitiesVisibilityDurationSetting>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoActivitiesVisibilityDurationSetting>>;
};
export enum EnumGeoActivitiesVisibilitySetting {
  Friends = 'FRIENDS',
  Groups = 'GROUPS',
  Owner = 'OWNER',
  Public = 'PUBLIC',
}

/** A filter to be used against EnumGeoActivitiesVisibilitySetting fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoActivitiesVisibilitySettingFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoActivitiesVisibilitySetting>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoActivitiesVisibilitySetting>>;
};
export enum EnumGeoCodesType {
  Activity = 'ACTIVITY',
  Address = 'ADDRESS',
  BusinessLocation = 'BUSINESS_LOCATION',
  City = 'CITY',
  Contintent = 'CONTINTENT',
  Coordinates = 'COORDINATES',
  Country = 'COUNTRY',
  County = 'COUNTY',
  CurrentLocation = 'CURRENT_LOCATION',
  Custom = 'CUSTOM',
  Geofence = 'GEOFENCE',
  GroupLocation = 'GROUP_LOCATION',
  HomeLocation = 'HOME_LOCATION',
  LastKnownLocation = 'LAST_KNOWN_LOCATION',
  LocationShare = 'LOCATION_SHARE',
  Neighborhood = 'NEIGHBORHOOD',
  Place = 'PLACE',
  PostalCode = 'POSTAL_CODE',
  Province = 'PROVINCE',
  ResidentialNeighborhood = 'RESIDENTIAL_NEIGHBORHOOD',
  SearchLocation = 'SEARCH_LOCATION',
  State = 'STATE',
}

/** A filter to be used against EnumGeoCodesType fields. All fields are combined with a logical ‘and.’ */
export type EnumGeoCodesTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<EnumGeoCodesType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<EnumGeoCodesType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<EnumGeoCodesType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<EnumGeoCodesType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<EnumGeoCodesType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<EnumGeoCodesType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<EnumGeoCodesType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<EnumGeoCodesType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<EnumGeoCodesType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<EnumGeoCodesType>>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Float']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Float']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Float']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Float']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** A connection to a list of `GeoActivity` values. */
export type GeoActivitiesConnection = {
  __typename?: 'GeoActivitiesConnection';
  /** A list of edges which contains the `GeoActivity` and cursor to aid in pagination. */
  edges: Array<GeoActivitiesEdge>;
  /** A list of `GeoActivity` objects. */
  nodes: Array<GeoActivity>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeoActivity` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GeoActivity` edge in the connection. */
export type GeoActivitiesEdge = {
  __typename?: 'GeoActivitiesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GeoActivity` at the end of the edge. */
  node: GeoActivity;
};

/** Methods to use when ordering `GeoActivity`. */
export enum GeoActivitiesOrderBy {
  AppBadgeIdAsc = 'APP_BADGE_ID_ASC',
  AppBadgeIdDesc = 'APP_BADGE_ID_DESC',
  AppDropCategoryIdAsc = 'APP_DROP_CATEGORY_ID_ASC',
  AppDropCategoryIdDesc = 'APP_DROP_CATEGORY_ID_DESC',
  AppEmojiIdAsc = 'APP_EMOJI_ID_ASC',
  AppEmojiIdDesc = 'APP_EMOJI_ID_DESC',
  BadgeCodeAsc = 'BADGE_CODE_ASC',
  BadgeCodeDesc = 'BADGE_CODE_DESC',
  BadgeLabelAsc = 'BADGE_LABEL_ASC',
  BadgeLabelDesc = 'BADGE_LABEL_DESC',
  BusinessOfferLinkAsc = 'BUSINESS_OFFER_LINK_ASC',
  BusinessOfferLinkDesc = 'BUSINESS_OFFER_LINK_DESC',
  CommentCountAsc = 'COMMENT_COUNT_ASC',
  CommentCountDesc = 'COMMENT_COUNT_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrentOfferClaimCountAsc = 'CURRENT_OFFER_CLAIM_COUNT_ASC',
  CurrentOfferClaimCountDesc = 'CURRENT_OFFER_CLAIM_COUNT_DESC',
  CurrentPromotionEndDateAsc = 'CURRENT_PROMOTION_END_DATE_ASC',
  CurrentPromotionEndDateDesc = 'CURRENT_PROMOTION_END_DATE_DESC',
  CurrentPromotionStartDateAsc = 'CURRENT_PROMOTION_START_DATE_ASC',
  CurrentPromotionStartDateDesc = 'CURRENT_PROMOTION_START_DATE_DESC',
  CurrentPromotionTotalViewsAsc = 'CURRENT_PROMOTION_TOTAL_VIEWS_ASC',
  CurrentPromotionTotalViewsDesc = 'CURRENT_PROMOTION_TOTAL_VIEWS_DESC',
  DatafeedLocationAsc = 'DATAFEED_LOCATION_ASC',
  DatafeedLocationDesc = 'DATAFEED_LOCATION_DESC',
  DatafeedProcessedErrorsAsc = 'DATAFEED_PROCESSED_ERRORS_ASC',
  DatafeedProcessedErrorsDesc = 'DATAFEED_PROCESSED_ERRORS_DESC',
  DatafeedProcessedTimestampAsc = 'DATAFEED_PROCESSED_TIMESTAMP_ASC',
  DatafeedProcessedTimestampDesc = 'DATAFEED_PROCESSED_TIMESTAMP_DESC',
  DatafeedSharesAsc = 'DATAFEED_SHARES_ASC',
  DatafeedSharesDesc = 'DATAFEED_SHARES_DESC',
  DataloaderKeyAsc = 'DATALOADER_KEY_ASC',
  DataloaderKeyDesc = 'DATALOADER_KEY_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  DisplayEndDateAsc = 'DISPLAY_END_DATE_ASC',
  DisplayEndDateDesc = 'DISPLAY_END_DATE_DESC',
  DisplayStartDateAsc = 'DISPLAY_START_DATE_ASC',
  DisplayStartDateDesc = 'DISPLAY_START_DATE_DESC',
  DownVoteCountAsc = 'DOWN_VOTE_COUNT_ASC',
  DownVoteCountDesc = 'DOWN_VOTE_COUNT_DESC',
  DropCategoryAsc = 'DROP_CATEGORY_ASC',
  DropCategoryDesc = 'DROP_CATEGORY_DESC',
  DropCategoryIconUrlAsc = 'DROP_CATEGORY_ICON_URL_ASC',
  DropCategoryIconUrlDesc = 'DROP_CATEGORY_ICON_URL_DESC',
  EmojiCodeAsc = 'EMOJI_CODE_ASC',
  EmojiCodeDesc = 'EMOJI_CODE_DESC',
  EmojiLabelAsc = 'EMOJI_LABEL_ASC',
  EmojiLabelDesc = 'EMOJI_LABEL_DESC',
  EndDateAsc = 'END_DATE_ASC',
  EndDateDesc = 'END_DATE_DESC',
  ExternalSourceAsc = 'EXTERNAL_SOURCE_ASC',
  ExternalSourceDesc = 'EXTERNAL_SOURCE_DESC',
  ExternalSourceIdAsc = 'EXTERNAL_SOURCE_ID_ASC',
  ExternalSourceIdDesc = 'EXTERNAL_SOURCE_ID_DESC',
  GeocastCountAsc = 'GEOCAST_COUNT_ASC',
  GeocastCountDesc = 'GEOCAST_COUNT_DESC',
  GeocastRelativePositionAsc = 'GEOCAST_RELATIVE_POSITION_ASC',
  GeocastRelativePositionDesc = 'GEOCAST_RELATIVE_POSITION_DESC',
  GeocodeErrorsAsc = 'GEOCODE_ERRORS_ASC',
  GeocodeErrorsDesc = 'GEOCODE_ERRORS_DESC',
  GeocodeIdAsc = 'GEOCODE_ID_ASC',
  GeocodeIdDesc = 'GEOCODE_ID_DESC',
  GeocodeJsonAsc = 'GEOCODE_JSON_ASC',
  GeocodeJsonDesc = 'GEOCODE_JSON_DESC',
  GeocodeTimestampAsc = 'GEOCODE_TIMESTAMP_ASC',
  GeocodeTimestampDesc = 'GEOCODE_TIMESTAMP_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsExternalSourcedAsc = 'IS_EXTERNAL_SOURCED_ASC',
  IsExternalSourcedDesc = 'IS_EXTERNAL_SOURCED_DESC',
  IsGovernmentRelatedAsc = 'IS_GOVERNMENT_RELATED_ASC',
  IsGovernmentRelatedDesc = 'IS_GOVERNMENT_RELATED_DESC',
  IsOfferAsc = 'IS_OFFER_ASC',
  IsOfferDesc = 'IS_OFFER_DESC',
  IsPopularAsc = 'IS_POPULAR_ASC',
  IsPopularDesc = 'IS_POPULAR_DESC',
  IsPromotedAsc = 'IS_PROMOTED_ASC',
  IsPromotedDesc = 'IS_PROMOTED_DESC',
  IsPublicAsc = 'IS_PUBLIC_ASC',
  IsPublicDesc = 'IS_PUBLIC_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  LikeCountAsc = 'LIKE_COUNT_ASC',
  LikeCountDesc = 'LIKE_COUNT_DESC',
  LinkToGeoactivityIdAsc = 'LINK_TO_GEOACTIVITY_ID_ASC',
  LinkToGeoactivityIdDesc = 'LINK_TO_GEOACTIVITY_ID_DESC',
  LinkToGeocodeIdAsc = 'LINK_TO_GEOCODE_ID_ASC',
  LinkToGeocodeIdDesc = 'LINK_TO_GEOCODE_ID_DESC',
  LinkToGeocontextIdAsc = 'LINK_TO_GEOCONTEXT_ID_ASC',
  LinkToGeocontextIdDesc = 'LINK_TO_GEOCONTEXT_ID_DESC',
  MessageAuthorAsc = 'MESSAGE_AUTHOR_ASC',
  MessageAuthorDesc = 'MESSAGE_AUTHOR_DESC',
  MessageBodyAsc = 'MESSAGE_BODY_ASC',
  MessageBodyDesc = 'MESSAGE_BODY_DESC',
  MessageBodyTsvAsc = 'MESSAGE_BODY_TSV_ASC',
  MessageBodyTsvDesc = 'MESSAGE_BODY_TSV_DESC',
  MessageCategoriesAsc = 'MESSAGE_CATEGORIES_ASC',
  MessageCategoriesDesc = 'MESSAGE_CATEGORIES_DESC',
  MessageDetailsAsc = 'MESSAGE_DETAILS_ASC',
  MessageDetailsDesc = 'MESSAGE_DETAILS_DESC',
  MessageIsDynamicAsc = 'MESSAGE_IS_DYNAMIC_ASC',
  MessageIsDynamicDesc = 'MESSAGE_IS_DYNAMIC_DESC',
  MessageMetadataAsc = 'MESSAGE_METADATA_ASC',
  MessageMetadataDesc = 'MESSAGE_METADATA_DESC',
  MessagePublishedDateAsc = 'MESSAGE_PUBLISHED_DATE_ASC',
  MessagePublishedDateDesc = 'MESSAGE_PUBLISHED_DATE_DESC',
  MessageSubjectAsc = 'MESSAGE_SUBJECT_ASC',
  MessageSubjectDesc = 'MESSAGE_SUBJECT_DESC',
  MessageSubjectTsvAsc = 'MESSAGE_SUBJECT_TSV_ASC',
  MessageSubjectTsvDesc = 'MESSAGE_SUBJECT_TSV_DESC',
  MessageTypeAsc = 'MESSAGE_TYPE_ASC',
  MessageTypeDesc = 'MESSAGE_TYPE_DESC',
  MetaJsonAsc = 'META_JSON_ASC',
  MetaJsonDesc = 'META_JSON_DESC',
  Natural = 'NATURAL',
  OwnerAccountIdAsc = 'OWNER_ACCOUNT_ID_ASC',
  OwnerAccountIdDesc = 'OWNER_ACCOUNT_ID_DESC',
  OwnerDeleteAfterEventAsc = 'OWNER_DELETE_AFTER_EVENT_ASC',
  OwnerDeleteAfterEventDesc = 'OWNER_DELETE_AFTER_EVENT_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  OwnerLimitOfferClaimCountAsc = 'OWNER_LIMIT_OFFER_CLAIM_COUNT_ASC',
  OwnerLimitOfferClaimCountDesc = 'OWNER_LIMIT_OFFER_CLAIM_COUNT_DESC',
  OwnerRequirePasswordAsc = 'OWNER_REQUIRE_PASSWORD_ASC',
  OwnerRequirePasswordDesc = 'OWNER_REQUIRE_PASSWORD_DESC',
  OwnerRequireProximityDistanceAsc = 'OWNER_REQUIRE_PROXIMITY_DISTANCE_ASC',
  OwnerRequireProximityDistanceDesc = 'OWNER_REQUIRE_PROXIMITY_DISTANCE_DESC',
  OwnerShowCheckInAsc = 'OWNER_SHOW_CHECK_IN_ASC',
  OwnerShowCheckInDesc = 'OWNER_SHOW_CHECK_IN_DESC',
  ProcessExecuteContentModerationCompleteAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_ASC',
  ProcessExecuteContentModerationCompleteDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_DESC',
  ProcessExecuteContentModerationDateAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_ASC',
  ProcessExecuteContentModerationDateDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_DESC',
  ProcessExecuteContentModerationErrorsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_ASC',
  ProcessExecuteContentModerationErrorsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_DESC',
  ProcessExecuteContentModerationResultsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_ASC',
  ProcessExecuteContentModerationResultsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_DESC',
  ProcessExecuteContentModerationReviewerOverrideAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_ASC',
  ProcessExecuteContentModerationReviewerOverrideDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_DESC',
  ProcessGeotaggerLastSyncAsc = 'PROCESS_GEOTAGGER_LAST_SYNC_ASC',
  ProcessGeotaggerLastSyncDesc = 'PROCESS_GEOTAGGER_LAST_SYNC_DESC',
  ProcessGeotaggerLastSyncErrorsAsc = 'PROCESS_GEOTAGGER_LAST_SYNC_ERRORS_ASC',
  ProcessGeotaggerLastSyncErrorsDesc = 'PROCESS_GEOTAGGER_LAST_SYNC_ERRORS_DESC',
  PromotionIdAsc = 'PROMOTION_ID_ASC',
  PromotionIdDesc = 'PROMOTION_ID_DESC',
  RecommendationsAsc = 'RECOMMENDATIONS_ASC',
  RecommendationsDesc = 'RECOMMENDATIONS_DESC',
  RefAudioUrlAsc = 'REF_AUDIO_URL_ASC',
  RefAudioUrlDesc = 'REF_AUDIO_URL_DESC',
  RefGifUrlAsc = 'REF_GIF_URL_ASC',
  RefGifUrlDesc = 'REF_GIF_URL_DESC',
  RefImageUrlAsc = 'REF_IMAGE_URL_ASC',
  RefImageUrlDesc = 'REF_IMAGE_URL_DESC',
  RefMultiMediaAsc = 'REF_MULTI_MEDIA_ASC',
  RefMultiMediaDesc = 'REF_MULTI_MEDIA_DESC',
  RefUrlAsc = 'REF_URL_ASC',
  RefUrlDesc = 'REF_URL_DESC',
  RefVideoUrlAsc = 'REF_VIDEO_URL_ASC',
  RefVideoUrlDesc = 'REF_VIDEO_URL_DESC',
  RelatedToGeocodeJsonAsc = 'RELATED_TO_GEOCODE_JSON_ASC',
  RelatedToGeocodeJsonDesc = 'RELATED_TO_GEOCODE_JSON_DESC',
  SearchableTsvAsc = 'SEARCHABLE_TSV_ASC',
  SearchableTsvDesc = 'SEARCHABLE_TSV_DESC',
  ShowBusinessCardAsc = 'SHOW_BUSINESS_CARD_ASC',
  ShowBusinessCardDesc = 'SHOW_BUSINESS_CARD_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TotalViewsAsc = 'TOTAL_VIEWS_ASC',
  TotalViewsDesc = 'TOTAL_VIEWS_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UpVoteCountAsc = 'UP_VOTE_COUNT_ASC',
  UpVoteCountDesc = 'UP_VOTE_COUNT_DESC',
  UserBusinessCardIdAsc = 'USER_BUSINESS_CARD_ID_ASC',
  UserBusinessCardIdDesc = 'USER_BUSINESS_CARD_ID_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  VisibilityAsc = 'VISIBILITY_ASC',
  VisibilityDesc = 'VISIBILITY_DESC',
  VisibilityDurationAsc = 'VISIBILITY_DURATION_ASC',
  VisibilityDurationDesc = 'VISIBILITY_DURATION_DESC',
  VisibilityDurationSettingAsc = 'VISIBILITY_DURATION_SETTING_ASC',
  VisibilityDurationSettingDesc = 'VISIBILITY_DURATION_SETTING_DESC',
  VisibilityEndDateAsc = 'VISIBILITY_END_DATE_ASC',
  VisibilityEndDateDesc = 'VISIBILITY_END_DATE_DESC',
  VisibilityLabelAsc = 'VISIBILITY_LABEL_ASC',
  VisibilityLabelDesc = 'VISIBILITY_LABEL_DESC',
  VisibilityLevelAsc = 'VISIBILITY_LEVEL_ASC',
  VisibilityLevelDesc = 'VISIBILITY_LEVEL_DESC',
  VisibilityRadiusAsc = 'VISIBILITY_RADIUS_ASC',
  VisibilityRadiusDesc = 'VISIBILITY_RADIUS_DESC',
  VisibilitySettingAsc = 'VISIBILITY_SETTING_ASC',
  VisibilitySettingDesc = 'VISIBILITY_SETTING_DESC',
  VisibilityStartDateAsc = 'VISIBILITY_START_DATE_ASC',
  VisibilityStartDateDesc = 'VISIBILITY_START_DATE_DESC',
}
export type GeoActivity = {
  __typename?: 'GeoActivity';
  /** Reads a single `AppBadge` that is related to this `GeoActivity`. */
  appBadge?: Maybe<AppBadge>;
  appBadgeId?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `AppDropCategory` that is related to this `GeoActivity`. */
  appDropCategory?: Maybe<AppDropCategory>;
  appDropCategoryId?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `AppEmoji` that is related to this `GeoActivity`. */
  appEmoji?: Maybe<AppEmoji>;
  appEmojiId?: Maybe<Scalars['BigInt']['output']>;
  badgeCode?: Maybe<Scalars['String']['output']>;
  badgeLabel?: Maybe<Scalars['String']['output']>;
  businessOfferLink?: Maybe<Scalars['String']['output']>;
  commentCount?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  currentOfferClaimCount?: Maybe<Scalars['Int']['output']>;
  currentPromotionEndDate?: Maybe<Scalars['Datetime']['output']>;
  currentPromotionStartDate?: Maybe<Scalars['Datetime']['output']>;
  currentPromotionTotalViews?: Maybe<Scalars['Int']['output']>;
  datafeedLocation?: Maybe<Scalars['JSON']['output']>;
  datafeedProcessedErrors?: Maybe<Scalars['String']['output']>;
  datafeedProcessedTimestamp?: Maybe<Scalars['Datetime']['output']>;
  datafeedShares?: Maybe<Scalars['JSON']['output']>;
  dataloaderKey?: Maybe<Scalars['String']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  displayEndDate?: Maybe<Scalars['Datetime']['output']>;
  displayStartDate?: Maybe<Scalars['Datetime']['output']>;
  downVoteCount?: Maybe<Scalars['Int']['output']>;
  dropCategory?: Maybe<EnumGeoActivitiesDropCategory>;
  dropCategoryIconUrl?: Maybe<Scalars['String']['output']>;
  emojiCode?: Maybe<Scalars['String']['output']>;
  emojiLabel?: Maybe<Scalars['String']['output']>;
  endDate?: Maybe<Scalars['Datetime']['output']>;
  externalSource?: Maybe<Scalars['String']['output']>;
  externalSourceId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `GeoActivityTag`. */
  geoActivityTagsByGeoactivityId: GeoActivityTagsConnection;
  geocastCount?: Maybe<Scalars['Int']['output']>;
  geocastRelativePosition?: Maybe<Scalars['Boolean']['output']>;
  /** Reads a single `GeoCode` that is related to this `GeoActivity`. */
  geocode?: Maybe<GeoCode>;
  geocodeErrors?: Maybe<Scalars['String']['output']>;
  geocodeId?: Maybe<Scalars['BigInt']['output']>;
  geocodeJson?: Maybe<Scalars['JSON']['output']>;
  geocodeTimestamp?: Maybe<Scalars['Datetime']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isExternalSourced?: Maybe<Scalars['Boolean']['output']>;
  isGovernmentRelated?: Maybe<Scalars['Boolean']['output']>;
  isOffer?: Maybe<Scalars['Boolean']['output']>;
  isPopular?: Maybe<Scalars['Boolean']['output']>;
  isPromoted?: Maybe<Scalars['Boolean']['output']>;
  isPublic?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  linkToGeoactivityId?: Maybe<Scalars['BigInt']['output']>;
  /** Reads a single `GeoCode` that is related to this `GeoActivity`. */
  linkToGeocode?: Maybe<GeoCode>;
  linkToGeocodeId?: Maybe<Scalars['BigInt']['output']>;
  linkToGeocontextId?: Maybe<Scalars['BigInt']['output']>;
  messageAuthor?: Maybe<Scalars['String']['output']>;
  messageBody?: Maybe<Scalars['String']['output']>;
  messageBodyTsv?: Maybe<Scalars['String']['output']>;
  messageCategories?: Maybe<Scalars['JSON']['output']>;
  messageDetails?: Maybe<Scalars['String']['output']>;
  messageIsDynamic?: Maybe<Scalars['Boolean']['output']>;
  messageMetadata?: Maybe<Scalars['JSON']['output']>;
  messagePublishedDate?: Maybe<Scalars['Datetime']['output']>;
  messageSubject?: Maybe<Scalars['String']['output']>;
  messageSubjectTsv?: Maybe<Scalars['String']['output']>;
  messageType?: Maybe<EnumGeoActivitiesMessageType>;
  metaJson?: Maybe<Scalars['JSON']['output']>;
  /** Reads a single `User` that is related to this `GeoActivity`. */
  owner?: Maybe<User>;
  ownerAccountId?: Maybe<Scalars['BigInt']['output']>;
  ownerDeleteAfterEvent?: Maybe<Scalars['Boolean']['output']>;
  ownerId?: Maybe<Scalars['BigInt']['output']>;
  ownerLimitOfferClaimCount?: Maybe<Scalars['Int']['output']>;
  ownerRequirePassword?: Maybe<Scalars['String']['output']>;
  ownerRequireProximityDistance?: Maybe<Scalars['Float']['output']>;
  ownerShowCheckIn?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationComplete?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationDate?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationErrors?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationResults?: Maybe<Scalars['JSON']['output']>;
  processExecuteContentModerationReviewerOverride?: Maybe<Scalars['Boolean']['output']>;
  processGeotaggerLastSync?: Maybe<Scalars['Datetime']['output']>;
  processGeotaggerLastSyncErrors?: Maybe<Scalars['String']['output']>;
  promotionId?: Maybe<Scalars['String']['output']>;
  recommendations?: Maybe<Scalars['JSON']['output']>;
  refAudioUrl?: Maybe<Scalars['String']['output']>;
  refGifUrl?: Maybe<Scalars['String']['output']>;
  refImageUrl?: Maybe<Scalars['String']['output']>;
  refMultiMedia?: Maybe<Scalars['JSON']['output']>;
  refUrl?: Maybe<Scalars['String']['output']>;
  refVideoUrl?: Maybe<Scalars['String']['output']>;
  relatedToGeocodeJson?: Maybe<Scalars['JSON']['output']>;
  searchableTsv?: Maybe<Scalars['String']['output']>;
  showBusinessCard?: Maybe<Scalars['Boolean']['output']>;
  startDate?: Maybe<Scalars['Datetime']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  totalViews?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<EnumGeoActivitiesType>;
  upVoteCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `UserBusinessCard` that is related to this `GeoActivity`. */
  userBusinessCard?: Maybe<UserBusinessCard>;
  userBusinessCardId?: Maybe<Scalars['BigInt']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  visibility?: Maybe<Scalars['Boolean']['output']>;
  visibilityDuration?: Maybe<Scalars['Int']['output']>;
  visibilityDurationSetting?: Maybe<EnumGeoActivitiesVisibilityDurationSetting>;
  visibilityEndDate?: Maybe<Scalars['Datetime']['output']>;
  visibilityLabel?: Maybe<Scalars['String']['output']>;
  visibilityLevel?: Maybe<Scalars['String']['output']>;
  visibilityRadius?: Maybe<Scalars['Int']['output']>;
  visibilitySetting?: Maybe<EnumGeoActivitiesVisibilitySetting>;
  visibilityStartDate?: Maybe<Scalars['Datetime']['output']>;
};
export type GeoActivityGeoActivityTagsByGeoactivityIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityTagCondition>;
  filter?: InputMaybe<GeoActivityTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivityTagsOrderBy>>;
};
export type GeoActivityAccess = {
  __typename?: 'GeoActivityAccess';
  allowedAccess?: Maybe<Scalars['Boolean']['output']>;
  allowedAccessReason?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
};

/**
 * A condition to be used against `GeoActivity` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GeoActivityCondition = {
  /** Checks for equality with the object’s `appBadgeId` field. */
  appBadgeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `appDropCategoryId` field. */
  appDropCategoryId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `appEmojiId` field. */
  appEmojiId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `badgeCode` field. */
  badgeCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `badgeLabel` field. */
  badgeLabel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `businessOfferLink` field. */
  businessOfferLink?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `commentCount` field. */
  commentCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `currentOfferClaimCount` field. */
  currentOfferClaimCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `currentPromotionEndDate` field. */
  currentPromotionEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `currentPromotionStartDate` field. */
  currentPromotionStartDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `currentPromotionTotalViews` field. */
  currentPromotionTotalViews?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `datafeedLocation` field. */
  datafeedLocation?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `datafeedProcessedErrors` field. */
  datafeedProcessedErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `datafeedProcessedTimestamp` field. */
  datafeedProcessedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `datafeedShares` field. */
  datafeedShares?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `dataloaderKey` field. */
  dataloaderKey?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `displayEndDate` field. */
  displayEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `displayStartDate` field. */
  displayStartDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `downVoteCount` field. */
  downVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `dropCategory` field. */
  dropCategory?: InputMaybe<EnumGeoActivitiesDropCategory>;
  /** Checks for equality with the object’s `dropCategoryIconUrl` field. */
  dropCategoryIconUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emojiCode` field. */
  emojiCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emojiLabel` field. */
  emojiLabel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `endDate` field. */
  endDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `externalSource` field. */
  externalSource?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `externalSourceId` field. */
  externalSourceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `geocastCount` field. */
  geocastCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `geocastRelativePosition` field. */
  geocastRelativePosition?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `geocodeErrors` field. */
  geocodeErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `geocodeJson` field. */
  geocodeJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `geocodeTimestamp` field. */
  geocodeTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isExternalSourced` field. */
  isExternalSourced?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isGovernmentRelated` field. */
  isGovernmentRelated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isOffer` field. */
  isOffer?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPopular` field. */
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPromoted` field. */
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPublic` field. */
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `likeCount` field. */
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `linkToGeoactivityId` field. */
  linkToGeoactivityId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `linkToGeocodeId` field. */
  linkToGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `linkToGeocontextId` field. */
  linkToGeocontextId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `messageAuthor` field. */
  messageAuthor?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageBody` field. */
  messageBody?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageBodyTsv` field. */
  messageBodyTsv?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageCategories` field. */
  messageCategories?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `messageDetails` field. */
  messageDetails?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageIsDynamic` field. */
  messageIsDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `messageMetadata` field. */
  messageMetadata?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `messagePublishedDate` field. */
  messagePublishedDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `messageSubject` field. */
  messageSubject?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageSubjectTsv` field. */
  messageSubjectTsv?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `messageType` field. */
  messageType?: InputMaybe<EnumGeoActivitiesMessageType>;
  /** Checks for equality with the object’s `metaJson` field. */
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `ownerAccountId` field. */
  ownerAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `ownerDeleteAfterEvent` field. */
  ownerDeleteAfterEvent?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `ownerLimitOfferClaimCount` field. */
  ownerLimitOfferClaimCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `ownerRequirePassword` field. */
  ownerRequirePassword?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ownerRequireProximityDistance` field. */
  ownerRequireProximityDistance?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `ownerShowCheckIn` field. */
  ownerShowCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationResults` field. */
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processGeotaggerLastSync` field. */
  processGeotaggerLastSync?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processGeotaggerLastSyncErrors` field. */
  processGeotaggerLastSyncErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `promotionId` field. */
  promotionId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recommendations` field. */
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `refAudioUrl` field. */
  refAudioUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `refGifUrl` field. */
  refGifUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `refImageUrl` field. */
  refImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `refMultiMedia` field. */
  refMultiMedia?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `refUrl` field. */
  refUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `refVideoUrl` field. */
  refVideoUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `relatedToGeocodeJson` field. */
  relatedToGeocodeJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `searchableTsv` field. */
  searchableTsv?: InputMaybe<Scalars['String']['input']>;
  /** Filters the list of GeoActivities to only those which match a custom filter. */
  sharingAndFilterCriteria?: InputMaybe<Scalars['String']['input']>;
  /** Filters the list of GeoActivities to only those which contain posts written by the specified user. */
  sharingEnforcedByViewerId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `showBusinessCard` field. */
  showBusinessCard?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `startDate` field. */
  startDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `totalViews` field. */
  totalViews?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<EnumGeoActivitiesType>;
  /** Checks for equality with the object’s `upVoteCount` field. */
  upVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `userBusinessCardId` field. */
  userBusinessCardId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  /** Filters the list of GeoActivityTags to only those which contain geocode within location bounds. */
  viewingLocation?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Checks for equality with the object’s `visibility` field. */
  visibility?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `visibilityDuration` field. */
  visibilityDuration?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `visibilityDurationSetting` field. */
  visibilityDurationSetting?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  /** Checks for equality with the object’s `visibilityEndDate` field. */
  visibilityEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `visibilityLabel` field. */
  visibilityLabel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `visibilityLevel` field. */
  visibilityLevel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `visibilityRadius` field. */
  visibilityRadius?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `visibilitySetting` field. */
  visibilitySetting?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  /** Checks for equality with the object’s `visibilityStartDate` field. */
  visibilityStartDate?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `GeoActivity` object types. All fields are combined with a logical ‘and.’ */
export type GeoActivityFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GeoActivityFilter>>;
  /** Filter by the object’s `appBadgeId` field. */
  appBadgeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `appDropCategoryId` field. */
  appDropCategoryId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `appEmojiId` field. */
  appEmojiId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `badgeCode` field. */
  badgeCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `badgeLabel` field. */
  badgeLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `businessOfferLink` field. */
  businessOfferLink?: InputMaybe<StringFilter>;
  /** Filter by the object’s `commentCount` field. */
  commentCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentOfferClaimCount` field. */
  currentOfferClaimCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `currentPromotionEndDate` field. */
  currentPromotionEndDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentPromotionStartDate` field. */
  currentPromotionStartDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currentPromotionTotalViews` field. */
  currentPromotionTotalViews?: InputMaybe<IntFilter>;
  /** Filter by the object’s `datafeedProcessedErrors` field. */
  datafeedProcessedErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datafeedProcessedTimestamp` field. */
  datafeedProcessedTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `dataloaderKey` field. */
  dataloaderKey?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `displayEndDate` field. */
  displayEndDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `displayStartDate` field. */
  displayStartDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `downVoteCount` field. */
  downVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `dropCategory` field. */
  dropCategory?: InputMaybe<EnumGeoActivitiesDropCategoryFilter>;
  /** Filter by the object’s `dropCategoryIconUrl` field. */
  dropCategoryIconUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emojiCode` field. */
  emojiCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emojiLabel` field. */
  emojiLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `endDate` field. */
  endDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `externalSource` field. */
  externalSource?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalSourceId` field. */
  externalSourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `geocastCount` field. */
  geocastCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `geocastRelativePosition` field. */
  geocastRelativePosition?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `geocodeErrors` field. */
  geocodeErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `geocodeTimestamp` field. */
  geocodeTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isExternalSourced` field. */
  isExternalSourced?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isGovernmentRelated` field. */
  isGovernmentRelated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOffer` field. */
  isOffer?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPopular` field. */
  isPopular?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPromoted` field. */
  isPromoted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPublic` field. */
  isPublic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likeCount` field. */
  likeCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `linkToGeoactivityId` field. */
  linkToGeoactivityId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `linkToGeocodeId` field. */
  linkToGeocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `linkToGeocontextId` field. */
  linkToGeocontextId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `messageAuthor` field. */
  messageAuthor?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageBody` field. */
  messageBody?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageDetails` field. */
  messageDetails?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageIsDynamic` field. */
  messageIsDynamic?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `messagePublishedDate` field. */
  messagePublishedDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `messageSubject` field. */
  messageSubject?: InputMaybe<StringFilter>;
  /** Filter by the object’s `messageType` field. */
  messageType?: InputMaybe<EnumGeoActivitiesMessageTypeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GeoActivityFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GeoActivityFilter>>;
  /** Filter by the object’s `ownerAccountId` field. */
  ownerAccountId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `ownerDeleteAfterEvent` field. */
  ownerDeleteAfterEvent?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `ownerLimitOfferClaimCount` field. */
  ownerLimitOfferClaimCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `ownerRequirePassword` field. */
  ownerRequirePassword?: InputMaybe<StringFilter>;
  /** Filter by the object’s `ownerRequireProximityDistance` field. */
  ownerRequireProximityDistance?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `ownerShowCheckIn` field. */
  ownerShowCheckIn?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processGeotaggerLastSync` field. */
  processGeotaggerLastSync?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processGeotaggerLastSyncErrors` field. */
  processGeotaggerLastSyncErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `promotionId` field. */
  promotionId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refAudioUrl` field. */
  refAudioUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refGifUrl` field. */
  refGifUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refImageUrl` field. */
  refImageUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refUrl` field. */
  refUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `refVideoUrl` field. */
  refVideoUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `showBusinessCard` field. */
  showBusinessCard?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `startDate` field. */
  startDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StringFilter>;
  /** Filter by the object’s `totalViews` field. */
  totalViews?: InputMaybe<IntFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<EnumGeoActivitiesTypeFilter>;
  /** Filter by the object’s `upVoteCount` field. */
  upVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `userBusinessCardId` field. */
  userBusinessCardId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `visibility` field. */
  visibility?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `visibilityDuration` field. */
  visibilityDuration?: InputMaybe<IntFilter>;
  /** Filter by the object’s `visibilityDurationSetting` field. */
  visibilityDurationSetting?: InputMaybe<EnumGeoActivitiesVisibilityDurationSettingFilter>;
  /** Filter by the object’s `visibilityEndDate` field. */
  visibilityEndDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `visibilityLabel` field. */
  visibilityLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `visibilityLevel` field. */
  visibilityLevel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `visibilityRadius` field. */
  visibilityRadius?: InputMaybe<IntFilter>;
  /** Filter by the object’s `visibilitySetting` field. */
  visibilitySetting?: InputMaybe<EnumGeoActivitiesVisibilitySettingFilter>;
  /** Filter by the object’s `visibilityStartDate` field. */
  visibilityStartDate?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `GeoActivity` */
export type GeoActivityInput = {
  appBadgeId?: InputMaybe<Scalars['BigInt']['input']>;
  appDropCategoryId?: InputMaybe<Scalars['BigInt']['input']>;
  appEmojiId?: InputMaybe<Scalars['BigInt']['input']>;
  badgeCode?: InputMaybe<Scalars['String']['input']>;
  badgeLabel?: InputMaybe<Scalars['String']['input']>;
  businessOfferLink?: InputMaybe<Scalars['String']['input']>;
  commentCount?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  currentOfferClaimCount?: InputMaybe<Scalars['Int']['input']>;
  currentPromotionEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  currentPromotionStartDate?: InputMaybe<Scalars['Datetime']['input']>;
  currentPromotionTotalViews?: InputMaybe<Scalars['Int']['input']>;
  datafeedLocation?: InputMaybe<Scalars['JSON']['input']>;
  datafeedProcessedErrors?: InputMaybe<Scalars['String']['input']>;
  datafeedProcessedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  datafeedShares?: InputMaybe<Scalars['JSON']['input']>;
  dataloaderKey?: InputMaybe<Scalars['String']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  displayEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  displayStartDate?: InputMaybe<Scalars['Datetime']['input']>;
  downVoteCount?: InputMaybe<Scalars['Int']['input']>;
  dropCategory?: InputMaybe<EnumGeoActivitiesDropCategory>;
  dropCategoryIconUrl?: InputMaybe<Scalars['String']['input']>;
  emojiCode?: InputMaybe<Scalars['String']['input']>;
  emojiLabel?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['Datetime']['input']>;
  externalSource?: InputMaybe<Scalars['String']['input']>;
  externalSourceId?: InputMaybe<Scalars['String']['input']>;
  geocastCount?: InputMaybe<Scalars['Int']['input']>;
  geocastRelativePosition?: InputMaybe<Scalars['Boolean']['input']>;
  geocodeErrors?: InputMaybe<Scalars['String']['input']>;
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  geocodeJson?: InputMaybe<Scalars['JSON']['input']>;
  geocodeTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isExternalSourced?: InputMaybe<Scalars['Boolean']['input']>;
  isGovernmentRelated?: InputMaybe<Scalars['Boolean']['input']>;
  isOffer?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isPromoted?: InputMaybe<Scalars['Boolean']['input']>;
  isPublic?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  linkToGeoactivityId?: InputMaybe<Scalars['BigInt']['input']>;
  linkToGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  linkToGeocontextId?: InputMaybe<Scalars['BigInt']['input']>;
  messageAuthor?: InputMaybe<Scalars['String']['input']>;
  messageBody?: InputMaybe<Scalars['String']['input']>;
  messageBodyTsv?: InputMaybe<Scalars['String']['input']>;
  messageCategories?: InputMaybe<Scalars['JSON']['input']>;
  messageDetails?: InputMaybe<Scalars['String']['input']>;
  messageIsDynamic?: InputMaybe<Scalars['Boolean']['input']>;
  messageMetadata?: InputMaybe<Scalars['JSON']['input']>;
  messagePublishedDate?: InputMaybe<Scalars['Datetime']['input']>;
  messageSubject?: InputMaybe<Scalars['String']['input']>;
  messageSubjectTsv?: InputMaybe<Scalars['String']['input']>;
  messageType?: InputMaybe<EnumGeoActivitiesMessageType>;
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  ownerAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  ownerDeleteAfterEvent?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  ownerLimitOfferClaimCount?: InputMaybe<Scalars['Int']['input']>;
  ownerRequirePassword?: InputMaybe<Scalars['String']['input']>;
  ownerRequireProximityDistance?: InputMaybe<Scalars['Float']['input']>;
  ownerShowCheckIn?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  processGeotaggerLastSync?: InputMaybe<Scalars['Datetime']['input']>;
  processGeotaggerLastSyncErrors?: InputMaybe<Scalars['String']['input']>;
  promotionId?: InputMaybe<Scalars['String']['input']>;
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  refAudioUrl?: InputMaybe<Scalars['String']['input']>;
  refGifUrl?: InputMaybe<Scalars['String']['input']>;
  refImageUrl?: InputMaybe<Scalars['String']['input']>;
  refMultiMedia?: InputMaybe<Scalars['JSON']['input']>;
  refUrl?: InputMaybe<Scalars['String']['input']>;
  refVideoUrl?: InputMaybe<Scalars['String']['input']>;
  relatedToGeocodeJson?: InputMaybe<Scalars['JSON']['input']>;
  searchableTsv?: InputMaybe<Scalars['String']['input']>;
  showBusinessCard?: InputMaybe<Scalars['Boolean']['input']>;
  startDate?: InputMaybe<Scalars['Datetime']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  totalViews?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<EnumGeoActivitiesType>;
  upVoteCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  userBusinessCardId?: InputMaybe<Scalars['BigInt']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  visibility?: InputMaybe<Scalars['Boolean']['input']>;
  visibilityDuration?: InputMaybe<Scalars['Int']['input']>;
  visibilityDurationSetting?: InputMaybe<EnumGeoActivitiesVisibilityDurationSetting>;
  visibilityEndDate?: InputMaybe<Scalars['Datetime']['input']>;
  visibilityLabel?: InputMaybe<Scalars['String']['input']>;
  visibilityLevel?: InputMaybe<Scalars['String']['input']>;
  visibilityRadius?: InputMaybe<Scalars['Int']['input']>;
  visibilitySetting?: InputMaybe<EnumGeoActivitiesVisibilitySetting>;
  visibilityStartDate?: InputMaybe<Scalars['Datetime']['input']>;
};
export type GeoActivityTag = {
  __typename?: 'GeoActivityTag';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `GeoActivity` that is related to this `GeoActivityTag`. */
  geoactivity?: Maybe<GeoActivity>;
  geoactivityId?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  socialDownVoteCount?: Maybe<Scalars['Int']['output']>;
  socialUpVoteCount?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Tag` that is related to this `GeoActivityTag`. */
  tag?: Maybe<Tag>;
  tagId?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `GeoActivityTag` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type GeoActivityTagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `geoactivityId` field. */
  geoactivityId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filters the list of GeoActivities to only those which match a custom filter. */
  sharingAndFilterCriteria?: InputMaybe<Scalars['String']['input']>;
  /** Filters the list of GeoActivityTags to only those which contain posts written by the specified user. */
  sharingEnforcedByViewerId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Filters the list of GeoActivityTags to only those which contain geocode within location bounds. */
  viewingLocation?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** A filter to be used against `GeoActivityTag` object types. All fields are combined with a logical ‘and.’ */
export type GeoActivityTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GeoActivityTagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `geoactivityId` field. */
  geoactivityId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GeoActivityTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GeoActivityTagFilter>>;
  /** Filter by the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `GeoActivityTag` */
export type GeoActivityTagInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  geoactivityId?: InputMaybe<Scalars['BigInt']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `GeoActivityTag` values. */
export type GeoActivityTagsConnection = {
  __typename?: 'GeoActivityTagsConnection';
  /** A list of edges which contains the `GeoActivityTag` and cursor to aid in pagination. */
  edges: Array<GeoActivityTagsEdge>;
  /** A list of `GeoActivityTag` objects. */
  nodes: Array<GeoActivityTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeoActivityTag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GeoActivityTag` edge in the connection. */
export type GeoActivityTagsEdge = {
  __typename?: 'GeoActivityTagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GeoActivityTag` at the end of the edge. */
  node: GeoActivityTag;
};

/** Methods to use when ordering `GeoActivityTag`. */
export enum GeoActivityTagsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GeoactivityIdAsc = 'GEOACTIVITY_ID_ASC',
  GeoactivityIdDesc = 'GEOACTIVITY_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  Natural = 'NATURAL',
  SocialDownVoteCountAsc = 'SOCIAL_DOWN_VOTE_COUNT_ASC',
  SocialDownVoteCountDesc = 'SOCIAL_DOWN_VOTE_COUNT_DESC',
  SocialUpVoteCountAsc = 'SOCIAL_UP_VOTE_COUNT_ASC',
  SocialUpVoteCountDesc = 'SOCIAL_UP_VOTE_COUNT_DESC',
  TagIdAsc = 'TAG_ID_ASC',
  TagIdDesc = 'TAG_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}
export type GeoCode = {
  __typename?: 'GeoCode';
  businessOfferLink?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  currencyCode?: Maybe<Scalars['String']['output']>;
  currencyName?: Maybe<Scalars['String']['output']>;
  datafeedProcessedErrors?: Maybe<Scalars['String']['output']>;
  datafeedProcessedTimestamp?: Maybe<Scalars['Datetime']['output']>;
  datafeedShares?: Maybe<Scalars['JSON']['output']>;
  demographicInfoPopulation?: Maybe<Scalars['Int']['output']>;
  distanceFromMe?: Maybe<Scalars['Float']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  emoji?: Maybe<Scalars['String']['output']>;
  emojiUnicode?: Maybe<Scalars['String']['output']>;
  externalSource?: Maybe<Scalars['String']['output']>;
  externalSourceId?: Maybe<Scalars['String']['output']>;
  foursquareId?: Maybe<Scalars['String']['output']>;
  foursquareIdData?: Maybe<Scalars['JSON']['output']>;
  foursquareIdDataErrors?: Maybe<Scalars['String']['output']>;
  foursquareIdDataTimestamp?: Maybe<Scalars['Datetime']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByGeocodeId: GeoActivitiesConnection;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByLinkToGeocodeId: GeoActivitiesConnection;
  /** Reads and enables pagination through a set of `GeoCodeTag`. */
  geoCodeTagsByGeocodeId: GeoCodeTagsConnection;
  geofeedSettingsCrawlerEnabled?: Maybe<Scalars['Boolean']['output']>;
  geofeedSettingsPipelineEnabled?: Maybe<Scalars['Boolean']['output']>;
  geonameId?: Maybe<Scalars['String']['output']>;
  googlePlaceId?: Maybe<Scalars['String']['output']>;
  googlePlaceIdData?: Maybe<Scalars['JSON']['output']>;
  googlePlaceIdDataErrors?: Maybe<Scalars['String']['output']>;
  googlePlaceIdDataTimestamp?: Maybe<Scalars['Datetime']['output']>;
  googlePlaceIdUrl?: Maybe<Scalars['String']['output']>;
  googlePlusCode?: Maybe<Scalars['String']['output']>;
  hereApiExtId?: Maybe<Scalars['String']['output']>;
  hereApiExtIdData?: Maybe<Scalars['JSON']['output']>;
  hereApiExtIdDataErrors?: Maybe<Scalars['String']['output']>;
  hereApiExtIdDataTimestamp?: Maybe<Scalars['Datetime']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isBusiness?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isExternalSourced?: Maybe<Scalars['Boolean']['output']>;
  isNormalized?: Maybe<Scalars['Boolean']['output']>;
  isPopular?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  isValidated?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  languages?: Maybe<Scalars['JSON']['output']>;
  latestReverseGeocodeErrors?: Maybe<Scalars['String']['output']>;
  latestReverseGeocodeRequest?: Maybe<Scalars['JSON']['output']>;
  latestReverseGeocodeTimestamp?: Maybe<Scalars['Datetime']['output']>;
  linkBookReservation?: Maybe<Scalars['String']['output']>;
  linkFacebook?: Maybe<Scalars['String']['output']>;
  linkInstagram?: Maybe<Scalars['String']['output']>;
  linkOrderOnline?: Maybe<Scalars['String']['output']>;
  linkScheduleAppointment?: Maybe<Scalars['String']['output']>;
  linkYelpBusiness?: Maybe<Scalars['String']['output']>;
  locales?: Maybe<Scalars['JSON']['output']>;
  locationContext?: Maybe<Scalars['JSON']['output']>;
  locationCoordinates?: Maybe<GeometryPoint>;
  locationGeojson?: Maybe<GeometryPoint>;
  locationHierarchy?: Maybe<Scalars['JSON']['output']>;
  locationInfoAddressLine1?: Maybe<Scalars['String']['output']>;
  locationInfoAddressLine2?: Maybe<Scalars['String']['output']>;
  locationInfoCity?: Maybe<Scalars['String']['output']>;
  locationInfoContinent?: Maybe<Scalars['String']['output']>;
  locationInfoContinentIso2?: Maybe<Scalars['String']['output']>;
  locationInfoCountry?: Maybe<Scalars['String']['output']>;
  locationInfoCountryIso2?: Maybe<Scalars['String']['output']>;
  locationInfoCountryIso3?: Maybe<Scalars['String']['output']>;
  locationInfoCountryIsoNumeric?: Maybe<Scalars['String']['output']>;
  locationInfoCounty?: Maybe<Scalars['String']['output']>;
  locationInfoCountyCode?: Maybe<Scalars['String']['output']>;
  locationInfoDistrict?: Maybe<Scalars['String']['output']>;
  locationInfoHouseNumber?: Maybe<Scalars['String']['output']>;
  locationInfoNeighborCountryIso?: Maybe<Scalars['String']['output']>;
  locationInfoNeighborhood?: Maybe<Scalars['String']['output']>;
  locationInfoPostalCode?: Maybe<Scalars['String']['output']>;
  locationInfoPostalCodeFormat?: Maybe<Scalars['String']['output']>;
  locationInfoPostalCodeRegex?: Maybe<Scalars['String']['output']>;
  locationInfoStateProvince?: Maybe<Scalars['String']['output']>;
  locationInfoStateProvinceIso?: Maybe<Scalars['String']['output']>;
  locationInfoStreet?: Maybe<Scalars['String']['output']>;
  locationType?: Maybe<Scalars['String']['output']>;
  mapBbox?: Maybe<Scalars['JSON']['output']>;
  metaJson?: Maybe<Scalars['JSON']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  nameContextPreferredNames?: Maybe<Scalars['JSON']['output']>;
  officalLanguage?: Maybe<Scalars['String']['output']>;
  officialName?: Maybe<Scalars['String']['output']>;
  osmId?: Maybe<Scalars['String']['output']>;
  osmIdData?: Maybe<Scalars['JSON']['output']>;
  osmIdDataErrors?: Maybe<Scalars['String']['output']>;
  osmIdDataTimestamp?: Maybe<Scalars['Datetime']['output']>;
  osmPlaceId?: Maybe<Scalars['Int']['output']>;
  osmType?: Maybe<Scalars['String']['output']>;
  ownerAccountId?: Maybe<Scalars['BigInt']['output']>;
  ownerAllowedGeotags?: Maybe<Scalars['Boolean']['output']>;
  ownerAllowedLikesAndComments?: Maybe<Scalars['Boolean']['output']>;
  ownerCaption?: Maybe<Scalars['String']['output']>;
  ownerDisplayOwnerProfileLink?: Maybe<Scalars['Boolean']['output']>;
  ownerId?: Maybe<Scalars['BigInt']['output']>;
  phoneCode?: Maybe<Scalars['String']['output']>;
  physicalInfoAreaSqKm?: Maybe<Scalars['Int']['output']>;
  physicalInfoElevation?: Maybe<Scalars['Int']['output']>;
  placeDescription?: Maybe<Scalars['String']['output']>;
  placeHoursOfOperation?: Maybe<Scalars['String']['output']>;
  placeInfoDescription?: Maybe<Scalars['String']['output']>;
  placeInfoHours?: Maybe<Scalars['String']['output']>;
  placeInfoHoursJson?: Maybe<Scalars['JSON']['output']>;
  placeInfoIconUrl?: Maybe<Scalars['String']['output']>;
  placeInfoImageGallery?: Maybe<Scalars['JSON']['output']>;
  placeInfoImageUrl?: Maybe<Scalars['String']['output']>;
  placeInfoOwner?: Maybe<Scalars['String']['output']>;
  placeInfoPhoneNumber?: Maybe<Scalars['String']['output']>;
  placeInfoWebsite?: Maybe<Scalars['String']['output']>;
  placePhone?: Maybe<Scalars['String']['output']>;
  placeWebsite?: Maybe<Scalars['String']['output']>;
  premiumEnabled?: Maybe<Scalars['Boolean']['output']>;
  premiumEnabledTimestamp?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationComplete?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationDate?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationErrors?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationResults?: Maybe<Scalars['JSON']['output']>;
  processExecuteContentModerationReviewerOverride?: Maybe<Scalars['Boolean']['output']>;
  processGeotaggerLastSync?: Maybe<Scalars['Datetime']['output']>;
  processGeotaggerLastSyncErrors?: Maybe<Scalars['String']['output']>;
  recommendations?: Maybe<Scalars['JSON']['output']>;
  saveLabel?: Maybe<Scalars['String']['output']>;
  socialCommentCount?: Maybe<Scalars['Int']['output']>;
  socialDownVoteCount?: Maybe<Scalars['Int']['output']>;
  socialGeocastCount?: Maybe<Scalars['Int']['output']>;
  socialGeodropCount?: Maybe<Scalars['Int']['output']>;
  socialLikeCount?: Maybe<Scalars['Int']['output']>;
  socialUpVoteCount?: Maybe<Scalars['Int']['output']>;
  spokenLanguage?: Maybe<Scalars['String']['output']>;
  systemId?: Maybe<Scalars['String']['output']>;
  timezone?: Maybe<Scalars['String']['output']>;
  timezones?: Maybe<Scalars['JSON']['output']>;
  type?: Maybe<EnumGeoCodesType>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
  /** Reads a single `User` that is related to this `GeoCode`. */
  verifiedOwner?: Maybe<User>;
  verifiedOwnerId?: Maybe<Scalars['BigInt']['output']>;
  wofId?: Maybe<Scalars['String']['output']>;
  yelpId?: Maybe<Scalars['String']['output']>;
  yelpIdData?: Maybe<Scalars['JSON']['output']>;
  yelpIdDataErrors?: Maybe<Scalars['String']['output']>;
  yelpIdDataTimestamp?: Maybe<Scalars['Datetime']['output']>;
};
export type GeoCodeGeoActivitiesByGeocodeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};
export type GeoCodeGeoActivitiesByLinkToGeocodeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};
export type GeoCodeGeoCodeTagsByGeocodeIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoCodeTagCondition>;
  filter?: InputMaybe<GeoCodeTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoCodeTagsOrderBy>>;
};
export type GeoCodeAccess = {
  __typename?: 'GeoCodeAccess';
  allowedAccess?: Maybe<Scalars['Boolean']['output']>;
  allowedAccessReason?: Maybe<Scalars['String']['output']>;
  level?: Maybe<Scalars['Int']['output']>;
};

/** A condition to be used against `GeoCode` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type GeoCodeCondition = {
  /** Checks for equality with the object’s `businessOfferLink` field. */
  businessOfferLink?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `currencyName` field. */
  currencyName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `datafeedProcessedErrors` field. */
  datafeedProcessedErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `datafeedProcessedTimestamp` field. */
  datafeedProcessedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `datafeedShares` field. */
  datafeedShares?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `demographicInfoPopulation` field. */
  demographicInfoPopulation?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `distanceFromMe` field. */
  distanceFromMe?: InputMaybe<Scalars['Float']['input']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emoji` field. */
  emoji?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `emojiUnicode` field. */
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `externalSource` field. */
  externalSource?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `externalSourceId` field. */
  externalSourceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `foursquareId` field. */
  foursquareId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `foursquareIdData` field. */
  foursquareIdData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `foursquareIdDataErrors` field. */
  foursquareIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `foursquareIdDataTimestamp` field. */
  foursquareIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `geofeedSettingsCrawlerEnabled` field. */
  geofeedSettingsCrawlerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `geofeedSettingsPipelineEnabled` field. */
  geofeedSettingsPipelineEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `geonameId` field. */
  geonameId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `googlePlaceId` field. */
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `googlePlaceIdData` field. */
  googlePlaceIdData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `googlePlaceIdDataErrors` field. */
  googlePlaceIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `googlePlaceIdDataTimestamp` field. */
  googlePlaceIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `googlePlaceIdUrl` field. */
  googlePlaceIdUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `googlePlusCode` field. */
  googlePlusCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hereApiExtId` field. */
  hereApiExtId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hereApiExtIdData` field. */
  hereApiExtIdData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `hereApiExtIdDataErrors` field. */
  hereApiExtIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `hereApiExtIdDataTimestamp` field. */
  hereApiExtIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isActive` field. */
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isBusiness` field. */
  isBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isExternalSourced` field. */
  isExternalSourced?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isNormalized` field. */
  isNormalized?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPopular` field. */
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isValidated` field. */
  isValidated?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `languages` field. */
  languages?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `latestReverseGeocodeErrors` field. */
  latestReverseGeocodeErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `latestReverseGeocodeRequest` field. */
  latestReverseGeocodeRequest?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `latestReverseGeocodeTimestamp` field. */
  latestReverseGeocodeTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `linkBookReservation` field. */
  linkBookReservation?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkFacebook` field. */
  linkFacebook?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkInstagram` field. */
  linkInstagram?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkOrderOnline` field. */
  linkOrderOnline?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkScheduleAppointment` field. */
  linkScheduleAppointment?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `linkYelpBusiness` field. */
  linkYelpBusiness?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locales` field. */
  locales?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `locationContext` field. */
  locationContext?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `locationCoordinates` field. */
  locationCoordinates?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Checks for equality with the object’s `locationGeojson` field. */
  locationGeojson?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Checks for equality with the object’s `locationHierarchy` field. */
  locationHierarchy?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `locationInfoAddressLine1` field. */
  locationInfoAddressLine1?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoAddressLine2` field. */
  locationInfoAddressLine2?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCity` field. */
  locationInfoCity?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoContinent` field. */
  locationInfoContinent?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoContinentIso2` field. */
  locationInfoContinentIso2?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCountry` field. */
  locationInfoCountry?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCountryIso2` field. */
  locationInfoCountryIso2?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCountryIso3` field. */
  locationInfoCountryIso3?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCountryIsoNumeric` field. */
  locationInfoCountryIsoNumeric?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCounty` field. */
  locationInfoCounty?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoCountyCode` field. */
  locationInfoCountyCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoDistrict` field. */
  locationInfoDistrict?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoHouseNumber` field. */
  locationInfoHouseNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoNeighborCountryIso` field. */
  locationInfoNeighborCountryIso?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoNeighborhood` field. */
  locationInfoNeighborhood?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoPostalCode` field. */
  locationInfoPostalCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoPostalCodeFormat` field. */
  locationInfoPostalCodeFormat?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoPostalCodeRegex` field. */
  locationInfoPostalCodeRegex?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoStateProvince` field. */
  locationInfoStateProvince?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoStateProvinceIso` field. */
  locationInfoStateProvinceIso?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationInfoStreet` field. */
  locationInfoStreet?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `locationType` field. */
  locationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `mapBbox` field. */
  mapBbox?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `metaJson` field. */
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `nameContextPreferredNames` field. */
  nameContextPreferredNames?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `officalLanguage` field. */
  officalLanguage?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `officialName` field. */
  officialName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `osmId` field. */
  osmId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `osmIdData` field. */
  osmIdData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `osmIdDataErrors` field. */
  osmIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `osmIdDataTimestamp` field. */
  osmIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `osmPlaceId` field. */
  osmPlaceId?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `osmType` field. */
  osmType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ownerAccountId` field. */
  ownerAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `ownerAllowedGeotags` field. */
  ownerAllowedGeotags?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `ownerAllowedLikesAndComments` field. */
  ownerAllowedLikesAndComments?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `ownerCaption` field. */
  ownerCaption?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `ownerDisplayOwnerProfileLink` field. */
  ownerDisplayOwnerProfileLink?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `phoneCode` field. */
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `physicalInfoAreaSqKm` field. */
  physicalInfoAreaSqKm?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `physicalInfoElevation` field. */
  physicalInfoElevation?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `placeDescription` field. */
  placeDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeHoursOfOperation` field. */
  placeHoursOfOperation?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoDescription` field. */
  placeInfoDescription?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoHours` field. */
  placeInfoHours?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoHoursJson` field. */
  placeInfoHoursJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `placeInfoIconUrl` field. */
  placeInfoIconUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoImageGallery` field. */
  placeInfoImageGallery?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `placeInfoImageUrl` field. */
  placeInfoImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoOwner` field. */
  placeInfoOwner?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoPhoneNumber` field. */
  placeInfoPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeInfoWebsite` field. */
  placeInfoWebsite?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placePhone` field. */
  placePhone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `placeWebsite` field. */
  placeWebsite?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `premiumEnabled` field. */
  premiumEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `premiumEnabledTimestamp` field. */
  premiumEnabledTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationResults` field. */
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processGeotaggerLastSync` field. */
  processGeotaggerLastSync?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processGeotaggerLastSyncErrors` field. */
  processGeotaggerLastSyncErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `recommendations` field. */
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `saveLabel` field. */
  saveLabel?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialCommentCount` field. */
  socialCommentCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialGeocastCount` field. */
  socialGeocastCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialGeodropCount` field. */
  socialGeodropCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialLikeCount` field. */
  socialLikeCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `spokenLanguage` field. */
  spokenLanguage?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `systemId` field. */
  systemId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezone` field. */
  timezone?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `timezones` field. */
  timezones?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `type` field. */
  type?: InputMaybe<EnumGeoCodesType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  /** Checks for equality with the object’s `verifiedOwnerId` field. */
  verifiedOwnerId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Filters the list of GeoActivityTags to only those which contain geocode within location bounds. */
  viewingLocation?: InputMaybe<Array<Scalars['Float']['input']>>;
  /** Checks for equality with the object’s `wofId` field. */
  wofId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `yelpId` field. */
  yelpId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `yelpIdData` field. */
  yelpIdData?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `yelpIdDataErrors` field. */
  yelpIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `yelpIdDataTimestamp` field. */
  yelpIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `GeoCode` object types. All fields are combined with a logical ‘and.’ */
export type GeoCodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GeoCodeFilter>>;
  /** Filter by the object’s `businessOfferLink` field. */
  businessOfferLink?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `currencyCode` field. */
  currencyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `currencyName` field. */
  currencyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datafeedProcessedErrors` field. */
  datafeedProcessedErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datafeedProcessedTimestamp` field. */
  datafeedProcessedTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `demographicInfoPopulation` field. */
  demographicInfoPopulation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `distanceFromMe` field. */
  distanceFromMe?: InputMaybe<FloatFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emoji` field. */
  emoji?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emojiUnicode` field. */
  emojiUnicode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalSource` field. */
  externalSource?: InputMaybe<StringFilter>;
  /** Filter by the object’s `externalSourceId` field. */
  externalSourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `foursquareId` field. */
  foursquareId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `foursquareIdData` field. */
  foursquareIdData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `foursquareIdDataErrors` field. */
  foursquareIdDataErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `foursquareIdDataTimestamp` field. */
  foursquareIdDataTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `geofeedSettingsCrawlerEnabled` field. */
  geofeedSettingsCrawlerEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `geofeedSettingsPipelineEnabled` field. */
  geofeedSettingsPipelineEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `geonameId` field. */
  geonameId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googlePlaceId` field. */
  googlePlaceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googlePlaceIdData` field. */
  googlePlaceIdData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `googlePlaceIdDataErrors` field. */
  googlePlaceIdDataErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googlePlaceIdDataTimestamp` field. */
  googlePlaceIdDataTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `googlePlaceIdUrl` field. */
  googlePlaceIdUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `googlePlusCode` field. */
  googlePlusCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hereApiExtId` field. */
  hereApiExtId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hereApiExtIdData` field. */
  hereApiExtIdData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `hereApiExtIdDataErrors` field. */
  hereApiExtIdDataErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `hereApiExtIdDataTimestamp` field. */
  hereApiExtIdDataTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isActive` field. */
  isActive?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isBusiness` field. */
  isBusiness?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isExternalSourced` field. */
  isExternalSourced?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isNormalized` field. */
  isNormalized?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPopular` field. */
  isPopular?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isValidated` field. */
  isValidated?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `latestReverseGeocodeErrors` field. */
  latestReverseGeocodeErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `latestReverseGeocodeTimestamp` field. */
  latestReverseGeocodeTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `linkBookReservation` field. */
  linkBookReservation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkFacebook` field. */
  linkFacebook?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkInstagram` field. */
  linkInstagram?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkOrderOnline` field. */
  linkOrderOnline?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkScheduleAppointment` field. */
  linkScheduleAppointment?: InputMaybe<StringFilter>;
  /** Filter by the object’s `linkYelpBusiness` field. */
  linkYelpBusiness?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationCoordinates` field. */
  locationCoordinates?: InputMaybe<GeometryPointFilter>;
  /** Filter by the object’s `locationGeojson` field. */
  locationGeojson?: InputMaybe<GeometryPointFilter>;
  /** Filter by the object’s `locationInfoAddressLine1` field. */
  locationInfoAddressLine1?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoAddressLine2` field. */
  locationInfoAddressLine2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCity` field. */
  locationInfoCity?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoContinent` field. */
  locationInfoContinent?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoContinentIso2` field. */
  locationInfoContinentIso2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCountry` field. */
  locationInfoCountry?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCountryIso2` field. */
  locationInfoCountryIso2?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCountryIso3` field. */
  locationInfoCountryIso3?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCountryIsoNumeric` field. */
  locationInfoCountryIsoNumeric?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCounty` field. */
  locationInfoCounty?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoCountyCode` field. */
  locationInfoCountyCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoDistrict` field. */
  locationInfoDistrict?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoHouseNumber` field. */
  locationInfoHouseNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoNeighborCountryIso` field. */
  locationInfoNeighborCountryIso?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoNeighborhood` field. */
  locationInfoNeighborhood?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoPostalCode` field. */
  locationInfoPostalCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoPostalCodeFormat` field. */
  locationInfoPostalCodeFormat?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoPostalCodeRegex` field. */
  locationInfoPostalCodeRegex?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoStateProvince` field. */
  locationInfoStateProvince?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoStateProvinceIso` field. */
  locationInfoStateProvinceIso?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationInfoStreet` field. */
  locationInfoStreet?: InputMaybe<StringFilter>;
  /** Filter by the object’s `locationType` field. */
  locationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GeoCodeFilter>;
  /** Filter by the object’s `officalLanguage` field. */
  officalLanguage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `officialName` field. */
  officialName?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GeoCodeFilter>>;
  /** Filter by the object’s `osmId` field. */
  osmId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `osmIdData` field. */
  osmIdData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `osmIdDataErrors` field. */
  osmIdDataErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `osmIdDataTimestamp` field. */
  osmIdDataTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `osmPlaceId` field. */
  osmPlaceId?: InputMaybe<IntFilter>;
  /** Filter by the object’s `osmType` field. */
  osmType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `ownerAccountId` field. */
  ownerAccountId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `ownerAllowedGeotags` field. */
  ownerAllowedGeotags?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `ownerAllowedLikesAndComments` field. */
  ownerAllowedLikesAndComments?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `ownerCaption` field. */
  ownerCaption?: InputMaybe<StringFilter>;
  /** Filter by the object’s `ownerDisplayOwnerProfileLink` field. */
  ownerDisplayOwnerProfileLink?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `phoneCode` field. */
  phoneCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `physicalInfoAreaSqKm` field. */
  physicalInfoAreaSqKm?: InputMaybe<IntFilter>;
  /** Filter by the object’s `physicalInfoElevation` field. */
  physicalInfoElevation?: InputMaybe<IntFilter>;
  /** Filter by the object’s `placeDescription` field. */
  placeDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeHoursOfOperation` field. */
  placeHoursOfOperation?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoDescription` field. */
  placeInfoDescription?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoHours` field. */
  placeInfoHours?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoIconUrl` field. */
  placeInfoIconUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoImageUrl` field. */
  placeInfoImageUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoOwner` field. */
  placeInfoOwner?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoPhoneNumber` field. */
  placeInfoPhoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeInfoWebsite` field. */
  placeInfoWebsite?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placePhone` field. */
  placePhone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `placeWebsite` field. */
  placeWebsite?: InputMaybe<StringFilter>;
  /** Filter by the object’s `premiumEnabled` field. */
  premiumEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `premiumEnabledTimestamp` field. */
  premiumEnabledTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processGeotaggerLastSync` field. */
  processGeotaggerLastSync?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processGeotaggerLastSyncErrors` field. */
  processGeotaggerLastSyncErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `saveLabel` field. */
  saveLabel?: InputMaybe<StringFilter>;
  /** Filter by the object’s `socialCommentCount` field. */
  socialCommentCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialGeocastCount` field. */
  socialGeocastCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialGeodropCount` field. */
  socialGeodropCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialLikeCount` field. */
  socialLikeCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `spokenLanguage` field. */
  spokenLanguage?: InputMaybe<StringFilter>;
  /** Filter by the object’s `systemId` field. */
  systemId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `timezone` field. */
  timezone?: InputMaybe<StringFilter>;
  /** Filter by the object’s `type` field. */
  type?: InputMaybe<EnumGeoCodesTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `verifiedOwnerId` field. */
  verifiedOwnerId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `wofId` field. */
  wofId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `yelpId` field. */
  yelpId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `yelpIdData` field. */
  yelpIdData?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `yelpIdDataErrors` field. */
  yelpIdDataErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `yelpIdDataTimestamp` field. */
  yelpIdDataTimestamp?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `GeoCode` */
export type GeoCodeInput = {
  businessOfferLink?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  currencyCode?: InputMaybe<Scalars['String']['input']>;
  currencyName?: InputMaybe<Scalars['String']['input']>;
  datafeedProcessedErrors?: InputMaybe<Scalars['String']['input']>;
  datafeedProcessedTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  datafeedShares?: InputMaybe<Scalars['JSON']['input']>;
  demographicInfoPopulation?: InputMaybe<Scalars['Int']['input']>;
  distanceFromMe?: InputMaybe<Scalars['Float']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  emoji?: InputMaybe<Scalars['String']['input']>;
  emojiUnicode?: InputMaybe<Scalars['String']['input']>;
  externalSource?: InputMaybe<Scalars['String']['input']>;
  externalSourceId?: InputMaybe<Scalars['String']['input']>;
  foursquareId?: InputMaybe<Scalars['String']['input']>;
  foursquareIdData?: InputMaybe<Scalars['JSON']['input']>;
  foursquareIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  foursquareIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  geofeedSettingsCrawlerEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  geofeedSettingsPipelineEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  geonameId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceId?: InputMaybe<Scalars['String']['input']>;
  googlePlaceIdData?: InputMaybe<Scalars['JSON']['input']>;
  googlePlaceIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  googlePlaceIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  googlePlaceIdUrl?: InputMaybe<Scalars['String']['input']>;
  googlePlusCode?: InputMaybe<Scalars['String']['input']>;
  hereApiExtId?: InputMaybe<Scalars['String']['input']>;
  hereApiExtIdData?: InputMaybe<Scalars['JSON']['input']>;
  hereApiExtIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  hereApiExtIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isActive?: InputMaybe<Scalars['Boolean']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isBusiness?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isExternalSourced?: InputMaybe<Scalars['Boolean']['input']>;
  isNormalized?: InputMaybe<Scalars['Boolean']['input']>;
  isPopular?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isValidated?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  languages?: InputMaybe<Scalars['JSON']['input']>;
  latestReverseGeocodeErrors?: InputMaybe<Scalars['String']['input']>;
  latestReverseGeocodeRequest?: InputMaybe<Scalars['JSON']['input']>;
  latestReverseGeocodeTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  linkBookReservation?: InputMaybe<Scalars['String']['input']>;
  linkFacebook?: InputMaybe<Scalars['String']['input']>;
  linkInstagram?: InputMaybe<Scalars['String']['input']>;
  linkOrderOnline?: InputMaybe<Scalars['String']['input']>;
  linkScheduleAppointment?: InputMaybe<Scalars['String']['input']>;
  linkYelpBusiness?: InputMaybe<Scalars['String']['input']>;
  locales?: InputMaybe<Scalars['JSON']['input']>;
  locationContext?: InputMaybe<Scalars['JSON']['input']>;
  locationCoordinates?: InputMaybe<Scalars['GeoJSON']['input']>;
  locationGeojson?: InputMaybe<Scalars['GeoJSON']['input']>;
  locationHierarchy?: InputMaybe<Scalars['JSON']['input']>;
  locationInfoAddressLine1?: InputMaybe<Scalars['String']['input']>;
  locationInfoAddressLine2?: InputMaybe<Scalars['String']['input']>;
  locationInfoCity?: InputMaybe<Scalars['String']['input']>;
  locationInfoContinent?: InputMaybe<Scalars['String']['input']>;
  locationInfoContinentIso2?: InputMaybe<Scalars['String']['input']>;
  locationInfoCountry?: InputMaybe<Scalars['String']['input']>;
  locationInfoCountryIso2?: InputMaybe<Scalars['String']['input']>;
  locationInfoCountryIso3?: InputMaybe<Scalars['String']['input']>;
  locationInfoCountryIsoNumeric?: InputMaybe<Scalars['String']['input']>;
  locationInfoCounty?: InputMaybe<Scalars['String']['input']>;
  locationInfoCountyCode?: InputMaybe<Scalars['String']['input']>;
  locationInfoDistrict?: InputMaybe<Scalars['String']['input']>;
  locationInfoHouseNumber?: InputMaybe<Scalars['String']['input']>;
  locationInfoNeighborCountryIso?: InputMaybe<Scalars['String']['input']>;
  locationInfoNeighborhood?: InputMaybe<Scalars['String']['input']>;
  locationInfoPostalCode?: InputMaybe<Scalars['String']['input']>;
  locationInfoPostalCodeFormat?: InputMaybe<Scalars['String']['input']>;
  locationInfoPostalCodeRegex?: InputMaybe<Scalars['String']['input']>;
  locationInfoStateProvince?: InputMaybe<Scalars['String']['input']>;
  locationInfoStateProvinceIso?: InputMaybe<Scalars['String']['input']>;
  locationInfoStreet?: InputMaybe<Scalars['String']['input']>;
  locationType?: InputMaybe<Scalars['String']['input']>;
  mapBbox?: InputMaybe<Scalars['JSON']['input']>;
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  nameContextPreferredNames?: InputMaybe<Scalars['JSON']['input']>;
  officalLanguage?: InputMaybe<Scalars['String']['input']>;
  officialName?: InputMaybe<Scalars['String']['input']>;
  osmId?: InputMaybe<Scalars['String']['input']>;
  osmIdData?: InputMaybe<Scalars['JSON']['input']>;
  osmIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  osmIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  osmPlaceId?: InputMaybe<Scalars['Int']['input']>;
  osmType?: InputMaybe<Scalars['String']['input']>;
  ownerAccountId?: InputMaybe<Scalars['BigInt']['input']>;
  ownerAllowedGeotags?: InputMaybe<Scalars['Boolean']['input']>;
  ownerAllowedLikesAndComments?: InputMaybe<Scalars['Boolean']['input']>;
  ownerCaption?: InputMaybe<Scalars['String']['input']>;
  ownerDisplayOwnerProfileLink?: InputMaybe<Scalars['Boolean']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  phoneCode?: InputMaybe<Scalars['String']['input']>;
  physicalInfoAreaSqKm?: InputMaybe<Scalars['Int']['input']>;
  physicalInfoElevation?: InputMaybe<Scalars['Int']['input']>;
  placeDescription?: InputMaybe<Scalars['String']['input']>;
  placeHoursOfOperation?: InputMaybe<Scalars['String']['input']>;
  placeInfoDescription?: InputMaybe<Scalars['String']['input']>;
  placeInfoHours?: InputMaybe<Scalars['String']['input']>;
  placeInfoHoursJson?: InputMaybe<Scalars['JSON']['input']>;
  placeInfoIconUrl?: InputMaybe<Scalars['String']['input']>;
  placeInfoImageGallery?: InputMaybe<Scalars['JSON']['input']>;
  placeInfoImageUrl?: InputMaybe<Scalars['String']['input']>;
  placeInfoOwner?: InputMaybe<Scalars['String']['input']>;
  placeInfoPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  placeInfoWebsite?: InputMaybe<Scalars['String']['input']>;
  placePhone?: InputMaybe<Scalars['String']['input']>;
  placeWebsite?: InputMaybe<Scalars['String']['input']>;
  premiumEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  premiumEnabledTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  processGeotaggerLastSync?: InputMaybe<Scalars['Datetime']['input']>;
  processGeotaggerLastSyncErrors?: InputMaybe<Scalars['String']['input']>;
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  saveLabel?: InputMaybe<Scalars['String']['input']>;
  socialCommentCount?: InputMaybe<Scalars['Int']['input']>;
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  socialGeocastCount?: InputMaybe<Scalars['Int']['input']>;
  socialGeodropCount?: InputMaybe<Scalars['Int']['input']>;
  socialLikeCount?: InputMaybe<Scalars['Int']['input']>;
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  spokenLanguage?: InputMaybe<Scalars['String']['input']>;
  systemId?: InputMaybe<Scalars['String']['input']>;
  timezone?: InputMaybe<Scalars['String']['input']>;
  timezones?: InputMaybe<Scalars['JSON']['input']>;
  type?: InputMaybe<EnumGeoCodesType>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
  verifiedOwnerId?: InputMaybe<Scalars['BigInt']['input']>;
  wofId?: InputMaybe<Scalars['String']['input']>;
  yelpId?: InputMaybe<Scalars['String']['input']>;
  yelpIdData?: InputMaybe<Scalars['JSON']['input']>;
  yelpIdDataErrors?: InputMaybe<Scalars['String']['input']>;
  yelpIdDataTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
};
export type GeoCodeTag = {
  __typename?: 'GeoCodeTag';
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  /** Reads a single `GeoCode` that is related to this `GeoCodeTag`. */
  geocode?: Maybe<GeoCode>;
  geocodeId?: Maybe<Scalars['BigInt']['output']>;
  groupId?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  socialDownVoteCount?: Maybe<Scalars['Int']['output']>;
  socialUpVoteCount?: Maybe<Scalars['Int']['output']>;
  /** Reads a single `Tag` that is related to this `GeoCodeTag`. */
  tag?: Maybe<Tag>;
  tagId?: Maybe<Scalars['BigInt']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};

/**
 * A condition to be used against `GeoCodeTag` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type GeoCodeTagCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `groupId` field. */
  groupId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `tagId` field. */
  tagId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Filters the list of GeoActivityTags to only those which contain geocode within location bounds. */
  viewingLocation?: InputMaybe<Array<Scalars['Float']['input']>>;
};

/** A filter to be used against `GeoCodeTag` object types. All fields are combined with a logical ‘and.’ */
export type GeoCodeTagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<GeoCodeTagFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `groupId` field. */
  groupId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<GeoCodeTagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<GeoCodeTagFilter>>;
  /** Filter by the object’s `socialDownVoteCount` field. */
  socialDownVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `socialUpVoteCount` field. */
  socialUpVoteCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `tagId` field. */
  tagId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `GeoCodeTag` */
export type GeoCodeTagInput = {
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  groupId?: InputMaybe<Scalars['BigInt']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  socialDownVoteCount?: InputMaybe<Scalars['Int']['input']>;
  socialUpVoteCount?: InputMaybe<Scalars['Int']['input']>;
  tagId?: InputMaybe<Scalars['BigInt']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `GeoCodeTag` values. */
export type GeoCodeTagsConnection = {
  __typename?: 'GeoCodeTagsConnection';
  /** A list of edges which contains the `GeoCodeTag` and cursor to aid in pagination. */
  edges: Array<GeoCodeTagsEdge>;
  /** A list of `GeoCodeTag` objects. */
  nodes: Array<GeoCodeTag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeoCodeTag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GeoCodeTag` edge in the connection. */
export type GeoCodeTagsEdge = {
  __typename?: 'GeoCodeTagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GeoCodeTag` at the end of the edge. */
  node: GeoCodeTag;
};

/** Methods to use when ordering `GeoCodeTag`. */
export enum GeoCodeTagsOrderBy {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  GeocodeIdAsc = 'GEOCODE_ID_ASC',
  GeocodeIdDesc = 'GEOCODE_ID_DESC',
  GroupIdAsc = 'GROUP_ID_ASC',
  GroupIdDesc = 'GROUP_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  Natural = 'NATURAL',
  SocialDownVoteCountAsc = 'SOCIAL_DOWN_VOTE_COUNT_ASC',
  SocialDownVoteCountDesc = 'SOCIAL_DOWN_VOTE_COUNT_DESC',
  SocialUpVoteCountAsc = 'SOCIAL_UP_VOTE_COUNT_ASC',
  SocialUpVoteCountDesc = 'SOCIAL_UP_VOTE_COUNT_DESC',
  TagIdAsc = 'TAG_ID_ASC',
  TagIdDesc = 'TAG_ID_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A connection to a list of `GeoCode` values. */
export type GeoCodesConnection = {
  __typename?: 'GeoCodesConnection';
  /** A list of edges which contains the `GeoCode` and cursor to aid in pagination. */
  edges: Array<GeoCodesEdge>;
  /** A list of `GeoCode` objects. */
  nodes: Array<GeoCode>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `GeoCode` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `GeoCode` edge in the connection. */
export type GeoCodesEdge = {
  __typename?: 'GeoCodesEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `GeoCode` at the end of the edge. */
  node: GeoCode;
};

/** Methods to use when ordering `GeoCode`. */
export enum GeoCodesOrderBy {
  BusinessOfferLinkAsc = 'BUSINESS_OFFER_LINK_ASC',
  BusinessOfferLinkDesc = 'BUSINESS_OFFER_LINK_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  CurrencyCodeAsc = 'CURRENCY_CODE_ASC',
  CurrencyCodeDesc = 'CURRENCY_CODE_DESC',
  CurrencyNameAsc = 'CURRENCY_NAME_ASC',
  CurrencyNameDesc = 'CURRENCY_NAME_DESC',
  DatafeedProcessedErrorsAsc = 'DATAFEED_PROCESSED_ERRORS_ASC',
  DatafeedProcessedErrorsDesc = 'DATAFEED_PROCESSED_ERRORS_DESC',
  DatafeedProcessedTimestampAsc = 'DATAFEED_PROCESSED_TIMESTAMP_ASC',
  DatafeedProcessedTimestampDesc = 'DATAFEED_PROCESSED_TIMESTAMP_DESC',
  DatafeedSharesAsc = 'DATAFEED_SHARES_ASC',
  DatafeedSharesDesc = 'DATAFEED_SHARES_DESC',
  DemographicInfoPopulationAsc = 'DEMOGRAPHIC_INFO_POPULATION_ASC',
  DemographicInfoPopulationDesc = 'DEMOGRAPHIC_INFO_POPULATION_DESC',
  DistanceFromMeAsc = 'DISTANCE_FROM_ME_ASC',
  DistanceFromMeDesc = 'DISTANCE_FROM_ME_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  EmojiAsc = 'EMOJI_ASC',
  EmojiDesc = 'EMOJI_DESC',
  EmojiUnicodeAsc = 'EMOJI_UNICODE_ASC',
  EmojiUnicodeDesc = 'EMOJI_UNICODE_DESC',
  ExternalSourceAsc = 'EXTERNAL_SOURCE_ASC',
  ExternalSourceDesc = 'EXTERNAL_SOURCE_DESC',
  ExternalSourceIdAsc = 'EXTERNAL_SOURCE_ID_ASC',
  ExternalSourceIdDesc = 'EXTERNAL_SOURCE_ID_DESC',
  FoursquareIdAsc = 'FOURSQUARE_ID_ASC',
  FoursquareIdDataAsc = 'FOURSQUARE_ID_DATA_ASC',
  FoursquareIdDataDesc = 'FOURSQUARE_ID_DATA_DESC',
  FoursquareIdDataErrorsAsc = 'FOURSQUARE_ID_DATA_ERRORS_ASC',
  FoursquareIdDataErrorsDesc = 'FOURSQUARE_ID_DATA_ERRORS_DESC',
  FoursquareIdDataTimestampAsc = 'FOURSQUARE_ID_DATA_TIMESTAMP_ASC',
  FoursquareIdDataTimestampDesc = 'FOURSQUARE_ID_DATA_TIMESTAMP_DESC',
  FoursquareIdDesc = 'FOURSQUARE_ID_DESC',
  GeofeedSettingsCrawlerEnabledAsc = 'GEOFEED_SETTINGS_CRAWLER_ENABLED_ASC',
  GeofeedSettingsCrawlerEnabledDesc = 'GEOFEED_SETTINGS_CRAWLER_ENABLED_DESC',
  GeofeedSettingsPipelineEnabledAsc = 'GEOFEED_SETTINGS_PIPELINE_ENABLED_ASC',
  GeofeedSettingsPipelineEnabledDesc = 'GEOFEED_SETTINGS_PIPELINE_ENABLED_DESC',
  GeonameIdAsc = 'GEONAME_ID_ASC',
  GeonameIdDesc = 'GEONAME_ID_DESC',
  GooglePlaceIdAsc = 'GOOGLE_PLACE_ID_ASC',
  GooglePlaceIdDataAsc = 'GOOGLE_PLACE_ID_DATA_ASC',
  GooglePlaceIdDataDesc = 'GOOGLE_PLACE_ID_DATA_DESC',
  GooglePlaceIdDataErrorsAsc = 'GOOGLE_PLACE_ID_DATA_ERRORS_ASC',
  GooglePlaceIdDataErrorsDesc = 'GOOGLE_PLACE_ID_DATA_ERRORS_DESC',
  GooglePlaceIdDataTimestampAsc = 'GOOGLE_PLACE_ID_DATA_TIMESTAMP_ASC',
  GooglePlaceIdDataTimestampDesc = 'GOOGLE_PLACE_ID_DATA_TIMESTAMP_DESC',
  GooglePlaceIdDesc = 'GOOGLE_PLACE_ID_DESC',
  GooglePlaceIdUrlAsc = 'GOOGLE_PLACE_ID_URL_ASC',
  GooglePlaceIdUrlDesc = 'GOOGLE_PLACE_ID_URL_DESC',
  GooglePlusCodeAsc = 'GOOGLE_PLUS_CODE_ASC',
  GooglePlusCodeDesc = 'GOOGLE_PLUS_CODE_DESC',
  HereApiExtIdAsc = 'HERE_API_EXT_ID_ASC',
  HereApiExtIdDataAsc = 'HERE_API_EXT_ID_DATA_ASC',
  HereApiExtIdDataDesc = 'HERE_API_EXT_ID_DATA_DESC',
  HereApiExtIdDataErrorsAsc = 'HERE_API_EXT_ID_DATA_ERRORS_ASC',
  HereApiExtIdDataErrorsDesc = 'HERE_API_EXT_ID_DATA_ERRORS_DESC',
  HereApiExtIdDataTimestampAsc = 'HERE_API_EXT_ID_DATA_TIMESTAMP_ASC',
  HereApiExtIdDataTimestampDesc = 'HERE_API_EXT_ID_DATA_TIMESTAMP_DESC',
  HereApiExtIdDesc = 'HERE_API_EXT_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsActiveAsc = 'IS_ACTIVE_ASC',
  IsActiveDesc = 'IS_ACTIVE_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsBusinessAsc = 'IS_BUSINESS_ASC',
  IsBusinessDesc = 'IS_BUSINESS_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsExternalSourcedAsc = 'IS_EXTERNAL_SOURCED_ASC',
  IsExternalSourcedDesc = 'IS_EXTERNAL_SOURCED_DESC',
  IsNormalizedAsc = 'IS_NORMALIZED_ASC',
  IsNormalizedDesc = 'IS_NORMALIZED_DESC',
  IsPopularAsc = 'IS_POPULAR_ASC',
  IsPopularDesc = 'IS_POPULAR_DESC',
  IsPrivateAsc = 'IS_PRIVATE_ASC',
  IsPrivateDesc = 'IS_PRIVATE_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  IsValidatedAsc = 'IS_VALIDATED_ASC',
  IsValidatedDesc = 'IS_VALIDATED_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  LanguagesAsc = 'LANGUAGES_ASC',
  LanguagesDesc = 'LANGUAGES_DESC',
  LatestReverseGeocodeErrorsAsc = 'LATEST_REVERSE_GEOCODE_ERRORS_ASC',
  LatestReverseGeocodeErrorsDesc = 'LATEST_REVERSE_GEOCODE_ERRORS_DESC',
  LatestReverseGeocodeRequestAsc = 'LATEST_REVERSE_GEOCODE_REQUEST_ASC',
  LatestReverseGeocodeRequestDesc = 'LATEST_REVERSE_GEOCODE_REQUEST_DESC',
  LatestReverseGeocodeTimestampAsc = 'LATEST_REVERSE_GEOCODE_TIMESTAMP_ASC',
  LatestReverseGeocodeTimestampDesc = 'LATEST_REVERSE_GEOCODE_TIMESTAMP_DESC',
  LinkBookReservationAsc = 'LINK_BOOK_RESERVATION_ASC',
  LinkBookReservationDesc = 'LINK_BOOK_RESERVATION_DESC',
  LinkFacebookAsc = 'LINK_FACEBOOK_ASC',
  LinkFacebookDesc = 'LINK_FACEBOOK_DESC',
  LinkInstagramAsc = 'LINK_INSTAGRAM_ASC',
  LinkInstagramDesc = 'LINK_INSTAGRAM_DESC',
  LinkOrderOnlineAsc = 'LINK_ORDER_ONLINE_ASC',
  LinkOrderOnlineDesc = 'LINK_ORDER_ONLINE_DESC',
  LinkScheduleAppointmentAsc = 'LINK_SCHEDULE_APPOINTMENT_ASC',
  LinkScheduleAppointmentDesc = 'LINK_SCHEDULE_APPOINTMENT_DESC',
  LinkYelpBusinessAsc = 'LINK_YELP_BUSINESS_ASC',
  LinkYelpBusinessDesc = 'LINK_YELP_BUSINESS_DESC',
  LocalesAsc = 'LOCALES_ASC',
  LocalesDesc = 'LOCALES_DESC',
  LocationContextAsc = 'LOCATION_CONTEXT_ASC',
  LocationContextDesc = 'LOCATION_CONTEXT_DESC',
  LocationCoordinatesAsc = 'LOCATION_COORDINATES_ASC',
  LocationCoordinatesDesc = 'LOCATION_COORDINATES_DESC',
  LocationGeojsonAsc = 'LOCATION_GEOJSON_ASC',
  LocationGeojsonDesc = 'LOCATION_GEOJSON_DESC',
  LocationHierarchyAsc = 'LOCATION_HIERARCHY_ASC',
  LocationHierarchyDesc = 'LOCATION_HIERARCHY_DESC',
  LocationInfoAddressLine1Asc = 'LOCATION_INFO_ADDRESS_LINE1_ASC',
  LocationInfoAddressLine1Desc = 'LOCATION_INFO_ADDRESS_LINE1_DESC',
  LocationInfoAddressLine2Asc = 'LOCATION_INFO_ADDRESS_LINE2_ASC',
  LocationInfoAddressLine2Desc = 'LOCATION_INFO_ADDRESS_LINE2_DESC',
  LocationInfoCityAsc = 'LOCATION_INFO_CITY_ASC',
  LocationInfoCityDesc = 'LOCATION_INFO_CITY_DESC',
  LocationInfoContinentAsc = 'LOCATION_INFO_CONTINENT_ASC',
  LocationInfoContinentDesc = 'LOCATION_INFO_CONTINENT_DESC',
  LocationInfoContinentIso2Asc = 'LOCATION_INFO_CONTINENT_ISO2_ASC',
  LocationInfoContinentIso2Desc = 'LOCATION_INFO_CONTINENT_ISO2_DESC',
  LocationInfoCountryAsc = 'LOCATION_INFO_COUNTRY_ASC',
  LocationInfoCountryDesc = 'LOCATION_INFO_COUNTRY_DESC',
  LocationInfoCountryIso2Asc = 'LOCATION_INFO_COUNTRY_ISO2_ASC',
  LocationInfoCountryIso2Desc = 'LOCATION_INFO_COUNTRY_ISO2_DESC',
  LocationInfoCountryIso3Asc = 'LOCATION_INFO_COUNTRY_ISO3_ASC',
  LocationInfoCountryIso3Desc = 'LOCATION_INFO_COUNTRY_ISO3_DESC',
  LocationInfoCountryIsoNumericAsc = 'LOCATION_INFO_COUNTRY_ISO_NUMERIC_ASC',
  LocationInfoCountryIsoNumericDesc = 'LOCATION_INFO_COUNTRY_ISO_NUMERIC_DESC',
  LocationInfoCountyAsc = 'LOCATION_INFO_COUNTY_ASC',
  LocationInfoCountyCodeAsc = 'LOCATION_INFO_COUNTY_CODE_ASC',
  LocationInfoCountyCodeDesc = 'LOCATION_INFO_COUNTY_CODE_DESC',
  LocationInfoCountyDesc = 'LOCATION_INFO_COUNTY_DESC',
  LocationInfoDistrictAsc = 'LOCATION_INFO_DISTRICT_ASC',
  LocationInfoDistrictDesc = 'LOCATION_INFO_DISTRICT_DESC',
  LocationInfoHouseNumberAsc = 'LOCATION_INFO_HOUSE_NUMBER_ASC',
  LocationInfoHouseNumberDesc = 'LOCATION_INFO_HOUSE_NUMBER_DESC',
  LocationInfoNeighborhoodAsc = 'LOCATION_INFO_NEIGHBORHOOD_ASC',
  LocationInfoNeighborhoodDesc = 'LOCATION_INFO_NEIGHBORHOOD_DESC',
  LocationInfoNeighborCountryIsoAsc = 'LOCATION_INFO_NEIGHBOR_COUNTRY_ISO_ASC',
  LocationInfoNeighborCountryIsoDesc = 'LOCATION_INFO_NEIGHBOR_COUNTRY_ISO_DESC',
  LocationInfoPostalCodeAsc = 'LOCATION_INFO_POSTAL_CODE_ASC',
  LocationInfoPostalCodeDesc = 'LOCATION_INFO_POSTAL_CODE_DESC',
  LocationInfoPostalCodeFormatAsc = 'LOCATION_INFO_POSTAL_CODE_FORMAT_ASC',
  LocationInfoPostalCodeFormatDesc = 'LOCATION_INFO_POSTAL_CODE_FORMAT_DESC',
  LocationInfoPostalCodeRegexAsc = 'LOCATION_INFO_POSTAL_CODE_REGEX_ASC',
  LocationInfoPostalCodeRegexDesc = 'LOCATION_INFO_POSTAL_CODE_REGEX_DESC',
  LocationInfoStateProvinceAsc = 'LOCATION_INFO_STATE_PROVINCE_ASC',
  LocationInfoStateProvinceDesc = 'LOCATION_INFO_STATE_PROVINCE_DESC',
  LocationInfoStateProvinceIsoAsc = 'LOCATION_INFO_STATE_PROVINCE_ISO_ASC',
  LocationInfoStateProvinceIsoDesc = 'LOCATION_INFO_STATE_PROVINCE_ISO_DESC',
  LocationInfoStreetAsc = 'LOCATION_INFO_STREET_ASC',
  LocationInfoStreetDesc = 'LOCATION_INFO_STREET_DESC',
  LocationTypeAsc = 'LOCATION_TYPE_ASC',
  LocationTypeDesc = 'LOCATION_TYPE_DESC',
  MapBboxAsc = 'MAP_BBOX_ASC',
  MapBboxDesc = 'MAP_BBOX_DESC',
  MetaJsonAsc = 'META_JSON_ASC',
  MetaJsonDesc = 'META_JSON_DESC',
  NameAsc = 'NAME_ASC',
  NameContextPreferredNamesAsc = 'NAME_CONTEXT_PREFERRED_NAMES_ASC',
  NameContextPreferredNamesDesc = 'NAME_CONTEXT_PREFERRED_NAMES_DESC',
  NameDesc = 'NAME_DESC',
  Natural = 'NATURAL',
  OfficalLanguageAsc = 'OFFICAL_LANGUAGE_ASC',
  OfficalLanguageDesc = 'OFFICAL_LANGUAGE_DESC',
  OfficialNameAsc = 'OFFICIAL_NAME_ASC',
  OfficialNameDesc = 'OFFICIAL_NAME_DESC',
  OsmIdAsc = 'OSM_ID_ASC',
  OsmIdDataAsc = 'OSM_ID_DATA_ASC',
  OsmIdDataDesc = 'OSM_ID_DATA_DESC',
  OsmIdDataErrorsAsc = 'OSM_ID_DATA_ERRORS_ASC',
  OsmIdDataErrorsDesc = 'OSM_ID_DATA_ERRORS_DESC',
  OsmIdDataTimestampAsc = 'OSM_ID_DATA_TIMESTAMP_ASC',
  OsmIdDataTimestampDesc = 'OSM_ID_DATA_TIMESTAMP_DESC',
  OsmIdDesc = 'OSM_ID_DESC',
  OsmPlaceIdAsc = 'OSM_PLACE_ID_ASC',
  OsmPlaceIdDesc = 'OSM_PLACE_ID_DESC',
  OsmTypeAsc = 'OSM_TYPE_ASC',
  OsmTypeDesc = 'OSM_TYPE_DESC',
  OwnerAccountIdAsc = 'OWNER_ACCOUNT_ID_ASC',
  OwnerAccountIdDesc = 'OWNER_ACCOUNT_ID_DESC',
  OwnerAllowedGeotagsAsc = 'OWNER_ALLOWED_GEOTAGS_ASC',
  OwnerAllowedGeotagsDesc = 'OWNER_ALLOWED_GEOTAGS_DESC',
  OwnerAllowedLikesAndCommentsAsc = 'OWNER_ALLOWED_LIKES_AND_COMMENTS_ASC',
  OwnerAllowedLikesAndCommentsDesc = 'OWNER_ALLOWED_LIKES_AND_COMMENTS_DESC',
  OwnerCaptionAsc = 'OWNER_CAPTION_ASC',
  OwnerCaptionDesc = 'OWNER_CAPTION_DESC',
  OwnerDisplayOwnerProfileLinkAsc = 'OWNER_DISPLAY_OWNER_PROFILE_LINK_ASC',
  OwnerDisplayOwnerProfileLinkDesc = 'OWNER_DISPLAY_OWNER_PROFILE_LINK_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  PhoneCodeAsc = 'PHONE_CODE_ASC',
  PhoneCodeDesc = 'PHONE_CODE_DESC',
  PhysicalInfoAreaSqKmAsc = 'PHYSICAL_INFO_AREA_SQ_KM_ASC',
  PhysicalInfoAreaSqKmDesc = 'PHYSICAL_INFO_AREA_SQ_KM_DESC',
  PhysicalInfoElevationAsc = 'PHYSICAL_INFO_ELEVATION_ASC',
  PhysicalInfoElevationDesc = 'PHYSICAL_INFO_ELEVATION_DESC',
  PlaceDescriptionAsc = 'PLACE_DESCRIPTION_ASC',
  PlaceDescriptionDesc = 'PLACE_DESCRIPTION_DESC',
  PlaceHoursOfOperationAsc = 'PLACE_HOURS_OF_OPERATION_ASC',
  PlaceHoursOfOperationDesc = 'PLACE_HOURS_OF_OPERATION_DESC',
  PlaceInfoDescriptionAsc = 'PLACE_INFO_DESCRIPTION_ASC',
  PlaceInfoDescriptionDesc = 'PLACE_INFO_DESCRIPTION_DESC',
  PlaceInfoHoursAsc = 'PLACE_INFO_HOURS_ASC',
  PlaceInfoHoursDesc = 'PLACE_INFO_HOURS_DESC',
  PlaceInfoHoursJsonAsc = 'PLACE_INFO_HOURS_JSON_ASC',
  PlaceInfoHoursJsonDesc = 'PLACE_INFO_HOURS_JSON_DESC',
  PlaceInfoIconUrlAsc = 'PLACE_INFO_ICON_URL_ASC',
  PlaceInfoIconUrlDesc = 'PLACE_INFO_ICON_URL_DESC',
  PlaceInfoImageGalleryAsc = 'PLACE_INFO_IMAGE_GALLERY_ASC',
  PlaceInfoImageGalleryDesc = 'PLACE_INFO_IMAGE_GALLERY_DESC',
  PlaceInfoImageUrlAsc = 'PLACE_INFO_IMAGE_URL_ASC',
  PlaceInfoImageUrlDesc = 'PLACE_INFO_IMAGE_URL_DESC',
  PlaceInfoOwnerAsc = 'PLACE_INFO_OWNER_ASC',
  PlaceInfoOwnerDesc = 'PLACE_INFO_OWNER_DESC',
  PlaceInfoPhoneNumberAsc = 'PLACE_INFO_PHONE_NUMBER_ASC',
  PlaceInfoPhoneNumberDesc = 'PLACE_INFO_PHONE_NUMBER_DESC',
  PlaceInfoWebsiteAsc = 'PLACE_INFO_WEBSITE_ASC',
  PlaceInfoWebsiteDesc = 'PLACE_INFO_WEBSITE_DESC',
  PlacePhoneAsc = 'PLACE_PHONE_ASC',
  PlacePhoneDesc = 'PLACE_PHONE_DESC',
  PlaceWebsiteAsc = 'PLACE_WEBSITE_ASC',
  PlaceWebsiteDesc = 'PLACE_WEBSITE_DESC',
  PremiumEnabledAsc = 'PREMIUM_ENABLED_ASC',
  PremiumEnabledDesc = 'PREMIUM_ENABLED_DESC',
  PremiumEnabledTimestampAsc = 'PREMIUM_ENABLED_TIMESTAMP_ASC',
  PremiumEnabledTimestampDesc = 'PREMIUM_ENABLED_TIMESTAMP_DESC',
  ProcessExecuteContentModerationCompleteAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_ASC',
  ProcessExecuteContentModerationCompleteDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_DESC',
  ProcessExecuteContentModerationDateAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_ASC',
  ProcessExecuteContentModerationDateDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_DESC',
  ProcessExecuteContentModerationErrorsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_ASC',
  ProcessExecuteContentModerationErrorsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_DESC',
  ProcessExecuteContentModerationResultsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_ASC',
  ProcessExecuteContentModerationResultsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_DESC',
  ProcessExecuteContentModerationReviewerOverrideAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_ASC',
  ProcessExecuteContentModerationReviewerOverrideDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_DESC',
  ProcessGeotaggerLastSyncAsc = 'PROCESS_GEOTAGGER_LAST_SYNC_ASC',
  ProcessGeotaggerLastSyncDesc = 'PROCESS_GEOTAGGER_LAST_SYNC_DESC',
  ProcessGeotaggerLastSyncErrorsAsc = 'PROCESS_GEOTAGGER_LAST_SYNC_ERRORS_ASC',
  ProcessGeotaggerLastSyncErrorsDesc = 'PROCESS_GEOTAGGER_LAST_SYNC_ERRORS_DESC',
  RecommendationsAsc = 'RECOMMENDATIONS_ASC',
  RecommendationsDesc = 'RECOMMENDATIONS_DESC',
  SaveLabelAsc = 'SAVE_LABEL_ASC',
  SaveLabelDesc = 'SAVE_LABEL_DESC',
  SocialCommentCountAsc = 'SOCIAL_COMMENT_COUNT_ASC',
  SocialCommentCountDesc = 'SOCIAL_COMMENT_COUNT_DESC',
  SocialDownVoteCountAsc = 'SOCIAL_DOWN_VOTE_COUNT_ASC',
  SocialDownVoteCountDesc = 'SOCIAL_DOWN_VOTE_COUNT_DESC',
  SocialGeocastCountAsc = 'SOCIAL_GEOCAST_COUNT_ASC',
  SocialGeocastCountDesc = 'SOCIAL_GEOCAST_COUNT_DESC',
  SocialGeodropCountAsc = 'SOCIAL_GEODROP_COUNT_ASC',
  SocialGeodropCountDesc = 'SOCIAL_GEODROP_COUNT_DESC',
  SocialLikeCountAsc = 'SOCIAL_LIKE_COUNT_ASC',
  SocialLikeCountDesc = 'SOCIAL_LIKE_COUNT_DESC',
  SocialUpVoteCountAsc = 'SOCIAL_UP_VOTE_COUNT_ASC',
  SocialUpVoteCountDesc = 'SOCIAL_UP_VOTE_COUNT_DESC',
  SpokenLanguageAsc = 'SPOKEN_LANGUAGE_ASC',
  SpokenLanguageDesc = 'SPOKEN_LANGUAGE_DESC',
  SystemIdAsc = 'SYSTEM_ID_ASC',
  SystemIdDesc = 'SYSTEM_ID_DESC',
  TimezonesAsc = 'TIMEZONES_ASC',
  TimezonesDesc = 'TIMEZONES_DESC',
  TimezoneAsc = 'TIMEZONE_ASC',
  TimezoneDesc = 'TIMEZONE_DESC',
  TypeAsc = 'TYPE_ASC',
  TypeDesc = 'TYPE_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
  VerifiedOwnerIdAsc = 'VERIFIED_OWNER_ID_ASC',
  VerifiedOwnerIdDesc = 'VERIFIED_OWNER_ID_DESC',
  WofIdAsc = 'WOF_ID_ASC',
  WofIdDesc = 'WOF_ID_DESC',
  YelpIdAsc = 'YELP_ID_ASC',
  YelpIdDataAsc = 'YELP_ID_DATA_ASC',
  YelpIdDataDesc = 'YELP_ID_DATA_DESC',
  YelpIdDataErrorsAsc = 'YELP_ID_DATA_ERRORS_ASC',
  YelpIdDataErrorsDesc = 'YELP_ID_DATA_ERRORS_DESC',
  YelpIdDataTimestampAsc = 'YELP_ID_DATA_TIMESTAMP_ASC',
  YelpIdDataTimestampDesc = 'YELP_ID_DATA_TIMESTAMP_DESC',
  YelpIdDesc = 'YELP_ID_DESC',
}

/** All geometry XY types implement this interface */
export type GeometryGeometry = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};

/** All geometry types implement this interface */
export type GeometryInterface = {
  /** Converts the object to GeoJSON */
  geojson?: Maybe<Scalars['GeoJSON']['output']>;
  /** Spatial reference identifier (SRID) */
  srid: Scalars['Int']['output'];
};
export type GeometryPoint = GeometryGeometry & GeometryInterface & {
  __typename?: 'GeometryPoint';
  geojson?: Maybe<Scalars['GeoJSON']['output']>;
  srid: Scalars['Int']['output'];
  x: Scalars['Float']['output'];
  y: Scalars['Float']['output'];
};

/** A filter to be used against GeometryPoint fields. All fields are combined with a logical ‘and.’ */
export type GeometryPointFilter = {
  /** Bounding box is strictly above the specified geometry's bounding box. */
  bboxAbove?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box is strictly below the specified geometry's bounding box. */
  bboxBelow?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box contains the specified geometry's bounding box. */
  bboxContains?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box is the same as the specified geometry's bounding box. */
  bboxEquals?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** 2D bounding box intersects the specified geometry's 2D bounding box. */
  bboxIntersects2D?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** n-D bounding box intersects the specified geometry's n-D bounding box. */
  bboxIntersectsND?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box is strictly to the left of the specified geometry's bounding box. */
  bboxLeftOf?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box overlaps or is above the specified geometry's bounding box. */
  bboxOverlapsOrAbove?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box overlaps or is below the specified geometry's bounding box. */
  bboxOverlapsOrBelow?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box overlaps or is to the left of the specified geometry's bounding box. */
  bboxOverlapsOrLeftOf?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box overlaps or is to the right of the specified geometry's bounding box. */
  bboxOverlapsOrRightOf?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Bounding box is strictly to the right of the specified geometry's bounding box. */
  bboxRightOf?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** No points of the specified geometry lie in the exterior, and at least one point of the interior of the specified geometry lies in the interior. */
  contains?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** The specified geometry intersects the interior but not the boundary (or exterior). */
  containsProperly?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** No point is outside the specified geometry. */
  coveredBy?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** No point in the specified geometry is outside. */
  covers?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They have some, but not all, interior points in common. */
  crosses?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They do not share any space together. */
  disjoint?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They represent the same geometry. Directionality is ignored. */
  equals?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Coordinates and coordinate order are the same as specified geometry. */
  exactlyEquals?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They share any portion of space in 2D. */
  intersects?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They share any portion of space in 3D. */
  intersects3D?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They represent the same geometry and points are in the same directional order. */
  orderingEquals?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They share space, are of the same dimension, but are not completely contained by each other. */
  overlaps?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** They have at least one point in common, but their interiors do not intersect. */
  touches?: InputMaybe<Scalars['GeoJSON']['input']>;
  /** Completely inside the specified geometry. */
  within?: InputMaybe<Scalars['GeoJSON']['input']>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['Int']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['Int']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['Int']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['Int']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['Int']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['Int']['input']>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars['JSON']['input']>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['JSON']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['JSON']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['JSON']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['JSON']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['JSON']['input']>>;
};
export type Location = {
  latitude?: InputMaybe<Scalars['Float']['input']>;
  longitude?: InputMaybe<Scalars['Float']['input']>;
};
export type MapData = {
  __typename?: 'MapData';
  alerts?: Maybe<Scalars['JSON']['output']>;
  geoCasts?: Maybe<Scalars['JSON']['output']>;
  geoCodes?: Maybe<Scalars['JSON']['output']>;
  geoDrops?: Maybe<Scalars['JSON']['output']>;
  groups?: Maybe<Scalars['JSON']['output']>;
  interests?: Maybe<Scalars['JSON']['output']>;
  publicGeoCasts?: Maybe<Scalars['JSON']['output']>;
  publicGeoDrops?: Maybe<Scalars['JSON']['output']>;
  users?: Maybe<Scalars['JSON']['output']>;
};
export type MapFilter = {
  group?: InputMaybe<Scalars['BigInt']['input']>;
  interest?: InputMaybe<Scalars['BigInt']['input']>;
  user?: InputMaybe<Scalars['BigInt']['input']>;
};
export type MapResults = {
  __typename?: 'MapResults';
  results?: Maybe<Scalars['JSON']['output']>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: 'Mutation';
  /** Creates a single `AppBadge`. */
  createAppBadge?: Maybe<CreateAppBadgePayload>;
  /** Creates a single `AppDropCategory`. */
  createAppDropCategory?: Maybe<CreateAppDropCategoryPayload>;
  /** Creates a single `AppEmoji`. */
  createAppEmoji?: Maybe<CreateAppEmojiPayload>;
  /** Creates a single `GeoActivity`. */
  createGeoActivity?: Maybe<CreateGeoActivityPayload>;
  /** Creates a single `GeoActivityTag`. */
  createGeoActivityTag?: Maybe<CreateGeoActivityTagPayload>;
  /** Creates a single `GeoCode`. */
  createGeoCode?: Maybe<CreateGeoCodePayload>;
  /** Creates a single `GeoCodeTag`. */
  createGeoCodeTag?: Maybe<CreateGeoCodeTagPayload>;
  /** Creates a single `Tag`. */
  createTag?: Maybe<CreateTagPayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserBusinessCard`. */
  createUserBusinessCard?: Maybe<CreateUserBusinessCardPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppBadgeArgs = {
  input: CreateAppBadgeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppDropCategoryArgs = {
  input: CreateAppDropCategoryInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAppEmojiArgs = {
  input: CreateAppEmojiInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeoActivityArgs = {
  input: CreateGeoActivityInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeoActivityTagArgs = {
  input: CreateGeoActivityTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeoCodeArgs = {
  input: CreateGeoCodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateGeoCodeTagArgs = {
  input: CreateGeoCodeTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateTagArgs = {
  input: CreateTagInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserBusinessCardArgs = {
  input: CreateUserBusinessCardInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  nodeId: Scalars['ID']['output'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['Cursor']['output']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean']['output'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean']['output'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['Cursor']['output']>;
};

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: 'Query';
  activities?: Maybe<MapData>;
  /** Reads and enables pagination through a set of `AppBadge`. */
  appBadges?: Maybe<AppBadgesConnection>;
  /** Reads and enables pagination through a set of `AppDropCategory`. */
  appDropCategories?: Maybe<AppDropCategoriesConnection>;
  /** Reads and enables pagination through a set of `AppEmoji`. */
  appEmojis?: Maybe<AppEmojisConnection>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivities?: Maybe<GeoActivitiesConnection>;
  /** Reads and enables pagination through a set of `GeoActivityTag`. */
  geoActivityTags?: Maybe<GeoActivityTagsConnection>;
  /** Reads and enables pagination through a set of `GeoCodeTag`. */
  geoCodeTags?: Maybe<GeoCodeTagsConnection>;
  /** Reads and enables pagination through a set of `GeoCode`. */
  geoCodes?: Maybe<GeoCodesConnection>;
  getAnonMapFeed?: Maybe<AnonMapFeed>;
  getFeeds?: Maybe<MapResults>;
  getFeedsConfig?: Maybe<MapResults>;
  getGeoActivityAccessLevel?: Maybe<GeoActivityAccess>;
  getGeoCodeAccessLevel?: Maybe<GeoCodeAccess>;
  getLayers?: Maybe<MapResults>;
  getUserProfileAccessPermissions?: Maybe<UserAccessPermissions>;
  getUserProfileLocation?: Maybe<UserLocationLevelAccess>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  nodeId: Scalars['ID']['output'];
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  /** Reads and enables pagination through a set of `Tag`. */
  tags?: Maybe<TagsConnection>;
  /** Reads and enables pagination through a set of `UserBusinessCard`. */
  userBusinessCards?: Maybe<UserBusinessCardsConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryActivitiesArgs = {
  boundary?: InputMaybe<Boundary>;
  currentLocation?: InputMaybe<Location>;
  filter?: InputMaybe<MapFilter>;
  location?: InputMaybe<Location>;
  sharingAndFilterCriteria?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  zoomLevel?: InputMaybe<Scalars['Float']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAppBadgesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AppBadgeCondition>;
  filter?: InputMaybe<AppBadgeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AppBadgesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAppDropCategoriesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AppDropCategoryCondition>;
  filter?: InputMaybe<AppDropCategoryFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AppDropCategoriesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAppEmojisArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<AppEmojiCondition>;
  filter?: InputMaybe<AppEmojiFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<AppEmojisOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGeoActivitiesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGeoActivityTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityTagCondition>;
  filter?: InputMaybe<GeoActivityTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivityTagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGeoCodeTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoCodeTagCondition>;
  filter?: InputMaybe<GeoCodeTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoCodeTagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGeoCodesArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoCodeCondition>;
  filter?: InputMaybe<GeoCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoCodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetAnonMapFeedArgs = {
  boundary?: InputMaybe<Boundary>;
  currentLocation?: InputMaybe<Location>;
  filter?: InputMaybe<MapFilter>;
  location?: InputMaybe<Location>;
  sharingAndFilterCriteria?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  zoomLevel?: InputMaybe<Scalars['Float']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetFeedsArgs = {
  isUserSaved?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetGeoActivityAccessLevelArgs = {
  referenceDate?: InputMaybe<Scalars['String']['input']>;
  targetGeoActivityId?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetGeoCodeAccessLevelArgs = {
  targetGeoCodeId?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetLayersArgs = {
  boundary: Boundary;
  groupId?: InputMaybe<Scalars['BigInt']['input']>;
  includeGeoJson?: InputMaybe<Scalars['Boolean']['input']>;
  isGroupSaved?: InputMaybe<Scalars['Boolean']['input']>;
  isUserSaved?: InputMaybe<Scalars['Boolean']['input']>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetUserProfileAccessPermissionsArgs = {
  targetUserId?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryGetUserProfileLocationArgs = {
  targetUserId?: InputMaybe<Scalars['BigInt']['input']>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  nodeId: Scalars['ID']['input'];
};

/** The root query type which gives access points into the data universe. */
export type QueryTagsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<TagCondition>;
  filter?: InputMaybe<TagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<TagsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserBusinessCardsArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserBusinessCardCondition>;
  filter?: InputMaybe<UserBusinessCardFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UserBusinessCardsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value, treating null like an ordinary value (case-insensitive). */
  distinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars['String']['input']>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value (case-insensitive). */
  equalToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['String']['input']>;
  /** Greater than the specified value (case-insensitive). */
  greaterThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Greater than or equal to the specified value (case-insensitive). */
  greaterThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Included in the specified list (case-insensitive). */
  inInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars['String']['input']>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['String']['input']>;
  /** Less than the specified value (case-insensitive). */
  lessThanInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Less than or equal to the specified value (case-insensitive). */
  lessThanOrEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars['String']['input']>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['String']['input']>;
  /** Equal to the specified value, treating null like an ordinary value (case-insensitive). */
  notDistinctFromInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['String']['input']>;
  /** Not equal to the specified value (case-insensitive). */
  notEqualToInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Not included in the specified list (case-insensitive). */
  notInInsensitive?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars['String']['input']>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars['String']['input']>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars['String']['input']>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars['String']['input']>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars['String']['input']>;
};
export type Tag = {
  __typename?: 'Tag';
  category?: Maybe<Scalars['String']['output']>;
  classificationType?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  followerCount?: Maybe<Scalars['Int']['output']>;
  /** Reads and enables pagination through a set of `GeoActivityTag`. */
  geoActivityTagsByTagId: GeoActivityTagsConnection;
  /** Reads and enables pagination through a set of `GeoCodeTag`. */
  geoCodeTagsByTagId: GeoCodeTagsConnection;
  geoactivityUsageCount?: Maybe<Scalars['Int']['output']>;
  geocodeId?: Maybe<Scalars['BigInt']['output']>;
  geocodeUsageCount?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  interestId?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  isVisible?: Maybe<Scalars['Boolean']['output']>;
  label?: Maybe<Scalars['String']['output']>;
  likeCount?: Maybe<Scalars['Int']['output']>;
  processExecuteContentModerationComplete?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationDate?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationErrors?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationResults?: Maybe<Scalars['JSON']['output']>;
  processExecuteContentModerationReviewerOverride?: Maybe<Scalars['Boolean']['output']>;
  totalUsageCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
};
export type TagGeoActivityTagsByTagIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityTagCondition>;
  filter?: InputMaybe<GeoActivityTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivityTagsOrderBy>>;
};
export type TagGeoCodeTagsByTagIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoCodeTagCondition>;
  filter?: InputMaybe<GeoCodeTagFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoCodeTagsOrderBy>>;
};

/** A condition to be used against `Tag` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type TagCondition = {
  /** Checks for equality with the object’s `category` field. */
  category?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `classificationType` field. */
  classificationType?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `followerCount` field. */
  followerCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `geoactivityUsageCount` field. */
  geoactivityUsageCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `geocodeUsageCount` field. */
  geocodeUsageCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `imageUrl` field. */
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `interestId` field. */
  interestId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isVisible` field. */
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `label` field. */
  label?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `likeCount` field. */
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationResults` field. */
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `totalUsageCount` field. */
  totalUsageCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A filter to be used against `Tag` object types. All fields are combined with a logical ‘and.’ */
export type TagFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `category` field. */
  category?: InputMaybe<StringFilter>;
  /** Filter by the object’s `classificationType` field. */
  classificationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `followerCount` field. */
  followerCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `geoactivityUsageCount` field. */
  geoactivityUsageCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `geocodeUsageCount` field. */
  geocodeUsageCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `imageUrl` field. */
  imageUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `interestId` field. */
  interestId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isVisible` field. */
  isVisible?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `label` field. */
  label?: InputMaybe<StringFilter>;
  /** Filter by the object’s `likeCount` field. */
  likeCount?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<TagFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<TagFilter>>;
  /** Filter by the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `totalUsageCount` field. */
  totalUsageCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

/** An input for mutations affecting `Tag` */
export type TagInput = {
  category?: InputMaybe<Scalars['String']['input']>;
  classificationType?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  followerCount?: InputMaybe<Scalars['Int']['input']>;
  geoactivityUsageCount?: InputMaybe<Scalars['Int']['input']>;
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  geocodeUsageCount?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
  interestId?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isVisible?: InputMaybe<Scalars['Boolean']['input']>;
  label?: InputMaybe<Scalars['String']['input']>;
  likeCount?: InputMaybe<Scalars['Int']['input']>;
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  totalUsageCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
};

/** A connection to a list of `Tag` values. */
export type TagsConnection = {
  __typename?: 'TagsConnection';
  /** A list of edges which contains the `Tag` and cursor to aid in pagination. */
  edges: Array<TagsEdge>;
  /** A list of `Tag` objects. */
  nodes: Array<Tag>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Tag` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `Tag` edge in the connection. */
export type TagsEdge = {
  __typename?: 'TagsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `Tag` at the end of the edge. */
  node: Tag;
};

/** Methods to use when ordering `Tag`. */
export enum TagsOrderBy {
  CategoryAsc = 'CATEGORY_ASC',
  CategoryDesc = 'CATEGORY_DESC',
  ClassificationTypeAsc = 'CLASSIFICATION_TYPE_ASC',
  ClassificationTypeDesc = 'CLASSIFICATION_TYPE_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DescriptionAsc = 'DESCRIPTION_ASC',
  DescriptionDesc = 'DESCRIPTION_DESC',
  FollowerCountAsc = 'FOLLOWER_COUNT_ASC',
  FollowerCountDesc = 'FOLLOWER_COUNT_DESC',
  GeoactivityUsageCountAsc = 'GEOACTIVITY_USAGE_COUNT_ASC',
  GeoactivityUsageCountDesc = 'GEOACTIVITY_USAGE_COUNT_DESC',
  GeocodeIdAsc = 'GEOCODE_ID_ASC',
  GeocodeIdDesc = 'GEOCODE_ID_DESC',
  GeocodeUsageCountAsc = 'GEOCODE_USAGE_COUNT_ASC',
  GeocodeUsageCountDesc = 'GEOCODE_USAGE_COUNT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  ImageUrlAsc = 'IMAGE_URL_ASC',
  ImageUrlDesc = 'IMAGE_URL_DESC',
  InterestIdAsc = 'INTEREST_ID_ASC',
  InterestIdDesc = 'INTEREST_ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  IsVisibleAsc = 'IS_VISIBLE_ASC',
  IsVisibleDesc = 'IS_VISIBLE_DESC',
  LabelAsc = 'LABEL_ASC',
  LabelDesc = 'LABEL_DESC',
  LikeCountAsc = 'LIKE_COUNT_ASC',
  LikeCountDesc = 'LIKE_COUNT_DESC',
  Natural = 'NATURAL',
  ProcessExecuteContentModerationCompleteAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_ASC',
  ProcessExecuteContentModerationCompleteDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_DESC',
  ProcessExecuteContentModerationDateAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_ASC',
  ProcessExecuteContentModerationDateDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_DESC',
  ProcessExecuteContentModerationErrorsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_ASC',
  ProcessExecuteContentModerationErrorsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_DESC',
  ProcessExecuteContentModerationResultsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_ASC',
  ProcessExecuteContentModerationResultsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_DESC',
  ProcessExecuteContentModerationReviewerOverrideAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_ASC',
  ProcessExecuteContentModerationReviewerOverrideDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_DESC',
  TotalUsageCountAsc = 'TOTAL_USAGE_COUNT_ASC',
  TotalUsageCountDesc = 'TOTAL_USAGE_COUNT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars['UUID']['input']>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars['Boolean']['input']>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars['UUID']['input']>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars['UUID']['input']>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars['UUID']['input']>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars['UUID']['input']>>;
};
export type User = {
  __typename?: 'User';
  appId?: Maybe<Scalars['String']['output']>;
  bcardBusinessName?: Maybe<Scalars['String']['output']>;
  bcardEmail?: Maybe<Scalars['String']['output']>;
  bcardJobTitle?: Maybe<Scalars['String']['output']>;
  bcardLocationAddress?: Maybe<Scalars['String']['output']>;
  bcardName?: Maybe<Scalars['String']['output']>;
  bcardPhoneNumber?: Maybe<Scalars['String']['output']>;
  bcardSocialFacebookUsername?: Maybe<Scalars['String']['output']>;
  bcardSocialInstagramUsername?: Maybe<Scalars['String']['output']>;
  bcardSocialLinkedinUsername?: Maybe<Scalars['String']['output']>;
  bcardSocialTwitterUsername?: Maybe<Scalars['String']['output']>;
  bcardVerified?: Maybe<Scalars['Boolean']['output']>;
  bcardWebsite?: Maybe<Scalars['String']['output']>;
  birthDate?: Maybe<Scalars['Datetime']['output']>;
  computeNumOfSharedInterests?: Maybe<Scalars['Int']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  deleteMyAccountRequestDate?: Maybe<Scalars['Datetime']['output']>;
  deleteMyAccountRequested?: Maybe<Scalars['Boolean']['output']>;
  deviceNotificationAllowed?: Maybe<Scalars['Boolean']['output']>;
  deviceNotificationToken?: Maybe<Scalars['String']['output']>;
  deviceOs?: Maybe<Scalars['String']['output']>;
  finishedOnboarding?: Maybe<Scalars['Boolean']['output']>;
  fullName?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByOwnerId: GeoActivitiesConnection;
  /** Reads and enables pagination through a set of `GeoCode`. */
  geoCodesByVerifiedOwnerId: GeoCodesConnection;
  homeLocationGeocodeId?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isCompletedTutorial?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isDemo?: Maybe<Scalars['Boolean']['output']>;
  isOnboardingComplete?: Maybe<Scalars['Boolean']['output']>;
  isPrivate?: Maybe<Scalars['Boolean']['output']>;
  isSystemUser?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  klaviyoEmailMarketingInfoSynced?: Maybe<Scalars['Boolean']['output']>;
  lastAppOpen?: Maybe<Scalars['Datetime']['output']>;
  lastKnownLocationGeocodeId?: Maybe<Scalars['BigInt']['output']>;
  lastNotificationsViewTimestamp?: Maybe<Scalars['Datetime']['output']>;
  metaJson?: Maybe<Scalars['JSON']['output']>;
  myRecommendations?: Maybe<Scalars['JSON']['output']>;
  myRecommendationsDate?: Maybe<Scalars['JSON']['output']>;
  onboardingStatus?: Maybe<Scalars['String']['output']>;
  premiumEnabled?: Maybe<Scalars['Boolean']['output']>;
  premiumEnabledTimestamp?: Maybe<Scalars['Datetime']['output']>;
  primaryVisibleAccount?: Maybe<Scalars['Boolean']['output']>;
  prioritizedSortOrder?: Maybe<Scalars['Int']['output']>;
  privacyShowFullNameAll?: Maybe<Scalars['Boolean']['output']>;
  privacyShowFullNameTrustedGroups?: Maybe<Scalars['Boolean']['output']>;
  privacyShowFullNameTrustedUsers?: Maybe<Scalars['Boolean']['output']>;
  privacyShowInterestsAndGroupsAll?: Maybe<Scalars['Boolean']['output']>;
  privacyShowInterestsAndGroupsTrustedGroups?: Maybe<Scalars['Boolean']['output']>;
  privacyShowInterestsAndGroupsTrustedUsers?: Maybe<Scalars['Boolean']['output']>;
  privacyShowProfilePicAll?: Maybe<Scalars['Boolean']['output']>;
  privacyShowProfilePicTrustedGroups?: Maybe<Scalars['Boolean']['output']>;
  privacyShowProfilePicTrustedUsers?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationComplete?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationDate?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationErrors?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationResults?: Maybe<Scalars['JSON']['output']>;
  processExecuteContentModerationReviewerOverride?: Maybe<Scalars['Boolean']['output']>;
  profileBio?: Maybe<Scalars['String']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  publicAlias?: Maybe<Scalars['String']['output']>;
  publicAliasTsv?: Maybe<Scalars['String']['output']>;
  publicLocationViewCity?: Maybe<Scalars['Boolean']['output']>;
  publicLocationViewCountry?: Maybe<Scalars['Boolean']['output']>;
  publicLocationViewNeighborhood?: Maybe<Scalars['Boolean']['output']>;
  publicLocationViewStreet?: Maybe<Scalars['Boolean']['output']>;
  recommendations?: Maybe<Scalars['JSON']['output']>;
  referralCode?: Maybe<Scalars['String']['output']>;
  referredBy?: Maybe<Scalars['BigInt']['output']>;
  referredCode?: Maybe<Scalars['String']['output']>;
  registrationId?: Maybe<Scalars['BigInt']['output']>;
  settingsDashboardMyElectedOfficialsOnlyFilter?: Maybe<Scalars['Boolean']['output']>;
  settingsDashboardMyGroupsOnlyFilter?: Maybe<Scalars['Boolean']['output']>;
  settingsDashboardMyInterestsOnlyFilter?: Maybe<Scalars['Boolean']['output']>;
  settingsDashboardMyTrustedUsersOnlyFilter?: Maybe<Scalars['Boolean']['output']>;
  settingsEnableInterestMatchNotifications?: Maybe<Scalars['Boolean']['output']>;
  settingsLocale?: Maybe<Scalars['String']['output']>;
  settingsNotificationsOnMyGeoactivityComments?: Maybe<Scalars['Boolean']['output']>;
  settingsNotificationsOnMyMentions?: Maybe<Scalars['Boolean']['output']>;
  settingsNotificationsOnMyPrivateMessages?: Maybe<Scalars['Boolean']['output']>;
  settingsNotificationsOnSafetyAlertsNearby?: Maybe<Scalars['Boolean']['output']>;
  syncMarketingInfoComplete?: Maybe<Scalars['Boolean']['output']>;
  termsOfUseAndPrivacyConfirmed?: Maybe<Scalars['Boolean']['output']>;
  termsOfUseAndPrivacyConfirmedDate?: Maybe<Scalars['Datetime']['output']>;
  unreadPrivateMessageCount?: Maybe<Scalars['Int']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  uuid?: Maybe<Scalars['UUID']['output']>;
};
export type UserGeoActivitiesByOwnerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};
export type UserGeoCodesByVerifiedOwnerIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoCodeCondition>;
  filter?: InputMaybe<GeoCodeFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoCodesOrderBy>>;
};
export type UserAccessPermissions = {
  __typename?: 'UserAccessPermissions';
  userFullNameVisible?: Maybe<Scalars['Boolean']['output']>;
  userGroupsAndInterestsVisible?: Maybe<Scalars['Boolean']['output']>;
  userPictureVisible?: Maybe<Scalars['Boolean']['output']>;
};
export type UserBusinessCard = {
  __typename?: 'UserBusinessCard';
  businessDropsEnabled?: Maybe<Scalars['Boolean']['output']>;
  businessName?: Maybe<Scalars['String']['output']>;
  contactName?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['Datetime']['output']>;
  domain?: Maybe<Scalars['String']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  enterpriseId?: Maybe<Scalars['String']['output']>;
  /** Reads and enables pagination through a set of `GeoActivity`. */
  geoActivitiesByUserBusinessCardId: GeoActivitiesConnection;
  geocodeId?: Maybe<Scalars['BigInt']['output']>;
  id?: Maybe<Scalars['BigInt']['output']>;
  internalDataopsTag?: Maybe<Scalars['String']['output']>;
  isApproved?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  isTest?: Maybe<Scalars['Boolean']['output']>;
  isVerified?: Maybe<Scalars['Boolean']['output']>;
  jobTitle?: Maybe<Scalars['String']['output']>;
  offers?: Maybe<Scalars['JSON']['output']>;
  ownerId?: Maybe<Scalars['BigInt']['output']>;
  phoneNumber?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationComplete?: Maybe<Scalars['Boolean']['output']>;
  processExecuteContentModerationDate?: Maybe<Scalars['Datetime']['output']>;
  processExecuteContentModerationErrors?: Maybe<Scalars['String']['output']>;
  processExecuteContentModerationResults?: Maybe<Scalars['JSON']['output']>;
  processExecuteContentModerationReviewerOverride?: Maybe<Scalars['Boolean']['output']>;
  profileImageUrl?: Maybe<Scalars['String']['output']>;
  socialFacebookUsername?: Maybe<Scalars['String']['output']>;
  socialInstagramUsername?: Maybe<Scalars['String']['output']>;
  socialLinkedinUsername?: Maybe<Scalars['String']['output']>;
  socialTwitterUsername?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['Datetime']['output']>;
  website?: Maybe<Scalars['String']['output']>;
};
export type UserBusinessCardGeoActivitiesByUserBusinessCardIdArgs = {
  after?: InputMaybe<Scalars['Cursor']['input']>;
  before?: InputMaybe<Scalars['Cursor']['input']>;
  condition?: InputMaybe<GeoActivityCondition>;
  filter?: InputMaybe<GeoActivityFilter>;
  first?: InputMaybe<Scalars['Int']['input']>;
  last?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  orderBy?: InputMaybe<Array<GeoActivitiesOrderBy>>;
};

/**
 * A condition to be used against `UserBusinessCard` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserBusinessCardCondition = {
  /** Checks for equality with the object’s `businessDropsEnabled` field. */
  businessDropsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `businessName` field. */
  businessName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `contactName` field. */
  contactName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `enterpriseId` field. */
  enterpriseId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `jobTitle` field. */
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `offers` field. */
  offers?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `ownerId` field. */
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationResults` field. */
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `profileImageUrl` field. */
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialFacebookUsername` field. */
  socialFacebookUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialInstagramUsername` field. */
  socialInstagramUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialLinkedinUsername` field. */
  socialLinkedinUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `socialTwitterUsername` field. */
  socialTwitterUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `website` field. */
  website?: InputMaybe<Scalars['String']['input']>;
};

/** A filter to be used against `UserBusinessCard` object types. All fields are combined with a logical ‘and.’ */
export type UserBusinessCardFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserBusinessCardFilter>>;
  /** Filter by the object’s `businessDropsEnabled` field. */
  businessDropsEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `businessName` field. */
  businessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `contactName` field. */
  contactName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enterpriseId` field. */
  enterpriseId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `geocodeId` field. */
  geocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `jobTitle` field. */
  jobTitle?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserBusinessCardFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserBusinessCardFilter>>;
  /** Filter by the object’s `ownerId` field. */
  ownerId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `phoneNumber` field. */
  phoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `profileImageUrl` field. */
  profileImageUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `socialFacebookUsername` field. */
  socialFacebookUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `socialInstagramUsername` field. */
  socialInstagramUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `socialLinkedinUsername` field. */
  socialLinkedinUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `socialTwitterUsername` field. */
  socialTwitterUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `website` field. */
  website?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `UserBusinessCard` */
export type UserBusinessCardInput = {
  businessDropsEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  businessName?: InputMaybe<Scalars['String']['input']>;
  contactName?: InputMaybe<Scalars['String']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  domain?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  enterpriseId?: InputMaybe<Scalars['String']['input']>;
  geocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  jobTitle?: InputMaybe<Scalars['String']['input']>;
  offers?: InputMaybe<Scalars['JSON']['input']>;
  ownerId?: InputMaybe<Scalars['BigInt']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  socialFacebookUsername?: InputMaybe<Scalars['String']['input']>;
  socialInstagramUsername?: InputMaybe<Scalars['String']['input']>;
  socialLinkedinUsername?: InputMaybe<Scalars['String']['input']>;
  socialTwitterUsername?: InputMaybe<Scalars['String']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  website?: InputMaybe<Scalars['String']['input']>;
};

/** A connection to a list of `UserBusinessCard` values. */
export type UserBusinessCardsConnection = {
  __typename?: 'UserBusinessCardsConnection';
  /** A list of edges which contains the `UserBusinessCard` and cursor to aid in pagination. */
  edges: Array<UserBusinessCardsEdge>;
  /** A list of `UserBusinessCard` objects. */
  nodes: Array<UserBusinessCard>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserBusinessCard` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `UserBusinessCard` edge in the connection. */
export type UserBusinessCardsEdge = {
  __typename?: 'UserBusinessCardsEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `UserBusinessCard` at the end of the edge. */
  node: UserBusinessCard;
};

/** Methods to use when ordering `UserBusinessCard`. */
export enum UserBusinessCardsOrderBy {
  BusinessDropsEnabledAsc = 'BUSINESS_DROPS_ENABLED_ASC',
  BusinessDropsEnabledDesc = 'BUSINESS_DROPS_ENABLED_DESC',
  BusinessNameAsc = 'BUSINESS_NAME_ASC',
  BusinessNameDesc = 'BUSINESS_NAME_DESC',
  ContactNameAsc = 'CONTACT_NAME_ASC',
  ContactNameDesc = 'CONTACT_NAME_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DomainAsc = 'DOMAIN_ASC',
  DomainDesc = 'DOMAIN_DESC',
  EmailAsc = 'EMAIL_ASC',
  EmailDesc = 'EMAIL_DESC',
  EnterpriseIdAsc = 'ENTERPRISE_ID_ASC',
  EnterpriseIdDesc = 'ENTERPRISE_ID_DESC',
  GeocodeIdAsc = 'GEOCODE_ID_ASC',
  GeocodeIdDesc = 'GEOCODE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  JobTitleAsc = 'JOB_TITLE_ASC',
  JobTitleDesc = 'JOB_TITLE_DESC',
  Natural = 'NATURAL',
  OffersAsc = 'OFFERS_ASC',
  OffersDesc = 'OFFERS_DESC',
  OwnerIdAsc = 'OWNER_ID_ASC',
  OwnerIdDesc = 'OWNER_ID_DESC',
  PhoneNumberAsc = 'PHONE_NUMBER_ASC',
  PhoneNumberDesc = 'PHONE_NUMBER_DESC',
  ProcessExecuteContentModerationCompleteAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_ASC',
  ProcessExecuteContentModerationCompleteDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_DESC',
  ProcessExecuteContentModerationDateAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_ASC',
  ProcessExecuteContentModerationDateDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_DESC',
  ProcessExecuteContentModerationErrorsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_ASC',
  ProcessExecuteContentModerationErrorsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_DESC',
  ProcessExecuteContentModerationResultsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_ASC',
  ProcessExecuteContentModerationResultsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_DESC',
  ProcessExecuteContentModerationReviewerOverrideAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_ASC',
  ProcessExecuteContentModerationReviewerOverrideDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_DESC',
  ProfileImageUrlAsc = 'PROFILE_IMAGE_URL_ASC',
  ProfileImageUrlDesc = 'PROFILE_IMAGE_URL_DESC',
  SocialFacebookUsernameAsc = 'SOCIAL_FACEBOOK_USERNAME_ASC',
  SocialFacebookUsernameDesc = 'SOCIAL_FACEBOOK_USERNAME_DESC',
  SocialInstagramUsernameAsc = 'SOCIAL_INSTAGRAM_USERNAME_ASC',
  SocialInstagramUsernameDesc = 'SOCIAL_INSTAGRAM_USERNAME_DESC',
  SocialLinkedinUsernameAsc = 'SOCIAL_LINKEDIN_USERNAME_ASC',
  SocialLinkedinUsernameDesc = 'SOCIAL_LINKEDIN_USERNAME_DESC',
  SocialTwitterUsernameAsc = 'SOCIAL_TWITTER_USERNAME_ASC',
  SocialTwitterUsernameDesc = 'SOCIAL_TWITTER_USERNAME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  WebsiteAsc = 'WEBSITE_ASC',
  WebsiteDesc = 'WEBSITE_DESC',
}

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `appId` field. */
  appId?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardBusinessName` field. */
  bcardBusinessName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardEmail` field. */
  bcardEmail?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardJobTitle` field. */
  bcardJobTitle?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardLocationAddress` field. */
  bcardLocationAddress?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardName` field. */
  bcardName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardPhoneNumber` field. */
  bcardPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardSocialFacebookUsername` field. */
  bcardSocialFacebookUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardSocialInstagramUsername` field. */
  bcardSocialInstagramUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardSocialLinkedinUsername` field. */
  bcardSocialLinkedinUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardSocialTwitterUsername` field. */
  bcardSocialTwitterUsername?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `bcardVerified` field. */
  bcardVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `bcardWebsite` field. */
  bcardWebsite?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `birthDate` field. */
  birthDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `computeNumOfSharedInterests` field. */
  computeNumOfSharedInterests?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deleteMyAccountRequestDate` field. */
  deleteMyAccountRequestDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `deleteMyAccountRequested` field. */
  deleteMyAccountRequested?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `deviceNotificationAllowed` field. */
  deviceNotificationAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `deviceNotificationToken` field. */
  deviceNotificationToken?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `deviceOs` field. */
  deviceOs?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `finishedOnboarding` field. */
  finishedOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `fullName` field. */
  fullName?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `homeLocationGeocodeId` field. */
  homeLocationGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `id` field. */
  id?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `isApproved` field. */
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isCompletedTutorial` field. */
  isCompletedTutorial?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isDemo` field. */
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isOnboardingComplete` field. */
  isOnboardingComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isSystemUser` field. */
  isSystemUser?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isTest` field. */
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `isVerified` field. */
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `klaviyoEmailMarketingInfoSynced` field. */
  klaviyoEmailMarketingInfoSynced?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `lastAppOpen` field. */
  lastAppOpen?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `lastKnownLocationGeocodeId` field. */
  lastKnownLocationGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `lastNotificationsViewTimestamp` field. */
  lastNotificationsViewTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `metaJson` field. */
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `myRecommendations` field. */
  myRecommendations?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `myRecommendationsDate` field. */
  myRecommendationsDate?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `onboardingStatus` field. */
  onboardingStatus?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `premiumEnabled` field. */
  premiumEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `premiumEnabledTimestamp` field. */
  premiumEnabledTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `primaryVisibleAccount` field. */
  primaryVisibleAccount?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `prioritizedSortOrder` field. */
  prioritizedSortOrder?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `privacyShowFullNameAll` field. */
  privacyShowFullNameAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowFullNameTrustedGroups` field. */
  privacyShowFullNameTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowFullNameTrustedUsers` field. */
  privacyShowFullNameTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowInterestsAndGroupsAll` field. */
  privacyShowInterestsAndGroupsAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowInterestsAndGroupsTrustedGroups` field. */
  privacyShowInterestsAndGroupsTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowInterestsAndGroupsTrustedUsers` field. */
  privacyShowInterestsAndGroupsTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowProfilePicAll` field. */
  privacyShowProfilePicAll?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowProfilePicTrustedGroups` field. */
  privacyShowProfilePicTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `privacyShowProfilePicTrustedUsers` field. */
  privacyShowProfilePicTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationResults` field. */
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `profileBio` field. */
  profileBio?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `profileImageUrl` field. */
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicAlias` field. */
  publicAlias?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicAliasTsv` field. */
  publicAliasTsv?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `publicLocationViewCity` field. */
  publicLocationViewCity?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `publicLocationViewCountry` field. */
  publicLocationViewCountry?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `publicLocationViewNeighborhood` field. */
  publicLocationViewNeighborhood?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `publicLocationViewStreet` field. */
  publicLocationViewStreet?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `recommendations` field. */
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  /** Checks for equality with the object’s `referralCode` field. */
  referralCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `referredBy` field. */
  referredBy?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `referredCode` field. */
  referredCode?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `registrationId` field. */
  registrationId?: InputMaybe<Scalars['BigInt']['input']>;
  /** Checks for equality with the object’s `settingsDashboardMyElectedOfficialsOnlyFilter` field. */
  settingsDashboardMyElectedOfficialsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsDashboardMyGroupsOnlyFilter` field. */
  settingsDashboardMyGroupsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsDashboardMyInterestsOnlyFilter` field. */
  settingsDashboardMyInterestsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsDashboardMyTrustedUsersOnlyFilter` field. */
  settingsDashboardMyTrustedUsersOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsEnableInterestMatchNotifications` field. */
  settingsEnableInterestMatchNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsLocale` field. */
  settingsLocale?: InputMaybe<Scalars['String']['input']>;
  /** Checks for equality with the object’s `settingsNotificationsOnMyGeoactivityComments` field. */
  settingsNotificationsOnMyGeoactivityComments?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsNotificationsOnMyMentions` field. */
  settingsNotificationsOnMyMentions?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsNotificationsOnMyPrivateMessages` field. */
  settingsNotificationsOnMyPrivateMessages?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `settingsNotificationsOnSafetyAlertsNearby` field. */
  settingsNotificationsOnSafetyAlertsNearby?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `syncMarketingInfoComplete` field. */
  syncMarketingInfoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `termsOfUseAndPrivacyConfirmed` field. */
  termsOfUseAndPrivacyConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  /** Checks for equality with the object’s `termsOfUseAndPrivacyConfirmedDate` field. */
  termsOfUseAndPrivacyConfirmedDate?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `unreadPrivateMessageCount` field. */
  unreadPrivateMessageCount?: InputMaybe<Scalars['Int']['input']>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  /** Checks for equality with the object’s `uuid` field. */
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `appId` field. */
  appId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardBusinessName` field. */
  bcardBusinessName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardEmail` field. */
  bcardEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardJobTitle` field. */
  bcardJobTitle?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardLocationAddress` field. */
  bcardLocationAddress?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardName` field. */
  bcardName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardPhoneNumber` field. */
  bcardPhoneNumber?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardSocialFacebookUsername` field. */
  bcardSocialFacebookUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardSocialInstagramUsername` field. */
  bcardSocialInstagramUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardSocialLinkedinUsername` field. */
  bcardSocialLinkedinUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardSocialTwitterUsername` field. */
  bcardSocialTwitterUsername?: InputMaybe<StringFilter>;
  /** Filter by the object’s `bcardVerified` field. */
  bcardVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `bcardWebsite` field. */
  bcardWebsite?: InputMaybe<StringFilter>;
  /** Filter by the object’s `birthDate` field. */
  birthDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `computeNumOfSharedInterests` field. */
  computeNumOfSharedInterests?: InputMaybe<IntFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleteMyAccountRequestDate` field. */
  deleteMyAccountRequestDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `deleteMyAccountRequested` field. */
  deleteMyAccountRequested?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `deviceNotificationAllowed` field. */
  deviceNotificationAllowed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `deviceNotificationToken` field. */
  deviceNotificationToken?: InputMaybe<StringFilter>;
  /** Filter by the object’s `deviceOs` field. */
  deviceOs?: InputMaybe<StringFilter>;
  /** Filter by the object’s `finishedOnboarding` field. */
  finishedOnboarding?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `fullName` field. */
  fullName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `homeLocationGeocodeId` field. */
  homeLocationGeocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `id` field. */
  id?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `internalDataopsTag` field. */
  internalDataopsTag?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isApproved` field. */
  isApproved?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isCompletedTutorial` field. */
  isCompletedTutorial?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDeleted` field. */
  isDeleted?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isDemo` field. */
  isDemo?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isOnboardingComplete` field. */
  isOnboardingComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isPrivate` field. */
  isPrivate?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isSystemUser` field. */
  isSystemUser?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isTest` field. */
  isTest?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isVerified` field. */
  isVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `klaviyoEmailMarketingInfoSynced` field. */
  klaviyoEmailMarketingInfoSynced?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `lastAppOpen` field. */
  lastAppOpen?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastKnownLocationGeocodeId` field. */
  lastKnownLocationGeocodeId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `lastNotificationsViewTimestamp` field. */
  lastNotificationsViewTimestamp?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Filter by the object’s `onboardingStatus` field. */
  onboardingStatus?: InputMaybe<StringFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `premiumEnabled` field. */
  premiumEnabled?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `premiumEnabledTimestamp` field. */
  premiumEnabledTimestamp?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `primaryVisibleAccount` field. */
  primaryVisibleAccount?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `prioritizedSortOrder` field. */
  prioritizedSortOrder?: InputMaybe<IntFilter>;
  /** Filter by the object’s `privacyShowFullNameAll` field. */
  privacyShowFullNameAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowFullNameTrustedGroups` field. */
  privacyShowFullNameTrustedGroups?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowFullNameTrustedUsers` field. */
  privacyShowFullNameTrustedUsers?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowInterestsAndGroupsAll` field. */
  privacyShowInterestsAndGroupsAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowInterestsAndGroupsTrustedGroups` field. */
  privacyShowInterestsAndGroupsTrustedGroups?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowInterestsAndGroupsTrustedUsers` field. */
  privacyShowInterestsAndGroupsTrustedUsers?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowProfilePicAll` field. */
  privacyShowProfilePicAll?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowProfilePicTrustedGroups` field. */
  privacyShowProfilePicTrustedGroups?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `privacyShowProfilePicTrustedUsers` field. */
  privacyShowProfilePicTrustedUsers?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationComplete` field. */
  processExecuteContentModerationComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `processExecuteContentModerationDate` field. */
  processExecuteContentModerationDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `processExecuteContentModerationErrors` field. */
  processExecuteContentModerationErrors?: InputMaybe<StringFilter>;
  /** Filter by the object’s `processExecuteContentModerationReviewerOverride` field. */
  processExecuteContentModerationReviewerOverride?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `profileBio` field. */
  profileBio?: InputMaybe<StringFilter>;
  /** Filter by the object’s `profileImageUrl` field. */
  profileImageUrl?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicAlias` field. */
  publicAlias?: InputMaybe<StringFilter>;
  /** Filter by the object’s `publicLocationViewCity` field. */
  publicLocationViewCity?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `publicLocationViewCountry` field. */
  publicLocationViewCountry?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `publicLocationViewNeighborhood` field. */
  publicLocationViewNeighborhood?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `publicLocationViewStreet` field. */
  publicLocationViewStreet?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `referralCode` field. */
  referralCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `referredBy` field. */
  referredBy?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `referredCode` field. */
  referredCode?: InputMaybe<StringFilter>;
  /** Filter by the object’s `registrationId` field. */
  registrationId?: InputMaybe<BigIntFilter>;
  /** Filter by the object’s `settingsDashboardMyElectedOfficialsOnlyFilter` field. */
  settingsDashboardMyElectedOfficialsOnlyFilter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsDashboardMyGroupsOnlyFilter` field. */
  settingsDashboardMyGroupsOnlyFilter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsDashboardMyInterestsOnlyFilter` field. */
  settingsDashboardMyInterestsOnlyFilter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsDashboardMyTrustedUsersOnlyFilter` field. */
  settingsDashboardMyTrustedUsersOnlyFilter?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsEnableInterestMatchNotifications` field. */
  settingsEnableInterestMatchNotifications?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsLocale` field. */
  settingsLocale?: InputMaybe<StringFilter>;
  /** Filter by the object’s `settingsNotificationsOnMyGeoactivityComments` field. */
  settingsNotificationsOnMyGeoactivityComments?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsNotificationsOnMyMentions` field. */
  settingsNotificationsOnMyMentions?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsNotificationsOnMyPrivateMessages` field. */
  settingsNotificationsOnMyPrivateMessages?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `settingsNotificationsOnSafetyAlertsNearby` field. */
  settingsNotificationsOnSafetyAlertsNearby?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `syncMarketingInfoComplete` field. */
  syncMarketingInfoComplete?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `termsOfUseAndPrivacyConfirmed` field. */
  termsOfUseAndPrivacyConfirmed?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `termsOfUseAndPrivacyConfirmedDate` field. */
  termsOfUseAndPrivacyConfirmedDate?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `unreadPrivateMessageCount` field. */
  unreadPrivateMessageCount?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `uuid` field. */
  uuid?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  appId?: InputMaybe<Scalars['String']['input']>;
  bcardBusinessName?: InputMaybe<Scalars['String']['input']>;
  bcardEmail?: InputMaybe<Scalars['String']['input']>;
  bcardJobTitle?: InputMaybe<Scalars['String']['input']>;
  bcardLocationAddress?: InputMaybe<Scalars['String']['input']>;
  bcardName?: InputMaybe<Scalars['String']['input']>;
  bcardPhoneNumber?: InputMaybe<Scalars['String']['input']>;
  bcardSocialFacebookUsername?: InputMaybe<Scalars['String']['input']>;
  bcardSocialInstagramUsername?: InputMaybe<Scalars['String']['input']>;
  bcardSocialLinkedinUsername?: InputMaybe<Scalars['String']['input']>;
  bcardSocialTwitterUsername?: InputMaybe<Scalars['String']['input']>;
  bcardVerified?: InputMaybe<Scalars['Boolean']['input']>;
  bcardWebsite?: InputMaybe<Scalars['String']['input']>;
  birthDate?: InputMaybe<Scalars['Datetime']['input']>;
  computeNumOfSharedInterests?: InputMaybe<Scalars['Int']['input']>;
  createdAt?: InputMaybe<Scalars['Datetime']['input']>;
  deleteMyAccountRequestDate?: InputMaybe<Scalars['Datetime']['input']>;
  deleteMyAccountRequested?: InputMaybe<Scalars['Boolean']['input']>;
  deviceNotificationAllowed?: InputMaybe<Scalars['Boolean']['input']>;
  deviceNotificationToken?: InputMaybe<Scalars['String']['input']>;
  deviceOs?: InputMaybe<Scalars['String']['input']>;
  finishedOnboarding?: InputMaybe<Scalars['Boolean']['input']>;
  fullName?: InputMaybe<Scalars['String']['input']>;
  homeLocationGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  id?: InputMaybe<Scalars['BigInt']['input']>;
  internalDataopsTag?: InputMaybe<Scalars['String']['input']>;
  isApproved?: InputMaybe<Scalars['Boolean']['input']>;
  isCompletedTutorial?: InputMaybe<Scalars['Boolean']['input']>;
  isDeleted?: InputMaybe<Scalars['Boolean']['input']>;
  isDemo?: InputMaybe<Scalars['Boolean']['input']>;
  isOnboardingComplete?: InputMaybe<Scalars['Boolean']['input']>;
  isPrivate?: InputMaybe<Scalars['Boolean']['input']>;
  isSystemUser?: InputMaybe<Scalars['Boolean']['input']>;
  isTest?: InputMaybe<Scalars['Boolean']['input']>;
  isVerified?: InputMaybe<Scalars['Boolean']['input']>;
  klaviyoEmailMarketingInfoSynced?: InputMaybe<Scalars['Boolean']['input']>;
  lastAppOpen?: InputMaybe<Scalars['Datetime']['input']>;
  lastKnownLocationGeocodeId?: InputMaybe<Scalars['BigInt']['input']>;
  lastNotificationsViewTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  metaJson?: InputMaybe<Scalars['JSON']['input']>;
  myRecommendations?: InputMaybe<Scalars['JSON']['input']>;
  myRecommendationsDate?: InputMaybe<Scalars['JSON']['input']>;
  onboardingStatus?: InputMaybe<Scalars['String']['input']>;
  premiumEnabled?: InputMaybe<Scalars['Boolean']['input']>;
  premiumEnabledTimestamp?: InputMaybe<Scalars['Datetime']['input']>;
  primaryVisibleAccount?: InputMaybe<Scalars['Boolean']['input']>;
  prioritizedSortOrder?: InputMaybe<Scalars['Int']['input']>;
  privacyShowFullNameAll?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowFullNameTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowFullNameTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowInterestsAndGroupsAll?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowInterestsAndGroupsTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowInterestsAndGroupsTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowProfilePicAll?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowProfilePicTrustedGroups?: InputMaybe<Scalars['Boolean']['input']>;
  privacyShowProfilePicTrustedUsers?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationComplete?: InputMaybe<Scalars['Boolean']['input']>;
  processExecuteContentModerationDate?: InputMaybe<Scalars['Datetime']['input']>;
  processExecuteContentModerationErrors?: InputMaybe<Scalars['String']['input']>;
  processExecuteContentModerationResults?: InputMaybe<Scalars['JSON']['input']>;
  processExecuteContentModerationReviewerOverride?: InputMaybe<Scalars['Boolean']['input']>;
  profileBio?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  publicAlias?: InputMaybe<Scalars['String']['input']>;
  publicAliasTsv?: InputMaybe<Scalars['String']['input']>;
  publicLocationViewCity?: InputMaybe<Scalars['Boolean']['input']>;
  publicLocationViewCountry?: InputMaybe<Scalars['Boolean']['input']>;
  publicLocationViewNeighborhood?: InputMaybe<Scalars['Boolean']['input']>;
  publicLocationViewStreet?: InputMaybe<Scalars['Boolean']['input']>;
  recommendations?: InputMaybe<Scalars['JSON']['input']>;
  referralCode?: InputMaybe<Scalars['String']['input']>;
  referredBy?: InputMaybe<Scalars['BigInt']['input']>;
  referredCode?: InputMaybe<Scalars['String']['input']>;
  registrationId?: InputMaybe<Scalars['BigInt']['input']>;
  settingsDashboardMyElectedOfficialsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  settingsDashboardMyGroupsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  settingsDashboardMyInterestsOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  settingsDashboardMyTrustedUsersOnlyFilter?: InputMaybe<Scalars['Boolean']['input']>;
  settingsEnableInterestMatchNotifications?: InputMaybe<Scalars['Boolean']['input']>;
  settingsLocale?: InputMaybe<Scalars['String']['input']>;
  settingsNotificationsOnMyGeoactivityComments?: InputMaybe<Scalars['Boolean']['input']>;
  settingsNotificationsOnMyMentions?: InputMaybe<Scalars['Boolean']['input']>;
  settingsNotificationsOnMyPrivateMessages?: InputMaybe<Scalars['Boolean']['input']>;
  settingsNotificationsOnSafetyAlertsNearby?: InputMaybe<Scalars['Boolean']['input']>;
  syncMarketingInfoComplete?: InputMaybe<Scalars['Boolean']['input']>;
  termsOfUseAndPrivacyConfirmed?: InputMaybe<Scalars['Boolean']['input']>;
  termsOfUseAndPrivacyConfirmedDate?: InputMaybe<Scalars['Datetime']['input']>;
  unreadPrivateMessageCount?: InputMaybe<Scalars['Int']['input']>;
  updatedAt?: InputMaybe<Scalars['Datetime']['input']>;
  uuid?: InputMaybe<Scalars['UUID']['input']>;
};
export type UserLocationLevelAccess = {
  __typename?: 'UserLocationLevelAccess';
  userHomeLocation?: Maybe<Scalars['String']['output']>;
  userLastKnownLocation?: Maybe<Scalars['String']['output']>;
  userLocationLevel?: Maybe<Scalars['String']['output']>;
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: 'UsersConnection';
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** A list of `User` objects. */
  nodes: Array<User>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars['Int']['output'];
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: 'UsersEdge';
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars['Cursor']['output']>;
  /** The `User` at the end of the edge. */
  node: User;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  AppIdAsc = 'APP_ID_ASC',
  AppIdDesc = 'APP_ID_DESC',
  BcardBusinessNameAsc = 'BCARD_BUSINESS_NAME_ASC',
  BcardBusinessNameDesc = 'BCARD_BUSINESS_NAME_DESC',
  BcardEmailAsc = 'BCARD_EMAIL_ASC',
  BcardEmailDesc = 'BCARD_EMAIL_DESC',
  BcardJobTitleAsc = 'BCARD_JOB_TITLE_ASC',
  BcardJobTitleDesc = 'BCARD_JOB_TITLE_DESC',
  BcardLocationAddressAsc = 'BCARD_LOCATION_ADDRESS_ASC',
  BcardLocationAddressDesc = 'BCARD_LOCATION_ADDRESS_DESC',
  BcardNameAsc = 'BCARD_NAME_ASC',
  BcardNameDesc = 'BCARD_NAME_DESC',
  BcardPhoneNumberAsc = 'BCARD_PHONE_NUMBER_ASC',
  BcardPhoneNumberDesc = 'BCARD_PHONE_NUMBER_DESC',
  BcardSocialFacebookUsernameAsc = 'BCARD_SOCIAL_FACEBOOK_USERNAME_ASC',
  BcardSocialFacebookUsernameDesc = 'BCARD_SOCIAL_FACEBOOK_USERNAME_DESC',
  BcardSocialInstagramUsernameAsc = 'BCARD_SOCIAL_INSTAGRAM_USERNAME_ASC',
  BcardSocialInstagramUsernameDesc = 'BCARD_SOCIAL_INSTAGRAM_USERNAME_DESC',
  BcardSocialLinkedinUsernameAsc = 'BCARD_SOCIAL_LINKEDIN_USERNAME_ASC',
  BcardSocialLinkedinUsernameDesc = 'BCARD_SOCIAL_LINKEDIN_USERNAME_DESC',
  BcardSocialTwitterUsernameAsc = 'BCARD_SOCIAL_TWITTER_USERNAME_ASC',
  BcardSocialTwitterUsernameDesc = 'BCARD_SOCIAL_TWITTER_USERNAME_DESC',
  BcardVerifiedAsc = 'BCARD_VERIFIED_ASC',
  BcardVerifiedDesc = 'BCARD_VERIFIED_DESC',
  BcardWebsiteAsc = 'BCARD_WEBSITE_ASC',
  BcardWebsiteDesc = 'BCARD_WEBSITE_DESC',
  BirthDateAsc = 'BIRTH_DATE_ASC',
  BirthDateDesc = 'BIRTH_DATE_DESC',
  ComputeNumOfSharedInterestsAsc = 'COMPUTE_NUM_OF_SHARED_INTERESTS_ASC',
  ComputeNumOfSharedInterestsDesc = 'COMPUTE_NUM_OF_SHARED_INTERESTS_DESC',
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  DeleteMyAccountRequestedAsc = 'DELETE_MY_ACCOUNT_REQUESTED_ASC',
  DeleteMyAccountRequestedDesc = 'DELETE_MY_ACCOUNT_REQUESTED_DESC',
  DeleteMyAccountRequestDateAsc = 'DELETE_MY_ACCOUNT_REQUEST_DATE_ASC',
  DeleteMyAccountRequestDateDesc = 'DELETE_MY_ACCOUNT_REQUEST_DATE_DESC',
  DeviceNotificationAllowedAsc = 'DEVICE_NOTIFICATION_ALLOWED_ASC',
  DeviceNotificationAllowedDesc = 'DEVICE_NOTIFICATION_ALLOWED_DESC',
  DeviceNotificationTokenAsc = 'DEVICE_NOTIFICATION_TOKEN_ASC',
  DeviceNotificationTokenDesc = 'DEVICE_NOTIFICATION_TOKEN_DESC',
  DeviceOsAsc = 'DEVICE_OS_ASC',
  DeviceOsDesc = 'DEVICE_OS_DESC',
  FinishedOnboardingAsc = 'FINISHED_ONBOARDING_ASC',
  FinishedOnboardingDesc = 'FINISHED_ONBOARDING_DESC',
  FullNameAsc = 'FULL_NAME_ASC',
  FullNameDesc = 'FULL_NAME_DESC',
  HomeLocationGeocodeIdAsc = 'HOME_LOCATION_GEOCODE_ID_ASC',
  HomeLocationGeocodeIdDesc = 'HOME_LOCATION_GEOCODE_ID_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  InternalDataopsTagAsc = 'INTERNAL_DATAOPS_TAG_ASC',
  InternalDataopsTagDesc = 'INTERNAL_DATAOPS_TAG_DESC',
  IsApprovedAsc = 'IS_APPROVED_ASC',
  IsApprovedDesc = 'IS_APPROVED_DESC',
  IsCompletedTutorialAsc = 'IS_COMPLETED_TUTORIAL_ASC',
  IsCompletedTutorialDesc = 'IS_COMPLETED_TUTORIAL_DESC',
  IsDeletedAsc = 'IS_DELETED_ASC',
  IsDeletedDesc = 'IS_DELETED_DESC',
  IsDemoAsc = 'IS_DEMO_ASC',
  IsDemoDesc = 'IS_DEMO_DESC',
  IsOnboardingCompleteAsc = 'IS_ONBOARDING_COMPLETE_ASC',
  IsOnboardingCompleteDesc = 'IS_ONBOARDING_COMPLETE_DESC',
  IsPrivateAsc = 'IS_PRIVATE_ASC',
  IsPrivateDesc = 'IS_PRIVATE_DESC',
  IsSystemUserAsc = 'IS_SYSTEM_USER_ASC',
  IsSystemUserDesc = 'IS_SYSTEM_USER_DESC',
  IsTestAsc = 'IS_TEST_ASC',
  IsTestDesc = 'IS_TEST_DESC',
  IsVerifiedAsc = 'IS_VERIFIED_ASC',
  IsVerifiedDesc = 'IS_VERIFIED_DESC',
  KlaviyoEmailMarketingInfoSyncedAsc = 'KLAVIYO_EMAIL_MARKETING_INFO_SYNCED_ASC',
  KlaviyoEmailMarketingInfoSyncedDesc = 'KLAVIYO_EMAIL_MARKETING_INFO_SYNCED_DESC',
  LastAppOpenAsc = 'LAST_APP_OPEN_ASC',
  LastAppOpenDesc = 'LAST_APP_OPEN_DESC',
  LastKnownLocationGeocodeIdAsc = 'LAST_KNOWN_LOCATION_GEOCODE_ID_ASC',
  LastKnownLocationGeocodeIdDesc = 'LAST_KNOWN_LOCATION_GEOCODE_ID_DESC',
  LastNotificationsViewTimestampAsc = 'LAST_NOTIFICATIONS_VIEW_TIMESTAMP_ASC',
  LastNotificationsViewTimestampDesc = 'LAST_NOTIFICATIONS_VIEW_TIMESTAMP_DESC',
  MetaJsonAsc = 'META_JSON_ASC',
  MetaJsonDesc = 'META_JSON_DESC',
  MyRecommendationsAsc = 'MY_RECOMMENDATIONS_ASC',
  MyRecommendationsDateAsc = 'MY_RECOMMENDATIONS_DATE_ASC',
  MyRecommendationsDateDesc = 'MY_RECOMMENDATIONS_DATE_DESC',
  MyRecommendationsDesc = 'MY_RECOMMENDATIONS_DESC',
  Natural = 'NATURAL',
  OnboardingStatusAsc = 'ONBOARDING_STATUS_ASC',
  OnboardingStatusDesc = 'ONBOARDING_STATUS_DESC',
  PremiumEnabledAsc = 'PREMIUM_ENABLED_ASC',
  PremiumEnabledDesc = 'PREMIUM_ENABLED_DESC',
  PremiumEnabledTimestampAsc = 'PREMIUM_ENABLED_TIMESTAMP_ASC',
  PremiumEnabledTimestampDesc = 'PREMIUM_ENABLED_TIMESTAMP_DESC',
  PrimaryVisibleAccountAsc = 'PRIMARY_VISIBLE_ACCOUNT_ASC',
  PrimaryVisibleAccountDesc = 'PRIMARY_VISIBLE_ACCOUNT_DESC',
  PrioritizedSortOrderAsc = 'PRIORITIZED_SORT_ORDER_ASC',
  PrioritizedSortOrderDesc = 'PRIORITIZED_SORT_ORDER_DESC',
  PrivacyShowFullNameAllAsc = 'PRIVACY_SHOW_FULL_NAME_ALL_ASC',
  PrivacyShowFullNameAllDesc = 'PRIVACY_SHOW_FULL_NAME_ALL_DESC',
  PrivacyShowFullNameTrustedGroupsAsc = 'PRIVACY_SHOW_FULL_NAME_TRUSTED_GROUPS_ASC',
  PrivacyShowFullNameTrustedGroupsDesc = 'PRIVACY_SHOW_FULL_NAME_TRUSTED_GROUPS_DESC',
  PrivacyShowFullNameTrustedUsersAsc = 'PRIVACY_SHOW_FULL_NAME_TRUSTED_USERS_ASC',
  PrivacyShowFullNameTrustedUsersDesc = 'PRIVACY_SHOW_FULL_NAME_TRUSTED_USERS_DESC',
  PrivacyShowInterestsAndGroupsAllAsc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_ALL_ASC',
  PrivacyShowInterestsAndGroupsAllDesc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_ALL_DESC',
  PrivacyShowInterestsAndGroupsTrustedGroupsAsc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_TRUSTED_GROUPS_ASC',
  PrivacyShowInterestsAndGroupsTrustedGroupsDesc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_TRUSTED_GROUPS_DESC',
  PrivacyShowInterestsAndGroupsTrustedUsersAsc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_TRUSTED_USERS_ASC',
  PrivacyShowInterestsAndGroupsTrustedUsersDesc = 'PRIVACY_SHOW_INTERESTS_AND_GROUPS_TRUSTED_USERS_DESC',
  PrivacyShowProfilePicAllAsc = 'PRIVACY_SHOW_PROFILE_PIC_ALL_ASC',
  PrivacyShowProfilePicAllDesc = 'PRIVACY_SHOW_PROFILE_PIC_ALL_DESC',
  PrivacyShowProfilePicTrustedGroupsAsc = 'PRIVACY_SHOW_PROFILE_PIC_TRUSTED_GROUPS_ASC',
  PrivacyShowProfilePicTrustedGroupsDesc = 'PRIVACY_SHOW_PROFILE_PIC_TRUSTED_GROUPS_DESC',
  PrivacyShowProfilePicTrustedUsersAsc = 'PRIVACY_SHOW_PROFILE_PIC_TRUSTED_USERS_ASC',
  PrivacyShowProfilePicTrustedUsersDesc = 'PRIVACY_SHOW_PROFILE_PIC_TRUSTED_USERS_DESC',
  ProcessExecuteContentModerationCompleteAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_ASC',
  ProcessExecuteContentModerationCompleteDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_COMPLETE_DESC',
  ProcessExecuteContentModerationDateAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_ASC',
  ProcessExecuteContentModerationDateDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_DATE_DESC',
  ProcessExecuteContentModerationErrorsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_ASC',
  ProcessExecuteContentModerationErrorsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_ERRORS_DESC',
  ProcessExecuteContentModerationResultsAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_ASC',
  ProcessExecuteContentModerationResultsDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_RESULTS_DESC',
  ProcessExecuteContentModerationReviewerOverrideAsc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_ASC',
  ProcessExecuteContentModerationReviewerOverrideDesc = 'PROCESS_EXECUTE_CONTENT_MODERATION_REVIEWER_OVERRIDE_DESC',
  ProfileBioAsc = 'PROFILE_BIO_ASC',
  ProfileBioDesc = 'PROFILE_BIO_DESC',
  ProfileImageUrlAsc = 'PROFILE_IMAGE_URL_ASC',
  ProfileImageUrlDesc = 'PROFILE_IMAGE_URL_DESC',
  PublicAliasAsc = 'PUBLIC_ALIAS_ASC',
  PublicAliasDesc = 'PUBLIC_ALIAS_DESC',
  PublicAliasTsvAsc = 'PUBLIC_ALIAS_TSV_ASC',
  PublicAliasTsvDesc = 'PUBLIC_ALIAS_TSV_DESC',
  PublicLocationViewCityAsc = 'PUBLIC_LOCATION_VIEW_CITY_ASC',
  PublicLocationViewCityDesc = 'PUBLIC_LOCATION_VIEW_CITY_DESC',
  PublicLocationViewCountryAsc = 'PUBLIC_LOCATION_VIEW_COUNTRY_ASC',
  PublicLocationViewCountryDesc = 'PUBLIC_LOCATION_VIEW_COUNTRY_DESC',
  PublicLocationViewNeighborhoodAsc = 'PUBLIC_LOCATION_VIEW_NEIGHBORHOOD_ASC',
  PublicLocationViewNeighborhoodDesc = 'PUBLIC_LOCATION_VIEW_NEIGHBORHOOD_DESC',
  PublicLocationViewStreetAsc = 'PUBLIC_LOCATION_VIEW_STREET_ASC',
  PublicLocationViewStreetDesc = 'PUBLIC_LOCATION_VIEW_STREET_DESC',
  RecommendationsAsc = 'RECOMMENDATIONS_ASC',
  RecommendationsDesc = 'RECOMMENDATIONS_DESC',
  ReferralCodeAsc = 'REFERRAL_CODE_ASC',
  ReferralCodeDesc = 'REFERRAL_CODE_DESC',
  ReferredByAsc = 'REFERRED_BY_ASC',
  ReferredByDesc = 'REFERRED_BY_DESC',
  ReferredCodeAsc = 'REFERRED_CODE_ASC',
  ReferredCodeDesc = 'REFERRED_CODE_DESC',
  RegistrationIdAsc = 'REGISTRATION_ID_ASC',
  RegistrationIdDesc = 'REGISTRATION_ID_DESC',
  SettingsDashboardMyElectedOfficialsOnlyFilterAsc = 'SETTINGS_DASHBOARD_MY_ELECTED_OFFICIALS_ONLY_FILTER_ASC',
  SettingsDashboardMyElectedOfficialsOnlyFilterDesc = 'SETTINGS_DASHBOARD_MY_ELECTED_OFFICIALS_ONLY_FILTER_DESC',
  SettingsDashboardMyGroupsOnlyFilterAsc = 'SETTINGS_DASHBOARD_MY_GROUPS_ONLY_FILTER_ASC',
  SettingsDashboardMyGroupsOnlyFilterDesc = 'SETTINGS_DASHBOARD_MY_GROUPS_ONLY_FILTER_DESC',
  SettingsDashboardMyInterestsOnlyFilterAsc = 'SETTINGS_DASHBOARD_MY_INTERESTS_ONLY_FILTER_ASC',
  SettingsDashboardMyInterestsOnlyFilterDesc = 'SETTINGS_DASHBOARD_MY_INTERESTS_ONLY_FILTER_DESC',
  SettingsDashboardMyTrustedUsersOnlyFilterAsc = 'SETTINGS_DASHBOARD_MY_TRUSTED_USERS_ONLY_FILTER_ASC',
  SettingsDashboardMyTrustedUsersOnlyFilterDesc = 'SETTINGS_DASHBOARD_MY_TRUSTED_USERS_ONLY_FILTER_DESC',
  SettingsEnableInterestMatchNotificationsAsc = 'SETTINGS_ENABLE_INTEREST_MATCH_NOTIFICATIONS_ASC',
  SettingsEnableInterestMatchNotificationsDesc = 'SETTINGS_ENABLE_INTEREST_MATCH_NOTIFICATIONS_DESC',
  SettingsLocaleAsc = 'SETTINGS_LOCALE_ASC',
  SettingsLocaleDesc = 'SETTINGS_LOCALE_DESC',
  SettingsNotificationsOnMyGeoactivityCommentsAsc = 'SETTINGS_NOTIFICATIONS_ON_MY_GEOACTIVITY_COMMENTS_ASC',
  SettingsNotificationsOnMyGeoactivityCommentsDesc = 'SETTINGS_NOTIFICATIONS_ON_MY_GEOACTIVITY_COMMENTS_DESC',
  SettingsNotificationsOnMyMentionsAsc = 'SETTINGS_NOTIFICATIONS_ON_MY_MENTIONS_ASC',
  SettingsNotificationsOnMyMentionsDesc = 'SETTINGS_NOTIFICATIONS_ON_MY_MENTIONS_DESC',
  SettingsNotificationsOnMyPrivateMessagesAsc = 'SETTINGS_NOTIFICATIONS_ON_MY_PRIVATE_MESSAGES_ASC',
  SettingsNotificationsOnMyPrivateMessagesDesc = 'SETTINGS_NOTIFICATIONS_ON_MY_PRIVATE_MESSAGES_DESC',
  SettingsNotificationsOnSafetyAlertsNearbyAsc = 'SETTINGS_NOTIFICATIONS_ON_SAFETY_ALERTS_NEARBY_ASC',
  SettingsNotificationsOnSafetyAlertsNearbyDesc = 'SETTINGS_NOTIFICATIONS_ON_SAFETY_ALERTS_NEARBY_DESC',
  SyncMarketingInfoCompleteAsc = 'SYNC_MARKETING_INFO_COMPLETE_ASC',
  SyncMarketingInfoCompleteDesc = 'SYNC_MARKETING_INFO_COMPLETE_DESC',
  TermsOfUseAndPrivacyConfirmedAsc = 'TERMS_OF_USE_AND_PRIVACY_CONFIRMED_ASC',
  TermsOfUseAndPrivacyConfirmedDateAsc = 'TERMS_OF_USE_AND_PRIVACY_CONFIRMED_DATE_ASC',
  TermsOfUseAndPrivacyConfirmedDateDesc = 'TERMS_OF_USE_AND_PRIVACY_CONFIRMED_DATE_DESC',
  TermsOfUseAndPrivacyConfirmedDesc = 'TERMS_OF_USE_AND_PRIVACY_CONFIRMED_DESC',
  UnreadPrivateMessageCountAsc = 'UNREAD_PRIVATE_MESSAGE_COUNT_ASC',
  UnreadPrivateMessageCountDesc = 'UNREAD_PRIVATE_MESSAGE_COUNT_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
  UuidAsc = 'UUID_ASC',
  UuidDesc = 'UUID_DESC',
}
export type GetAnonymousViewMapDataQueryVariables = Exact<{
  boundary: Boundary;
}>;
export type GetAnonymousViewMapDataQuery = {
  __typename?: 'Query';
  getAnonMapFeed?: {
    __typename?: 'AnonMapFeed';
    interests?: any | null;
    places?: any | null;
    geoDrops?: any | null;
    geoContexts?: any | null;
  } | null;
};
export type GetAnonGeoActivityByActivityIdQueryVariables = Exact<{
  id: Scalars['BigInt']['input'];
}>;
export type GetAnonGeoActivityByActivityIdQuery = {
  __typename?: 'Query';
  geoActivities?: {
    __typename?: 'GeoActivitiesConnection';
    nodes: Array<{
      __typename?: 'GeoActivity';
      id?: any | null;
      type?: EnumGeoActivitiesType | null;
      likeCount?: number | null;
      commentCount?: number | null;
      upVoteCount?: number | null;
      downVoteCount?: number | null;
      status?: string | null;
      label?: string | null;
      description?: string | null;
      ownerRequirePassword?: string | null;
      messageBody?: string | null;
      messageSubject?: string | null;
      refImageUrl?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      geocodeId?: any | null;
      geocodeTimestamp?: any | null;
      relatedToGeocodeJson?: any | null;
      isPublic?: boolean | null;
      ownerId?: any | null;
      showBusinessCard?: boolean | null;
      userBusinessCardId?: any | null;
      visibility?: boolean | null;
      visibilityDuration?: number | null;
      visibilityLabel?: string | null;
      visibilityLevel?: string | null;
      visibilityStartDate?: any | null;
      visibilityEndDate?: any | null;
      displayStartDate?: any | null;
      displayEndDate?: any | null;
      appEmojiId?: any | null;
      appBadgeId?: any | null;
      appDropCategoryId?: any | null;
      refUrl?: string | null;
      isExternalSourced?: boolean | null;
      externalSource?: string | null;
      externalSourceId?: string | null;
      businessOfferLink?: string | null;
      isPromoted?: boolean | null;
      currentPromotionStartDate?: any | null;
      currentPromotionEndDate?: any | null;
      currentPromotionTotalViews?: number | null;
      totalViews?: number | null;
      geocastRelativePosition?: boolean | null;
      isOffer?: boolean | null;
      isPopular?: boolean | null;
      ownerRequireProximityDistance?: number | null;
      metaJson?: any | null;
      owner?: {
        __typename?: 'User';
        id?: any | null;
        publicAlias?: string | null;
        profileImageUrl?: string | null;
        fullName?: string | null;
      } | null;
      geocode?: {
        __typename?: 'GeoCode';
        id?: any | null;
        name?: string | null;
        type?: EnumGeoCodesType | null;
        locationInfoAddressLine1?: string | null;
        socialCommentCount?: number | null;
        placeInfoDescription?: string | null;
        placeInfoHours?: string | null;
        placeInfoWebsite?: string | null;
        placeInfoPhoneNumber?: string | null;
        socialLikeCount?: number | null;
        ownerId?: any | null;
        ownerAccountId?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        locationCoordinates?: {
          __typename?: 'GeometryPoint';
          latitude: number;
          longitude: number;
        } | null;
      } | null;
      appEmoji?: {
        __typename?: 'AppEmoji';
        id?: any | null;
        name?: string | null;
        emoji?: string | null;
      } | null;
      appBadge?: {
        __typename?: 'AppBadge';
        id?: any | null;
        name?: string | null;
        iconUrl?: string | null;
      } | null;
      appDropCategory?: {
        __typename?: 'AppDropCategory';
        id?: any | null;
        name?: string | null;
        iconUrl?: string | null;
      } | null;
    }>;
  } | null;
};
export type SearchGeoActivitiesQueryVariables = Exact<{
  limit: Scalars['Int']['input'];
  offset: Scalars['Int']['input'];
  sharingAndFilterCriteria: Scalars['String']['input'];
}>;
export type SearchGeoActivitiesQuery = {
  __typename?: 'Query';
  geoActivities?: {
    __typename?: 'GeoActivitiesConnection';
    nodes: Array<{
      __typename?: 'GeoActivity';
      id?: any | null;
      type?: EnumGeoActivitiesType | null;
      likeCount?: number | null;
      commentCount?: number | null;
      upVoteCount?: number | null;
      downVoteCount?: number | null;
      status?: string | null;
      label?: string | null;
      description?: string | null;
      ownerRequirePassword?: string | null;
      messageBody?: string | null;
      messageSubject?: string | null;
      refImageUrl?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      geocodeId?: any | null;
      geocodeTimestamp?: any | null;
      relatedToGeocodeJson?: any | null;
      isPublic?: boolean | null;
      ownerId?: any | null;
      showBusinessCard?: boolean | null;
      userBusinessCardId?: any | null;
      visibility?: boolean | null;
      visibilityDuration?: number | null;
      visibilityLabel?: string | null;
      visibilityLevel?: string | null;
      visibilityStartDate?: any | null;
      visibilityEndDate?: any | null;
      displayStartDate?: any | null;
      displayEndDate?: any | null;
      appEmojiId?: any | null;
      appBadgeId?: any | null;
      appDropCategoryId?: any | null;
      refUrl?: string | null;
      isExternalSourced?: boolean | null;
      externalSource?: string | null;
      externalSourceId?: string | null;
      businessOfferLink?: string | null;
      isPromoted?: boolean | null;
      currentPromotionStartDate?: any | null;
      currentPromotionEndDate?: any | null;
      currentPromotionTotalViews?: number | null;
      totalViews?: number | null;
      geocastRelativePosition?: boolean | null;
      isOffer?: boolean | null;
      isPopular?: boolean | null;
      ownerRequireProximityDistance?: number | null;
      metaJson?: any | null;
      owner?: {
        __typename?: 'User';
        id?: any | null;
        publicAlias?: string | null;
        profileImageUrl?: string | null;
        fullName?: string | null;
      } | null;
      geocode?: {
        __typename?: 'GeoCode';
        id?: any | null;
        name?: string | null;
        type?: EnumGeoCodesType | null;
        locationInfoAddressLine1?: string | null;
        socialCommentCount?: number | null;
        placeInfoDescription?: string | null;
        placeInfoHours?: string | null;
        placeInfoWebsite?: string | null;
        placeInfoPhoneNumber?: string | null;
        socialLikeCount?: number | null;
        ownerId?: any | null;
        ownerAccountId?: any | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        locationCoordinates?: {
          __typename?: 'GeometryPoint';
          latitude: number;
          longitude: number;
        } | null;
      } | null;
      appEmoji?: {
        __typename?: 'AppEmoji';
        id?: any | null;
        name?: string | null;
        emoji?: string | null;
      } | null;
      appBadge?: {
        __typename?: 'AppBadge';
        id?: any | null;
        name?: string | null;
        iconUrl?: string | null;
      } | null;
      appDropCategory?: {
        __typename?: 'AppDropCategory';
        id?: any | null;
        name?: string | null;
        iconUrl?: string | null;
      } | null;
    }>;
    pageInfo: {
      __typename?: 'PageInfo';
      hasNextPage: boolean;
      hasPreviousPage: boolean;
      startCursor?: any | null;
      endCursor?: any | null;
    };
  } | null;
};
export const GetAnonymousViewMapDataDocument = gql`
    query getAnonymousViewMapData($boundary: Boundary!) {
  getAnonMapFeed(type: "public", boundary: $boundary) {
    interests
    places
    geoDrops
    geoContexts
  }
}
    `;

/**
 * __useGetAnonymousViewMapDataQuery__
 *
 * To run a query within a React component, call `useGetAnonymousViewMapDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonymousViewMapDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonymousViewMapDataQuery({
 *   variables: {
 *      boundary: // value for 'boundary'
 *   },
 * });
 */
export function useGetAnonymousViewMapDataQuery(baseOptions: Apollo.QueryHookOptions<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables> & ({
  variables: GetAnonymousViewMapDataQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
})) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>(GetAnonymousViewMapDataDocument, options);
}
export function useGetAnonymousViewMapDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>(GetAnonymousViewMapDataDocument, options);
}
export function useGetAnonymousViewMapDataSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useSuspenseQuery<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>(GetAnonymousViewMapDataDocument, options);
}
export type GetAnonymousViewMapDataQueryHookResult = ReturnType<typeof useGetAnonymousViewMapDataQuery>;
export type GetAnonymousViewMapDataLazyQueryHookResult = ReturnType<typeof useGetAnonymousViewMapDataLazyQuery>;
export type GetAnonymousViewMapDataSuspenseQueryHookResult = ReturnType<typeof useGetAnonymousViewMapDataSuspenseQuery>;
export type GetAnonymousViewMapDataQueryResult = Apollo.QueryResult<GetAnonymousViewMapDataQuery, GetAnonymousViewMapDataQueryVariables>;
export const GetAnonGeoActivityByActivityIdDocument = gql`
    query getAnonGeoActivityByActivityId($id: BigInt!) {
  geoActivities(condition: {id: $id}) {
    nodes {
      id
      type
      likeCount
      commentCount
      upVoteCount
      downVoteCount
      status
      label
      description
      ownerRequirePassword
      owner {
        id
        publicAlias
        profileImageUrl
        fullName
      }
      messageBody
      messageSubject
      refImageUrl
      createdAt
      updatedAt
      geocodeId
      geocodeTimestamp
      relatedToGeocodeJson
      isPublic
      ownerId
      showBusinessCard
      userBusinessCardId
      visibility
      visibilityDuration
      visibilityLabel
      visibilityLevel
      visibilityStartDate
      visibilityEndDate
      displayStartDate
      displayEndDate
      geocode {
        id
        name
        type
        locationInfoAddressLine1
        locationCoordinates {
          latitude: x
          longitude: y
        }
        socialCommentCount
        placeInfoDescription
        placeInfoHours
        placeInfoWebsite
        placeInfoPhoneNumber
        locationInfoAddressLine1
        socialLikeCount
        ownerId
        ownerAccountId
        createdAt
        updatedAt
      }
      appEmojiId
      appEmoji {
        id
        name
        emoji
      }
      appBadgeId
      appBadge {
        id
        name
        iconUrl
      }
      appDropCategoryId
      appDropCategory {
        id
        name
        iconUrl
      }
      refUrl
      isExternalSourced
      externalSource
      externalSourceId
      businessOfferLink
      isPromoted
      currentPromotionStartDate
      currentPromotionEndDate
      currentPromotionTotalViews
      totalViews
      geocastRelativePosition
      isOffer
      isPopular
      ownerRequireProximityDistance
      metaJson
    }
  }
}
    `;

/**
 * __useGetAnonGeoActivityByActivityIdQuery__
 *
 * To run a query within a React component, call `useGetAnonGeoActivityByActivityIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnonGeoActivityByActivityIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnonGeoActivityByActivityIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetAnonGeoActivityByActivityIdQuery(baseOptions: Apollo.QueryHookOptions<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables> & ({
  variables: GetAnonGeoActivityByActivityIdQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
})) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>(GetAnonGeoActivityByActivityIdDocument, options);
}
export function useGetAnonGeoActivityByActivityIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>(GetAnonGeoActivityByActivityIdDocument, options);
}
export function useGetAnonGeoActivityByActivityIdSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useSuspenseQuery<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>(GetAnonGeoActivityByActivityIdDocument, options);
}
export type GetAnonGeoActivityByActivityIdQueryHookResult = ReturnType<typeof useGetAnonGeoActivityByActivityIdQuery>;
export type GetAnonGeoActivityByActivityIdLazyQueryHookResult = ReturnType<typeof useGetAnonGeoActivityByActivityIdLazyQuery>;
export type GetAnonGeoActivityByActivityIdSuspenseQueryHookResult = ReturnType<typeof useGetAnonGeoActivityByActivityIdSuspenseQuery>;
export type GetAnonGeoActivityByActivityIdQueryResult = Apollo.QueryResult<GetAnonGeoActivityByActivityIdQuery, GetAnonGeoActivityByActivityIdQueryVariables>;
export const SearchGeoActivitiesDocument = gql`
    query searchGeoActivities($limit: Int!, $offset: Int!, $sharingAndFilterCriteria: String!) {
  geoActivities(
    condition: {sharingAndFilterCriteria: $sharingAndFilterCriteria}
    filter: {isDeleted: {equalTo: false}}
    first: $limit
    offset: $offset
  ) {
    nodes {
      id
      type
      likeCount
      commentCount
      upVoteCount
      downVoteCount
      status
      label
      description
      ownerRequirePassword
      owner {
        id
        publicAlias
        profileImageUrl
        fullName
      }
      messageBody
      messageSubject
      refImageUrl
      createdAt
      updatedAt
      geocodeId
      geocodeTimestamp
      relatedToGeocodeJson
      isPublic
      ownerId
      showBusinessCard
      userBusinessCardId
      visibility
      visibilityDuration
      visibilityLabel
      visibilityLevel
      visibilityStartDate
      visibilityEndDate
      displayStartDate
      displayEndDate
      geocode {
        id
        name
        type
        locationInfoAddressLine1
        locationCoordinates {
          latitude: x
          longitude: y
        }
        socialCommentCount
        placeInfoDescription
        placeInfoHours
        placeInfoWebsite
        placeInfoPhoneNumber
        locationInfoAddressLine1
        socialLikeCount
        ownerId
        ownerAccountId
        createdAt
        updatedAt
      }
      appEmojiId
      appEmoji {
        id
        name
        emoji
      }
      appBadgeId
      appBadge {
        id
        name
        iconUrl
      }
      appDropCategoryId
      appDropCategory {
        id
        name
        iconUrl
      }
      refUrl
      isExternalSourced
      externalSource
      externalSourceId
      businessOfferLink
      isPromoted
      currentPromotionStartDate
      currentPromotionEndDate
      currentPromotionTotalViews
      totalViews
      geocastRelativePosition
      isOffer
      isPopular
      ownerRequireProximityDistance
      metaJson
    }
    pageInfo {
      hasNextPage
      hasPreviousPage
      startCursor
      endCursor
    }
  }
}
    `;

/**
 * __useSearchGeoActivitiesQuery__
 *
 * To run a query within a React component, call `useSearchGeoActivitiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchGeoActivitiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchGeoActivitiesQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      sharingAndFilterCriteria: // value for 'sharingAndFilterCriteria'
 *   },
 * });
 */
export function useSearchGeoActivitiesQuery(baseOptions: Apollo.QueryHookOptions<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables> & ({
  variables: SearchGeoActivitiesQueryVariables;
  skip?: boolean;
} | {
  skip: boolean;
})) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useQuery<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>(SearchGeoActivitiesDocument, options);
}
export function useSearchGeoActivitiesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>) {
  const options = {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useLazyQuery<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>(SearchGeoActivitiesDocument, options);
}
export function useSearchGeoActivitiesSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>) {
  const options = baseOptions === Apollo.skipToken ? baseOptions : {
    ...defaultOptions,
    ...baseOptions
  };
  return Apollo.useSuspenseQuery<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>(SearchGeoActivitiesDocument, options);
}
export type SearchGeoActivitiesQueryHookResult = ReturnType<typeof useSearchGeoActivitiesQuery>;
export type SearchGeoActivitiesLazyQueryHookResult = ReturnType<typeof useSearchGeoActivitiesLazyQuery>;
export type SearchGeoActivitiesSuspenseQueryHookResult = ReturnType<typeof useSearchGeoActivitiesSuspenseQuery>;
export type SearchGeoActivitiesQueryResult = Apollo.QueryResult<SearchGeoActivitiesQuery, SearchGeoActivitiesQueryVariables>;