import React from 'react';
interface BackButtonProps {
  onBack: () => void; // Callback function for the back button
  header?: string; // Optional title to display next to the button
}
const BackButton: React.FC<BackButtonProps> = ({
  onBack,
  header
}) => {
  return <div className="flex items-center space-x-4" data-sentry-component="BackButton" data-sentry-source-file="index.tsx">
      <button onClick={onBack} className="w-10 h-10 flex items-center justify-center bg-gray-300 text-gray-700 rounded-full shadow-md border border-gray-400 hover:bg-gray-400" aria-label="Back">
        <svg xmlns="http://www.w3.org/2000/svg" className="w-5 h-5" fill="none" viewBox="0 0 24 24" stroke="currentColor" data-sentry-element="svg" data-sentry-source-file="index.tsx">
          <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M15 19l-7-7 7-7" data-sentry-element="path" data-sentry-source-file="index.tsx" />
        </svg>
      </button>
      {header && <h1 className="text-xl">{header}</h1>}
    </div>;
};
export default BackButton;