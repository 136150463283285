// components/GroupProfile.tsx

import React from 'react';

// Mock data for demonstration
const mockGroupData = {
  image: '/images/group-image.jpg',
  // Replace with an actual group image source
  type: 'Public Group',
  username: 'GroupName123',
  label: 'Hiking Enthusiasts',
  memberCount: 124
};
const GroupProfile: React.FC = () => {
  const {
    image,
    type,
    username,
    label,
    memberCount
  } = mockGroupData;
  return <div className="max-w-xl mx-auto bg-white rounded-lg shadow-md overflow-hidden p-4 flex" data-sentry-component="GroupProfile" data-sentry-source-file="profile.tsx">
      {/* Column 1: Group Image */}
      <div className="w-1/3">
        <img src={image} alt="Group" className="w-full h-full object-cover rounded-lg" />
      </div>

      {/* Column 2: Group Details */}
      <div className="w-2/3 pl-4 flex flex-col justify-between">
        <div>
          <div className="text-sm text-gray-500">{type}</div>
          <h2 className="text-xl font-semibold text-gray-900">{username}</h2>
          <div className="text-sm text-gray-600 mb-2">{label}</div>
          <div className="text-sm text-gray-500">Members: {memberCount}</div>
        </div>

        {/* Actions/Shortcuts */}
        <div className="flex space-x-4 mt-4">
          <button className="bg-blue-500 text-white px-4 py-2 rounded-lg">
            Add Member
          </button>
          <button className="bg-green-500 text-white px-4 py-2 rounded-lg">
            Add Content
          </button>
        </div>
      </div>
    </div>;
};
export { GroupProfile };