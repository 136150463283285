// components/ContentCard.tsx

import React from 'react';
import MediaComponent from './Media';
import { GoogleMapsLink } from '@/components/Location/OpenMaps';
import Link from 'next/link';
const mediaContent: any = [{
  type: 'video',
  url: ['https://www.w3schools.com/html/mov_bbb.mp4'] // Mock video URL
}, {
  type: 'image',
  url: ['https://via.placeholder.com/400x300?text=Gallery+Image+1']
}, {
  type: 'gallery',
  url: ['https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1']
}];

// Mock data for demonstration
const mockData = {
  media: [],
  // mediaContent
  title: '',
  icon: '',
  description: '',
  location: 'No where.',
  eventDate: 'No date.',
  eventTime: 'No time.',
  likes: 0,
  comments: 0,
  views: 0
};
const ContentCard: React.FC<{
  content?: any;
  onLocationClick?: (resultId: any, result: any) => void;
}> = ({
  content,
  onLocationClick
}) => {
  const {
    media = mockData.media,
    title = mockData.title,
    icon = mockData.icon,
    location = mockData.location,
    description = mockData.description,
    eventDate = mockData.eventDate,
    eventTime = mockData.eventTime,
    likes = mockData.likes,
    comments = mockData.comments,
    views = mockData.views
  } = content || {};

  // console.log({ content })

  return <div className="max-w-md mx-auto bg-white rounded-lg shadow-md overflow-hidden" data-sentry-component="ContentCard" data-sentry-source-file="index.tsx">

      <h2 className="text-2xl font-bold pt-2 px-4">{"Details"}</h2>


      {/* <div className="w-full h-48 bg-gray-200">
        <img src={media} alt="Media Content" className="w-full h-full object-cover" />
       </div> */}
      

      {/* Header + Icon */}
      <div className="flex items-center justify-between p-4">
        <h2 className="text-lg font-semibold text-gray-900">{title}</h2>
        {icon?.url ? <img src={icon?.url} alt="Content Icon" className="w-6 h-7" /> : <></>}
      </div>

      {/* Location */}
      <div className="px-4 text-gray-500 text-sm mb-2" onClick={() => {
      onLocationClick && onLocationClick(content?.id, content);
    }}>{location}{location && location !== mockData.location ? <GoogleMapsLink lat={content?.lat} lng={content?.lng} label={location} /> : <></>}</div>
      
      

      {/* Description */}
      <div className="px-4 text-gray-700 text-sm mb-4" style={{
      whiteSpace: "normal",
      overflowWrap: "break-word",
      maxWidth: "100%"
    }}>{description}</div>


      {/* Event Date & Time */}
      <div className="px-4 text-gray-500 text-sm mb-4">
        <div>{eventDate}</div>
        <div>{eventTime}</div>
      </div>
      
      {content?.refUrl ? <div className='px-4 pb-4'><Link href={content?.refUrl} target="_blank" rel="noopener noreferrer" className="text-blue-500 hover:underline ">
        (View More)
      </Link></div> : <></>}
 
      {/* Media Content */}
      <MediaComponent media={media} data-sentry-element="MediaComponent" data-sentry-source-file="index.tsx" />

      {/* Engagement (Likes, Comments, Views) */}
      <div className="border-t border-gray-200 flex items-center justify-between px-4 py-2 text-gray-500 text-xs">
        <div>{likes} Likes</div>
        <div>{comments} Comments</div>
        <div>{views} Views</div>
      </div>
    </div>;
};
export default ContentCard;