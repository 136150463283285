// components/SearchResults.tsx

import React, { useState, useEffect } from 'react';
import { PaginatedList } from '../Common/Pagination';
import MediaComponent from '../Cards/Content/Media';
import { GetAnonymousViewMapDataQueryVariables, useGetAnonymousViewMapDataLazyQuery, useGetAnonymousViewMapDataQuery } from '@/generated/public/graphql';
import { useIsMobile } from "@/hooks/useIsMobile";
interface LocationMarker {
  id: number;
  title: string;
  description: string;
  lng: number; // Add longitude
  lat: number; // Add latitude
  location?: string;
  media?: Array<{
    type: 'image' | 'video' | 'gallery';
    url: string[];
  }>;
}
const mediaContent: any = [{
  type: 'video',
  url: ['https://www.w3schools.com/html/mov_bbb.mp4'] // Mock video URL
}, {
  type: 'image',
  url: ['https://via.placeholder.com/400x300?text=Gallery+Image+1']
}, {
  type: 'gallery',
  url: ['https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1', 'https://via.placeholder.com/400x300?text=Gallery+Image+1']
}];

// Mock API calls (Replace these with actual API calls)
const mockSearchResults = (): Promise<LocationMarker[]> => {
  const updatedLocations = [{
    id: 1,
    title: 'Result 1',
    description: 'Description for result 1',
    lat: 33.7488,
    lng: -84.3877,
    media: mediaContent
  },
  // Downtown Atlanta
  {
    id: 2,
    title: 'Result 2',
    description: 'Description for result 2',
    lat: 33.8463,
    lng: -84.3620,
    media: mediaContent
  },
  // Buckhead
  {
    id: 3,
    title: 'Result 3',
    description: 'Description for result 3',
    lat: 33.7677,
    lng: -84.4906,
    media: mediaContent
  },
  // Westside Atlanta
  {
    id: 4,
    title: 'Result 4',
    description: 'Description for result 4',
    lat: 33.9235,
    lng: -84.3387,
    media: mediaContent
  },
  // Sandy Springs
  {
    id: 5,
    title: 'Result 5',
    description: 'Description for result 5',
    lat: 33.7832,
    lng: -84.3590,
    media: mediaContent
  },
  // Midtown Atlanta
  {
    id: 6,
    title: 'Result 6',
    description: 'Description for result 6',
    lat: 33.9143,
    lng: -84.5135,
    media: mediaContent
  },
  // Smyrna
  {
    id: 7,
    title: 'Result 7',
    description: 'Description for result 7',
    lat: 33.9695,
    lng: -84.2217,
    media: mediaContent
  },
  // Roswell
  {
    id: 8,
    title: 'Result 8',
    description: 'Description for result 8',
    lat: 33.6350,
    lng: -84.4440,
    media: mediaContent
  },
  // College Park
  {
    id: 9,
    title: 'Result 9',
    description: 'Description for result 9',
    lat: 33.9534,
    lng: -84.5490,
    media: mediaContent
  },
  // Marietta
  {
    id: 10,
    title: 'Result 10',
    description: 'Description for result 10',
    lat: 33.7459,
    lng: -84.3984,
    media: mediaContent
  },
  // Grant Park
  {
    id: 11,
    title: 'Result 11',
    description: 'Description for result 11',
    lat: 33.8683,
    lng: -84.5208,
    media: mediaContent
  },
  // Vinings
  {
    id: 12,
    title: 'Result 12',
    description: 'Description for result 12',
    lat: 33.7746,
    lng: -84.3973,
    media: mediaContent
  } // Old Fourth Ward
  ];
  return new Promise(resolve => {
    setTimeout(() => {
      resolve(updatedLocations);
    }, 500);
  });
};
const mockMedia = (type: string, id: number): Promise<string[]> => {
  return new Promise(resolve => {
    setTimeout(() => {
      if (type === 'image') {
        resolve([`/images/image${id}-1.jpg`, `/images/image${id}-2.jpg`]);
      } else if (type === 'video') {
        resolve([`/videos/video${id}.mp4`]);
      } else if (type === 'gallery') {
        resolve([`/gallery/img${id}-1.jpg`, `/gallery/img${id}-2.jpg`, `/gallery/img${id}-3.jpg`]);
      }
    }, 500);
  });
};
const ResultComponent: React.FC<any> = ({
  result,
  onResultClick,
  handleMediaTypeChange,
  selectedMediaType,
  media
}) => {
  return <div key={result.id} className="mb-6" data-sentry-component="ResultComponent" data-sentry-source-file="results.tsx">
      {result.eventDate ? <p className="text-gray-700 pb-2">{result.eventDate}</p> : <></>}
      <h2 className="text-xl font-semibold">{result.title}</h2>
      <p className="text-gray-700" style={{
      whiteSpace: "normal",
      overflowWrap: "break-word",
      maxWidth: "100%"
    }}>{result.description}</p>
      <div className="flex space-x-4 mt-2 pb-4">
        <button className="text-blue-500 hover:underline" onClick={() => {
        onResultClick && onResultClick(result.id, result);
      }}>
          View
        </button>
      </div>

      <MediaComponent media={result.media} showSingleItem={true} data-sentry-element="MediaComponent" data-sentry-source-file="results.tsx" />
      {/* 
            
            
            <div className="flex space-x-4 mt-2">
              <button
                onClick={() => handleMediaTypeChange('image', result.id)}
                className="text-blue-500 hover:underline"
              >
                Show Images
              </button>
              <button
                onClick={() => handleMediaTypeChange('video', result.id)}
                className="text-blue-500 hover:underline"
              >
                Show Video
              </button>
              <button
                onClick={() => handleMediaTypeChange('gallery', result.id)}
                className="text-blue-500 hover:underline"
              >
                Show Gallery
              </button>
            </div> */}

      {selectedMediaType && media.length > 0 && <div className="mt-4">
          {selectedMediaType === 'image' && <div className="grid grid-cols-2 gap-4">
              {media.map((src: any, index: any) => <img key={index} src={src} alt={`Image ${index}`} className="rounded-lg" />)}
            </div>}
          {selectedMediaType === 'video' && <video controls className="w-full rounded-lg">
              <source src={media[0]} type="video/mp4" />
              Your browser does not support the video tag.
            </video>}
          {selectedMediaType === 'gallery' && <div className="grid grid-cols-3 gap-4">
              {media.map((src: any, index: any) => <img key={index} src={src} alt={`Gallery Image ${index}`} className="rounded-lg" />)}
            </div>}
        </div>}
    </div>;
};
type Comparator<T> = (a: T, b: T) => number;
function genericSort<T>(list: T[], property: keyof T, ascending: boolean = true): T[] {
  const compare: Comparator<T> = (a, b) => {
    const aValue = a[property];
    const bValue = b[property];

    // Handle null or undefined values as 'nulls first'
    if (aValue == null && bValue == null) {
      return 0; // Both are null
    }
    if (aValue == null) {
      return -1; // aValue is null, comes first
    }
    if (bValue == null) {
      return 1; // bValue is null, comes first
    }

    // Sort based on value comparison
    if (aValue < bValue) {
      return ascending ? -1 : 1;
    }
    if (aValue > bValue) {
      return ascending ? 1 : -1;
    }
    return 0;
  };
  return list.sort(compare);
}
interface SearchResultsProps {
  header?: string;
  onResultClick?: (resultId: any, result?: any) => void;
  displayedResults?: any[];
  onDisplayToggle?: (showResults: boolean) => void;
  onResultView?: (idIn: string) => void;
}
const SearchResults: React.FC<SearchResultsProps> = ({
  header,
  onResultClick,
  displayedResults,
  onDisplayToggle,
  onResultView
}) => {
  const isMobile = useIsMobile();
  console.log({
    isMobile
  });
  const [selectedMediaType, setSelectedMediaType] = useState<'image' | 'video' | 'gallery' | ''>('');
  const [media, setMedia] = useState<string[]>([]);
  const [showResults, setShowResults] = useState<boolean>(true);
  React.useEffect(() => {
    onDisplayToggle && onDisplayToggle(showResults);
  }, [showResults]);
  const handleMediaTypeChange = async (type: 'image' | 'video' | 'gallery', id: number) => {
    setSelectedMediaType(type);
    const mediaData = await mockMedia(type, id);
    setMedia(mediaData);
  };
  return <div className="p-4 max-w-2xl mx-auto" data-sentry-component="SearchResults" data-sentry-source-file="results.tsx">
      <div className="flex items-center justify-between mb-4">
        <h1 className="text-2xl font-bold pr-5">{header || "Search Results"}</h1>
        {!isMobile ? <button className="text-blue-500 font-semibold hover:text-blue-700 focus:outline-none" onClick={() => {
        setShowResults(!showResults);
      }}>
          {showResults ? "Hide" : "Show"}
        </button> : <></>}
      </div>
      {/* <pre>{JSON.stringify(genericSort(displayedResults || [], "eventTimestamp"), null, 2)}</pre> */}
      {showResults ? <PaginatedList onRecordView={record => {
      // console.log("viewed:", record)
      onResultView && onResultView(record?.id);
    }} records={genericSort(displayedResults || [], "eventTimestamp")} renderItem={record => <ResultComponent result={record} onResultClick={onResultClick} handleMediaTypeChange={handleMediaTypeChange} selectedMediaType={selectedMediaType} media={media} />} /> : <></>}
    </div>;
};
export default SearchResults;